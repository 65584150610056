import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  HEADER_LIVE_SESSIONS_BACKGROUND,
  HEADER_RESILIENCE_FEED_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Flex, SimpleGrid, useBreakpointValue, Box } from "@chakra-ui/react";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { Pager, PagerState } from "../../Common/Pager";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { EVENTS, track } from "../../../Utils/Analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StickyBreadcrumb } from "../../Common/StickyBreadcrumb";
import { BlogPostDto, SkillDto } from "@headversity/contract";
import { ResilienceFeedItemBlock20 } from "./ResilienceFeedItemBlock20";
import { HvSelect } from "../../Common/HvSelect";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { isWebPlatform } from "../../../Utils/mobileUtils";

interface ResilienceFeedPageProps {
  isLiveSessions?: boolean;
}

export const ResilienceFeedPage = ({
  isLiveSessions,
}: ResilienceFeedPageProps) => {
  const navigate = useNavigate();

  const {
    allResilienceFeedPosts,
    getPageTitle,
    skills,
    globalHeadScanQuestionModalOpen,
    globalBlogPost,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [selectedResilienceCategory, setSelectedResilienceCategory] = useState<
    number[]
  >([-1]);

  const [resilienceFeedPager, setResilienceFeedPager] = useState<PagerState>({
    currentPage: 0,
    totalPage: 0,
    pagePerRow: 9,
    showPageInput: true,
  });

  const [resilienceFeedPosts, setResilienceFeedPosts] = useState<BlogPostDto[]>(
    []
  );
  const [categories, setCategories] = useState<SkillDto[]>([]);

  const isMd = useBreakpointValue({ base: false, md: true });
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParams = () => {
    const postParam = searchParams.get("post");
    let pageParamString = searchParams.get("page");
    let pageParam = pageParamString ? parseInt(pageParamString) : undefined;

    let categoryParam = null;
    if (pageParam) {
      categoryParam = searchParams.get("categories");
    }
    return { pageParam, categoryParam, postParam };
  };

  useEffect(() => {
    const { pageParam, categoryParam } = getSearchParams();
    if (pageParam === undefined) {
      return;
    }
    if (categoryParam) {
      const categories = categoryParam.split(",").map(function (item) {
        return parseInt(item);
      });
      setSelectedResilienceCategory(categories);
    }
    resilienceFeedPager.currentPage = pageParam - 1;
    setResilienceFeedPager({ ...resilienceFeedPager });
  }, [isLiveSessions]);

  useEffect(() => {
    const { pageParam, categoryParam } = getSearchParams();
    const newPage = resilienceFeedPager.currentPage + 1;
    let newCategories: any = null;
    if (
      selectedResilienceCategory.length > 0 &&
      !selectedResilienceCategory.includes(-1)
    ) {
      newCategories = selectedResilienceCategory.toString();
    }
    if (pageParam !== newPage || categoryParam !== newCategories) {
      let paramCount = 0;
      if (newPage !== 1) {
        searchParams.set("page", newPage.toString());
        paramCount++;
      } else {
        searchParams.delete("page");
      }

      if (newCategories !== null) {
        searchParams.set("categories", newCategories);
        paramCount++;
      } else {
        searchParams.delete("categories");
      }

      // don't add to history if no params
      if (window.location.search.length === 0 && paramCount === 0) {
        return;
      }

      searchParams.sort();
      setSearchParams(searchParams);
    }
  }, [resilienceFeedPager, globalHeadScanQuestionModalOpen, globalBlogPost]);

  useEffect(() => {
    navigate(`?${searchParams.toString()}`, {
      replace: true,
    });
  }, [searchParams]);

  useEffect(() => {
    if (allResilienceFeedPosts.length === 0) {
      return;
    }
    const posts = allResilienceFeedPosts.filter((item) => {
      if (
        (!isLiveSessions && item.isLiveSession) ||
        (isLiveSessions && !item.isLiveSession)
      ) {
        return false;
      }

      if (isLiveSessions || selectedResilienceCategory.indexOf(-1) > -1) {
        return true;
      }

      if (item?.skillCompetencies.length > 0) {
        const skillIds = item?.skillCompetencies.map(
          (skillCompetency) => skillCompetency.skillId
        );
        for (const skillId of skillIds) {
          if (selectedResilienceCategory.indexOf(skillId) > -1) return true;
        }
      }
      return false;
    });

    setResilienceFeedPosts([...posts]);

    resilienceFeedPager.totalPage = Math.ceil(
      posts.length / resilienceFeedPager.pagePerRow
    );
    if (resilienceFeedPager.currentPage > resilienceFeedPager.totalPage) {
      resilienceFeedPager.currentPage = resilienceFeedPager.totalPage - 1;
    } else if (resilienceFeedPager.currentPage < 0) {
      resilienceFeedPager.currentPage = 0;
    }
    setResilienceFeedPager({ ...resilienceFeedPager });
  }, [isLiveSessions, allResilienceFeedPosts, selectedResilienceCategory]);

  useEffect(() => {
    const categories: SkillDto[] = [];

    // pick only skills where we have at least one post
    skills.forEach((s) => {
      if (
        allResilienceFeedPosts.find((p) =>
          p.skillCompetencies.find((sc) => sc.skillId === s.id)
        )
      ) {
        categories.push(s);
      }
    });

    // sort
    categories.sort((a, b) => (a.name > b.name ? 1 : -1));

    setCategories(categories);
  }, [skills, allResilienceFeedPosts]);

  const handleCategoryButtonClick = (skillId: number) => {
    let newResilienceCategories: number[] = [];
    if (selectedResilienceCategory.includes(-1)) {
      newResilienceCategories = selectedResilienceCategory.filter(
        (rc) => rc !== -1
      );
    }
    if (selectedResilienceCategory.includes(skillId)) {
      newResilienceCategories = selectedResilienceCategory.filter(
        (rc) => rc !== skillId
      );
    } else {
      newResilienceCategories.push(skillId);
    }
    if (newResilienceCategories.length === 0) {
      newResilienceCategories.push(-1);
    }
    setSelectedResilienceCategory(newResilienceCategories);

    resilienceFeedPager.currentPage = 0;
    setResilienceFeedPager({ ...resilienceFeedPager });
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={
          isLiveSessions
            ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE
            : HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE
        }
        description={
          isLiveSessions
            ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_DESCRIPTION.replace(
                ".",
                ""
              )
            : HVLocalizeStrings.RESiLIENCE_PAGE_DESCRIPTION
        }
        backgroundImage={
          isLiveSessions
            ? HEADER_LIVE_SESSIONS_BACKGROUND
            : HEADER_RESILIENCE_FEED_BACKGROUND
        }
      />

      <StickyBreadcrumb
        crumbs={[
          { url: "/", text: HVLocalizeStrings.SOLO_MENU_HOME },
          { url: "/explore", text: HVLocalizeStrings.EXPLORE },
        ]}
        current={
          isLiveSessions
            ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE
            : HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE
        }
      />

      <FadeAfterDelay>
        {!isWebPlatform() && (
          <BlockSection
            noPaddingBottom={true}
            title={
              <BlockSectionTitle
                title={
                  isLiveSessions
                    ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE
                    : HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE
                }
                description={
                  isLiveSessions
                    ? HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_DESCRIPTION
                    : HVLocalizeStrings.RESiLIENCE_PAGE_DESCRIPTION + "."
                }
              />
            }
            columns={1}
            fadeIn={true}
          >
            <Box></Box>
          </BlockSection>
        )}
        {!isLiveSessions && (
          <Box
            px={4}
            mt={4}
            mb={6}
            mx={"auto"}
            maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          >
            <HvSelect
              width={isDesktop ? "310px" : "100%"}
              onChange={(event) => {
                const skillId = event.target.value;
                track(EVENTS.ResilienceFeedCategoryChanged, {
                  HV_Category: skillId,
                });
                handleCategoryButtonClick(parseInt(skillId));
              }}
              value={selectedResilienceCategory[0]}
            >
              <option key="All" value="-1">
                {HVLocalizeStrings.ALL_CATEGORIES}
              </option>
              {categories.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name as string}
                  </option>
                );
              })}
            </HvSelect>
          </Box>
        )}
        <SimpleGrid
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          columns={isDesktop ? 3 : isMd ? 2 : 1}
          spacing={"24px"}
          mx="auto"
          px={4}
        >
          {resilienceFeedPosts
            .slice(
              resilienceFeedPager.currentPage * resilienceFeedPager.pagePerRow,
              resilienceFeedPager.currentPage * resilienceFeedPager.pagePerRow +
                resilienceFeedPager.pagePerRow
            )
            .map((resilienceFeedPost) => {
              return (
                <Box
                  key={resilienceFeedPost.id}
                  width="100%"
                  position="relative"
                  // Set the padding-bottom property to maintain a 9:16 aspect ratio (56.25%) of image with an additional 60 pixels of padding
                  pb="calc(56.25% + 60px)"
                >
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                  >
                    <ResilienceFeedItemBlock20
                      post={resilienceFeedPost}
                      postCollection={resilienceFeedPosts}
                      from={
                        isLiveSessions ? "Live Sessions" : "Resilience Feed"
                      }
                      width={"100%"}
                      height={"100%"}
                      isSmall={!isLiveSessions}
                      hideSubTitle={isLiveSessions}
                      isLiveSession={isLiveSessions}
                    />
                  </Box>
                </Box>
              );
            })}
        </SimpleGrid>
        <Flex w={"100%"} justify={"center"} align={"center"} mt={16} mb={8}>
          <Pager
            pagerState={resilienceFeedPager}
            setPagerState={setResilienceFeedPager}
            withArrows={true}
          />
        </Flex>
      </FadeAfterDelay>
    </>
  );
};
