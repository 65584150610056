import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Container,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import {
  EligibilityJobResult,
  ReachUsersContext,
  UserModalOpen,
} from "../../State/Reach/ReachUsersContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import {
  BG_REACH_BASE,
  HEADER_REACH_HOME_BACKGROUND,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { BlockSection } from "../Common/BlockSection";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HvSelect } from "../Common/HvSelect";
import { HvSpinner } from "../Common/HvSpinner";
import { Pager, PagerState } from "../Common/Pager";
import EligibilityFileJobsViewModal from "./SelfService/EligibilityFileJobsViewModal";
import UserBulkModal from "./SelfService/UserModal/UserBulkModal";
import ReachUsersToolbar from "./SelfService/ReachUsersToolbar";
import { SelfServeUsersList } from "./SelfService/SelfServeUsersList";
import ReachUserModalContainer from "./SelfService/ReachUserModalContainer";
import { debounce } from "lodash";
import { CompletionState } from "@headversity/contract";
import { useLocation, useSearchParams } from "react-router-dom";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import ShareInviteCodeModal from "./SelfService/ShareInviteCodeModal";

export interface SortCriteriaProps {
  column: string;
  order: string;
}

export const ReachUsersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentPath = location.pathname;

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const {
    initialSearchFilter,
    initialCertsFilter,
    initialNodesFilter,
    initialCompletionStatusFilter,
    initialPage,
    initialLimit,
    initialSortBy,
    initialSortDir,
    initialModalUser,
    initialHierarchyOnly,
  } = useMemo(
    () => ({
      initialSearchFilter: searchParams.get("searchFilter") || "",
      initialCertsFilter: searchParams.get("certsFilter") || "",
      initialNodesFilter: searchParams.get("nodesFilter"), // if null, we are entering the page
      initialCompletionStatusFilter:
        searchParams.get("completionStatusesFilter") || "",
      initialPage: parseInt(searchParams.get("page") || "1"),
      initialLimit: parseInt(searchParams.get("limit") || "10"),
      initialSortBy: searchParams.get("sortBy") || "email",
      initialSortDir: searchParams.get("sortDir") || "asc",
      initialModalUser: searchParams.get("user") || "",
      initialHierarchyOnly: searchParams.get("hierarchyOnly") === "true",
    }),
    []
  );

  const USERS_PER_PAGE_OPTIONS = [10, 15, 20, 30, 50];
  const [sortCriteria, setSortCriteria] = useState<SortCriteriaProps>({
    column: "",
    order: "asc",
  });
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [isJobViewOpen, setIsJobViewOpen] = useState<boolean>(false);

  const { isMainContainerXL, showToast } =
    useContext<IShellProvider>(ShellContext);
  const { getPageTitle, userHierarchyAssociations } =
    useContext<IGlobalProvider>(GlobalContext);

  const {
    reachUsers,
    jobResult,
    userModalOpen,
    reachUsersIsLoading,
    getReachUsersAndCompanyInviteCodesFromServer,
    dataRefreshTimestamp,
    certsFilter,
    nodesFilter,
    completionStatusFilter,
    setSearchFilter: setContextSearchFilter,
    setCertsFilter,
    setNodesFilter,
    setCompletionStatusFilter,
    companyCerts,
    fetchCompanyCerts,
    hierarchyNodes,
    fetchHierarchyNodes,
    setIsUserCertsStatsLoading,
    setUserModalOpen,
    setSelectedUser,
    getCertsStatsForUserFromServer,
    selectedUser,
    hierarchyOnly,
    setHierarchyOnly,
    readOnlyReachUsers,
  } = useContext(ReachUsersContext);
  const [selfServePager, setSelfServePager] = useState<PagerState>({
    currentPage: 0,
    totalPage: 1,
    pagePerRow: USERS_PER_PAGE_OPTIONS[0],
    showPageInput: true,
  });

  const [shareInviteCodeModalOpen, setShareInviteCodeModalOpen] =
    useState<boolean>(false);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const debouncedSetIsInitialLoad = useCallback(
    debounce(setIsInitialLoad, 300, {
      leading: false,
      trailing: true,
    }),
    []
  );

  useEffect(() => {
    if (companyCerts && companyCerts.length > 0 && !reachUsersIsLoading) {
      if (initialModalUser) {
        // We need to wait for other filters to be applied first before opening the user modal.
        // This is to ensure that the user's data is included in the server's response.
        // so that the user modal can be opened.
        const user = reachUsers.data.find(
          (u) => u.id === Number(initialModalUser)
        );
        if (!user) {
          return;
        }
      }
      debouncedSetIsInitialLoad(false);
    }
  }, [companyCerts, reachUsersIsLoading, reachUsers]);

  useEffect(() => {
    fetchCompanyCerts();
  }, []);

  useEffect(() => {
    if (userHierarchyAssociations?.hierarchyId) {
      fetchHierarchyNodes();
    }
  }, [userHierarchyAssociations]);

  useEffect(() => {
    if (userHierarchyAssociations && hierarchyNodes.length > 0) {
      if (initialNodesFilter) {
        // we have nodesFilter in the url, just use whatever is in the string
        const filtered = hierarchyNodes.filter((node) =>
          initialNodesFilter.includes(node.id.toString())
        );
        if (initialNodesFilter.includes("-1")) {
          // special case, -1 means unassociated
          filtered.push({ id: -1, name: "Unassociated" });
        }
        setNodesFilter(filtered);
      }
      if (initialNodesFilter === null) {
        // we don't have nodesFilter in the url, default to no nodes filter
        setNodesFilter([]);
      }
    }
    if (companyCerts && companyCerts.length > 0) {
      if (initialSearchFilter) {
        setSearchFilter(initialSearchFilter);
        setContextSearchFilter(initialSearchFilter);
      }
      if (initialCertsFilter) {
        const filtered = companyCerts.filter((cert) =>
          initialCertsFilter.includes(cert.id.toString())
        );
        setCertsFilter(filtered);
      }

      if (initialCompletionStatusFilter) {
        const statuses = initialCompletionStatusFilter.split(",").map(Number);
        const filtered = statuses
          .map((status) => {
            switch (status) {
              case -1:
                return CompletionState.notStarted;
              case 0:
                return CompletionState.inProgress;
              case 1:
                return CompletionState.done;
              default:
                return null;
            }
          })
          .filter((status) => status !== null) as CompletionState[];
        setCompletionStatusFilter(filtered);
      }
      setSelfServePager((prev) => ({
        ...prev,
        pagePerRow: initialLimit,
      }));
      setSortCriteria({
        column: initialSortBy,
        order: initialSortDir,
      });
    }
  }, [
    companyCerts,
    initialSearchFilter,
    initialCertsFilter,
    initialNodesFilter,
    initialCompletionStatusFilter,
    initialLimit,
    initialSortBy,
    initialSortDir,
    userHierarchyAssociations,
    hierarchyNodes,
  ]);

  useEffect(() => {
    if (
      !isInitialLoad &&
      reachUsers &&
      reachUsers.data.length > 0 &&
      (initialModalUser || selectedUser?.id)
    ) {
      const userId = Number(
        initialModalUser ? initialModalUser : selectedUser?.id
      );
      const user = reachUsers.data.find((u) => u.id === userId);
      if (user) {
        setIsUserCertsStatsLoading(true);
        setUserModalOpen(UserModalOpen.VIEW_EDIT);
        setSelectedUser(user);
        getCertsStatsForUserFromServer(userId).then(() =>
          setIsUserCertsStatsLoading(false)
        );
        track(REACH_EVENTS.ReachUsersOpenViewUsersModal);
      }
    } else if (isInitialLoad) {
      if (initialHierarchyOnly) {
        setHierarchyOnly(initialHierarchyOnly);
      }
    }
  }, [isInitialLoad, reachUsers]);

  useEffect(() => {
    if (jobResult === EligibilityJobResult.SUCCESS) {
      showToast(
        true,
        "self-serve-user-eligibility-job-success",
        HVLocalizeStrings.REACH_USERS_ELIGIBILITY_JOB_SUCCESS
      );
    } else if (jobResult === EligibilityJobResult.ERROR) {
      showToast(
        false,
        "self-serve-user-eligibility-job-error",
        HVLocalizeStrings.REACH_USERS_ELIGIBILITY_JOB_ERROR
      );
    }
  }, [jobResult]);

  const debouncedGetReachUsersAndCompanyInviteCodesFromServer = useCallback(
    debounce(getReachUsersAndCompanyInviteCodesFromServer, 50, {
      leading: false,
      trailing: true,
    }),
    []
  );

  useEffect(() => {
    setSelfServePager((prev) => ({
      ...prev,
      currentPage: isInitialLoad ? initialPage - 1 : 0,
    }));
  }, [searchFilter, certsFilter, completionStatusFilter, initialPage]);

  useEffect(() => {
    debouncedGetReachUsersAndCompanyInviteCodesFromServer(
      selfServePager.pagePerRow,
      selfServePager.currentPage + 1,
      sortCriteria.column,
      sortCriteria.order,
      searchFilter,
      certsFilter,
      completionStatusFilter,
      nodesFilter
    );
  }, [
    searchFilter,
    sortCriteria,
    dataRefreshTimestamp,
    selfServePager.currentPage,
    selfServePager.pagePerRow,
    certsFilter,
    completionStatusFilter,
    hierarchyOnly,
    nodesFilter,
    debouncedGetReachUsersAndCompanyInviteCodesFromServer,
  ]);

  useEffect(() => {
    let queryParams = new URLSearchParams();
    queryParams.append("searchFilter", searchFilter);
    queryParams.append(
      "certsFilter",
      certsFilter.map((cert) => cert.id).join(",")
    );
    queryParams.append(
      "nodesFilter",
      nodesFilter.map((node) => node.id).join(",")
    );
    queryParams.append(
      "completionStatusesFilter",
      completionStatusFilter
        .map((status) => {
          switch (status) {
            case CompletionState.notStarted:
              return "-1";
            case CompletionState.inProgress:
              return "0";
            case CompletionState.done:
              return "1";
            default:
              return null;
          }
        })
        .filter((status) => status !== null)
        .join(",")
    );
    queryParams.append("page", (selfServePager.currentPage + 1).toString());
    queryParams.append("limit", selfServePager.pagePerRow.toString());
    queryParams.append("sortBy", sortCriteria.column);
    queryParams.append("sortDir", sortCriteria.order);
    queryParams.append("hierarchyOnly", hierarchyOnly.toString());
    if (selectedUser?.id) {
      queryParams.append("user", selectedUser.id.toString());
    }
    // ensure we are in the users page using react-router-dom
    if (currentPath.includes("/users")) {
      setSearchParams(queryParams);
    }
  }, [
    searchFilter,
    sortCriteria,
    dataRefreshTimestamp,
    selfServePager.currentPage,
    selfServePager.pagePerRow,
    certsFilter,
    nodesFilter,
    completionStatusFilter,
    setSearchParams,
    selectedUser,
    hierarchyOnly,
  ]);

  useEffect(() => {
    setSelfServePager((prev) => ({
      ...prev,
      totalPage: reachUsers.meta.last_page,
    }));
  }, [reachUsers.meta]);

  const handleSearchFilterChanged = (filter: string): void => {
    setSearchFilter(filter);
    setSelfServePager((prev) => ({
      ...prev,
      currentPage: 0,
    }));
  };

  const handleViewJobsClicked = () => {
    setIsJobViewOpen(true);
  };

  const handleSetPagerState = (pagerState: PagerState) => {
    setSelfServePager((prev) => ({
      ...prev,
      currentPage: pagerState.currentPage,
    }));
  };

  const handleShareInviteCodeClicked = () => {
    setShareInviteCodeModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`${HVLocalizeStrings.REACH_USERS}`)}</title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_USERS}
        description={HVLocalizeStrings.REACH_USERS_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>

      {reachUsersIsLoading ? (
        <BlockSection fadeIn={true} columns={1}>
          <Center>
            <HvSpinner />
          </Center>
        </BlockSection>
      ) : (
        <FadeAfterDelay>
          <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
            <ReachUsersToolbar
              onSearchFilterChanged={handleSearchFilterChanged}
              setIsViewJobsOpen={handleViewJobsClicked}
              onShareInviteCodeClicked={handleShareInviteCodeClicked}
            />
            {readOnlyReachUsers && (
              <Alert status="info">
                <AlertIcon />
                {HVLocalizeStrings.REACH_USERS_READ_ONLY_DESCRIPTION}
              </Alert>
            )}
            <Box
              bg={"white"}
              mx={"auto"}
              mt={"20px"}
              mb={"20px"}
              px={"20px"}
              color={PRIMARY_TEXT_COLOR}
              borderRadius={"10px"}
            >
              {reachUsers.data.length === 0 && (
                <Box bg="white" p={6}>
                  <Text fontSize={"md"} pb={3}>
                    {HVLocalizeStrings.REACH_NO_USERS_FOUND}
                  </Text>
                </Box>
              )}
              {reachUsers.data.length > 0 && (
                <SelfServeUsersList
                  users={reachUsers.data}
                  sortCriteria={sortCriteria}
                  setSortCriteria={setSortCriteria}
                />
              )}
              <Flex
                direction={isMobile ? "column" : "row"}
                alignItems="center"
                justifyContent="center"
                w="100%"
                py={1}
              >
                <Flex alignItems="center" gap={3} flex="1" py={1}>
                  <Text>{HVLocalizeStrings.REACH_USER_USERS_PER_PAGE}</Text>
                  <HvSelect
                    value={selfServePager.pagePerRow}
                    onChange={(event) => {
                      setSelfServePager((prev) => ({
                        ...prev,
                        pagePerRow: +event.target.value,
                        currentPage: 0,
                      }));
                    }}
                    w="70px"
                    bg="white"
                    color={PRIMARY_TEXT_COLOR}
                  >
                    {USERS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </HvSelect>
                </Flex>
                <Flex flex="1" justifyContent="center" py={1}>
                  <Pager
                    pagerState={selfServePager}
                    setPagerState={handleSetPagerState}
                    dontScroll={true}
                    withArrows={true}
                    pageTextColor={PRIMARY_TEXT_COLOR}
                  />
                </Flex>
                <Flex flex="1"></Flex>
              </Flex>
            </Box>
            <EligibilityFileJobsViewModal
              isOpen={isJobViewOpen}
              setIsViewOpen={setIsJobViewOpen}
            />
            <UserBulkModal isOpen={userModalOpen === UserModalOpen.BULK} />
            <ShareInviteCodeModal
              isOpen={shareInviteCodeModalOpen}
              setIsOpen={setShareInviteCodeModalOpen}
            />
          </Container>
        </FadeAfterDelay>
      )}
      <ReachUserModalContainer />
    </>
  );
};
