import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Black,
  Equity_Black,
  Error_Red,
  Mid_Blue,
} from "../../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import UserModalEditTabPanel from "./UserModalEditTabPanel";
import UserModalViewTabPanel from "./UserModalViewTabPanel";
import {
  CreateSelfServeUserDto,
  RequestPasswordCodeDto,
  SelfServeUserDto,
  UpdateSelfServeUserDto,
  UserDto,
  UserRole,
} from "@headversity/contract";
import { getKey, getLanguageName } from "../../../../Utils/Helpers";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { useContext, useEffect, useState } from "react";
import { REACH_EVENTS, track } from "../../../../Utils/Analytics";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import {
  ReachUsersContext,
  UserModalOpen,
} from "../../../../State/Reach/ReachUsersContext";
import { ConfirmationButtons } from "../../../Common/ConfirmationDialog";
import { requestPasswordResetCodeLink } from "../../../../Api/People/PasswordApi";
import { GlobalContext } from "../../../../State/GlobalContext";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { resendActivationEmail } from "../../../../Api/People/UserApi";
import { isMobile as isMobileDevice } from "react-device-detect";
import { HVTestId } from "../../../../Testing/dataTestIds";

type ActionsMenuProps = {
  self: UserDto | null;
  selectedUser: SelfServeUserDto | undefined;
  onResetUserPasswordClick: (user: SelfServeUserDto) => void;
  handleUserDeletion: (user: SelfServeUserDto) => void;
  onResendActivationEmailClick: (user: SelfServeUserDto) => void;
};

const ActionsMenu = ({
  self,
  selectedUser,
  onResetUserPasswordClick,
  handleUserDeletion,
  onResendActivationEmailClick,
}: ActionsMenuProps) => (
  <Menu>
    <MenuButton
      as={Button}
      rightIcon={<ChevronDownIcon />}
      data-testid={HVTestId.ProfileTabPanel.actionsDropdown}
    >
      {HVLocalizeStrings.REACH_USERS_ACTIONS}
    </MenuButton>
    <MenuList
      sx={{
        transition: "none !important",
        transform: "none !important",
      }}
    >
      {self && selectedUser && !selectedUser.isOnSso && (
        <>
          {self.role <= UserRole.HVAdmin && (
            <MenuItem
              onClick={() =>
                selectedUser?.firstAccessDate
                  ? onResetUserPasswordClick(selectedUser)
                  : onResendActivationEmailClick(selectedUser)
              }
              data-testid={HVTestId.ProfileTabPanel.actionsResetResend}
            >
              {selectedUser?.firstAccessDate
                ? HVLocalizeStrings.REACH_USERS_RESET_PASSWORD
                : HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL}
            </MenuItem>
          )}
          {self.role > UserRole.HVAdmin && !selectedUser?.firstAccessDate && (
            <MenuItem
              onClick={() => onResendActivationEmailClick(selectedUser)}
              data-testid={HVTestId.ProfileTabPanel.actionsResendActivation}
            >
              {HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL}
            </MenuItem>
          )}
        </>
      )}
      <MenuItem
        style={{ color: Error_Red }}
        onClick={() => {
          if (selectedUser) {
            handleUserDeletion(selectedUser);
          }
        }}
        data-testid={HVTestId.ProfileTabPanel.actionsDelete}
      >
        {HVLocalizeStrings.REACH_USERS_DELETE_USER}
      </MenuItem>
    </MenuList>
  </Menu>
);

interface ProfileTabPanelProps {
  fullName: string;
  email: string;
  language: string;
  activationDateTime?: Date;
  handleActivationDateTimeChanged: (date: Date) => void;
  user: UpdateSelfServeUserDto | CreateSelfServeUserDto;
  setUser: (user: UpdateSelfServeUserDto | CreateSelfServeUserDto) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isSaving: boolean;
  isMissingFields: boolean;
  userCanEditEmail: boolean;
  setDirtyEmail: (isDirty: boolean) => void;
  setFullName: (fullName: string) => void;
  setEmail: (email: string) => void;
  setLanguage: (language: string) => void;
  goToBulkUploadUsers: () => void;
  handleSaveChanges: () => void;
  getInviteCodeById: any;
  resetStates: () => void;
}

const ProfileTabPanel = (props: ProfileTabPanelProps) => {
  const {
    fullName,
    email,
    language,
    activationDateTime,
    handleActivationDateTimeChanged,
    user,
    setUser,
    isEditing,
    setIsEditing,
    isSaving,
    isMissingFields,
    userCanEditEmail,
    setDirtyEmail,
    setFullName,
    setEmail,
    setLanguage,
    goToBulkUploadUsers,
    handleSaveChanges,
    getInviteCodeById,
    resetStates,
  } = props;
  const { showToast, confirmationDialogRef } =
    useContext<IShellProvider>(ShellContext);
  const {
    selectedUser,
    deleteReachUserFromServer,
    triggerDataRefresh,
    setPasswordResetCodeLink,
    setPasswordResetUser,
    setUpdatePasswordModal,
    setUserModalOpen,
    setSelectedUser,
    readOnlyReachUsers,
  } = useContext(ReachUsersContext);
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const { self } = useContext(GlobalContext);

  const [viewOnly, setViewOnly] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidFullName, setIsValidFullName] = useState<boolean>(true);

  useEffect(() => {
    if (
      self &&
      selectedUser &&
      self.id !== selectedUser.id &&
      selectedUser.role <= UserRole.HVAdmin
    ) {
      setViewOnly(true);
    }
  }, [self, selectedUser]);

  useEffect(() => {
    if (readOnlyReachUsers) {
      setViewOnly(true);
    }
  }, [readOnlyReachUsers]);

  const handleUserDeletion = (user: SelfServeUserDto) => {
    if (user) {
      (confirmationDialogRef.current as any).confirmation(
        HVLocalizeStrings.TEAM_DELETE_USER,
        HVLocalizeStrings.REACH_USER_DELETE_DESCRIPTION.replace(
          "{0}",
          user.fullName
        ),
        ConfirmationButtons.YesNo,
        () => {
          deleteReachUserFromServer(user.id).then(() => {
            showToast(
              true,
              "self-serve-user-delete-success",
              HVLocalizeStrings.REACH_USER_DELETE_SUCCESS
            );
            track(REACH_EVENTS.ReachUsersSelfServeDeleteSingleUser);
            triggerDataRefresh();
            setUserModalOpen(UserModalOpen.NONE);
            setSelectedUser(undefined);
            resetStates();
          });
        }
      );
    }
  };

  const onResetUserPasswordClick = (user: SelfServeUserDto) => {
    requestPasswordResetCodeLink(getKey(), {
      email: user.email,
      isMobile: sessionStorage.getItem("isMobile") === "true" || isMobileDevice,
    } as RequestPasswordCodeDto).then((response: any) => {
      if (response.data) {
        setPasswordResetCodeLink(response.data);
        setPasswordResetUser(user.email);
      }
      setUpdatePasswordModal(true);
    });
  };

  const onResendActivationEmailClick = (user: SelfServeUserDto) => {
    if (!user) return;

    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL,
      HVLocalizeStrings.REACH_USERS_RESEND_ACTIVATION_EMAIL_CONFIRMATION,
      ConfirmationButtons.YesNo,
      () => {
        resendActivationEmail(getKey(), user.id).then(() => {
          showToast(
            true,
            "self-serve-user-resend-activation-email-success",
            HVLocalizeStrings.REACH_USER_ACTIVATION_EMAIL_RESENT
          );
        });
      }
    );
  };

  const setActivationEmail = (selectedUser?: SelfServeUserDto) => {
    if (!selectedUser) return "-";
    if (selectedUser.activationEmailSent) {
      return dayjs
        .utc(selectedUser.activationEmailSent)
        .local()
        .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME);
    }
    if (selectedUser.activationEmailAt) {
      return dayjs
        .utc(selectedUser.activationEmailAt)
        .local()
        .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME);
    }
    return "-";
  };

  return (
    <>
      <Flex
        flexDirection={"row"}
        w={"100%"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontWeight={"semibold"} fontSize={"lg"} color={Black} py={3}>
          {selectedUser?.fullName}
        </Text>
        {!viewOnly && !isEditing && (
          <ActionsMenu
            self={self}
            selectedUser={selectedUser}
            onResetUserPasswordClick={onResetUserPasswordClick}
            onResendActivationEmailClick={onResendActivationEmailClick}
            handleUserDeletion={handleUserDeletion}
          />
        )}
      </Flex>
      <Flex flexDirection={"column"} gap={6} py={6}>
        <Flex flexDirection={"column"} gap={2} pb={4}>
          <UserDates
            label={HVLocalizeStrings.REACH_USERS_CREATED}
            value={
              selectedUser?.createdAt
                ? dayjs
                    .utc(selectedUser?.createdAt.toString())
                    .local()
                    .format(HVLocalizeStrings.DATE_FORMAT)
                : "-"
            }
            dataTestId={HVTestId.ProfileTabPanel.createdDate}
          />
          <UserDates
            label={HVLocalizeStrings.REACH_USERS_ACTIVATION_DATE}
            value={
              selectedUser?.firstAccessDate
                ? dayjs
                    .utc(selectedUser.firstAccessDate.toString())
                    .local()
                    .format(HVLocalizeStrings.DATE_FORMAT)
                : "-"
            }
            dataTestId={HVTestId.ProfileTabPanel.activationDate}
          />
        </Flex>
        {isEditing ? (
          <UserModalEditTabPanel
            fullName={fullName}
            email={email}
            language={language}
            activationDateTime={activationDateTime}
            handleActivationDateTimeChanged={handleActivationDateTimeChanged}
            setFullName={setFullName}
            setEmail={setEmail}
            setLanguage={setLanguage}
            setUser={setUser}
            user={user}
            userCanEditEmail={userCanEditEmail}
            setDirtyEmail={setDirtyEmail}
            activationEmailSent={selectedUser?.activationEmailSent}
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
            setIsValidFullName={setIsValidFullName}
            isValidFullName={isValidFullName}
            nodeIds={selectedUser?.nodeIds}
          />
        ) : (
          <UserModalViewTabPanel
            fullName={selectedUser?.fullName || ""}
            email={selectedUser?.email || ""}
            role={selectedUser?.role === UserRole.Admin ? "Admin" : "Learner"}
            language={getLanguageName(selectedUser?.language as string)}
            activationEmail={setActivationEmail(selectedUser)}
            inviteCode={getInviteCodeById(selectedUser?.inviteCodeId)}
            customInfo={selectedUser?.customInfo}
            isEmailSent={selectedUser?.activationEmailSent !== null}
            associations={selectedUser?.nodeIds}
          />
        )}
        <Flex mt={6} gap={6} flexDirection={"column"} alignItems={"center"}>
          {isEditing ? (
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Primary}
              w={210}
              text={HVLocalizeStrings.SAVE}
              onClick={() => handleSaveChanges()}
              disabled={
                isSaving || isMissingFields || !isValidEmail || !isValidFullName
              }
            />
          ) : (
            !viewOnly && (
              <AnimatedButton
                text={HVLocalizeStrings.REACH_USERS_EDIT}
                onClick={() => {
                  setIsEditing(true);
                  track(REACH_EVENTS.ReachUsersOpenEditSingleUserForm);
                }}
                colorSet={AnimatedButtonColorSet.Third}
                dataTestId={HVTestId.ProfileTabPanel.edit}
              />
            )
          )}
          {!readOnlyReachUsers && (
            <Flex
              flexDirection={isMobile ? "column" : "row"}
              alignItems={"center"}
            >
              <Text color={Equity_Black}>
                {HVLocalizeStrings.REACH_USERS_CUSTOM_FIELDS_TEXT_1}
              </Text>
              <Button
                className={"button-link"}
                textColor={Mid_Blue}
                mx={1}
                onClick={goToBulkUploadUsers}
              >
                {HVLocalizeStrings.REACH_USERS_CUSTOM_FIELDS_TEXT_2}
              </Button>
              <Text color={Equity_Black}>
                {HVLocalizeStrings.REACH_USERS_CUSTOM_FIELDS_TEXT_3}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

interface UserDatesProps {
  label: string;
  value: string;
  dataTestId?: string;
}

const UserDates = (props: UserDatesProps) => {
  const { label, value, dataTestId } = props;
  return (
    <Flex gap={6}>
      <Text
        fontWeight={"semibold"}
        fontSize={"md"}
        color={Equity_Black}
        width={"150px"}
      >
        {label}
      </Text>
      <Text fontSize={"md"} color={Black} data-testid={dataTestId}>
        {value}
      </Text>
    </Flex>
  );
};

export default ProfileTabPanel;
