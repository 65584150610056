import { useContext, useEffect, useRef, useState } from "react";
import {
  CharterActivity,
  TeamLessonUserInstanceGroupingDto,
  TeamLessonUserInstanceParticipantDto,
} from "@headversity/contract";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { Box, Flex, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { ListBox } from "@progress/kendo-react-listbox";
import { Black, Border_Radius } from "../../../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../../../State/ShellContext";
import { ConfirmationButtons } from "../../../Common/ConfirmationDialog";
import { HvSelect } from "../../../Common/HvSelect";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { PresentationContent } from "../Shared/PresentationContent";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import styled from "styled-components";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";
import cloneDeep from "lodash/cloneDeep";

const UserListItem = (props: any) => {
  let {
    dataItem,
    selected,
    readOnly,
    teamLessonUserGrouping,
    setTeamLessonUserGrouping,
    ...others
  } = props;

  const handleGroupLeadChange = () => {
    if (readOnly) return;
    const newGrouping = [...teamLessonUserGrouping.grouping];
    for (let group of newGrouping) {
      if (
        group.users.some(
          (user: GroupEditorUser) => user.userId === dataItem.userId
        )
      ) {
        group.users = group.users.map((user: GroupEditorUser) => ({
          ...user,
          groupLead: user.userId === dataItem.userId,
        }));
        break;
      }
    }
    setTeamLessonUserGrouping({ grouping: newGrouping });
  };

  return (
    <li {...others} disabled={readOnly}>
      <button
        disabled={readOnly}
        onClick={handleGroupLeadChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleGroupLeadChange();
          }
        }}
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: dataItem.groupLead ? "bold" : "normal",
              whiteSpace: "normal",
              textAlign: "start",
            }}
          >
            {dataItem.fullName}
            {dataItem.groupLead
              ? ` (${HVLocalizeStrings.TEAM_GROUPING_REPORTER})`
              : ""}
          </span>
          <input
            type="radio"
            disabled={readOnly}
            checked={dataItem.groupLead}
            style={{ marginLeft: "auto" }}
          />
        </div>
      </button>
    </li>
  );
};

interface PresentationGroupEditorProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}

const StyledListBox = styled.div`
  width: 100%;

  .k-list-scroller.k-selectable {
    padding: 10px;
    border-radius: ${Border_Radius};
  }
`;

interface GroupEditorUser {
  userId: number;
  fullName: string;
  groupLead: boolean;
}

export const PresentationGroupEditor = (
  props: PresentationGroupEditorProps
) => {
  const { setActivity } = props;
  const [teamLessonUserGrouping, setTeamLessonUserGrouping] =
    useState<TeamLessonUserInstanceGroupingDto>({ grouping: [] });

  const [numberOfGroups, setNumberOfGroups] = useState<number>(0);
  const [joinedNames, setJoinedNames] = useState<string[]>([]);
  const [groupNumberOptions, setGroupNumberOptions] = useState<number[]>([1]);
  const draggedUser = useRef<any>(null);

  const setJoinedNamesFromTeamMemberDetails = (
    participantList: TeamLessonUserInstanceParticipantDto[] | undefined
  ) => {
    const names = !participantList
      ? []
      : participantList.map((x: any) => x.user.fullName);
    setJoinedNames(names);
  };

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const {
    currentTeam,
    currentTeamLessonUserInstanceId,
    currentParticipantList,
    setCloseDialog,
    setParticipateGroupingForTeamLessonUserInstanceToServer,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [saveGrouping, setSaveGrouping] = useState<boolean>(false);
  const [previousGrouping, setPreviousGrouping] = useState<TeamLessonUserInstanceGroupingDto>({ grouping: [] });

  useEffect(() => {
    setJoinedNamesFromTeamMemberDetails(currentParticipantList);
  }, [currentParticipantList]);

  useEffect(() => {
    if (currentTeamLessonUserInstanceId) {
      setLoading(true);
      setTeamLessonInProgressStepToServer(
        currentTeamLessonUserInstanceId,
        CharterActivity.Grouping
      ).finally(() => {
        setLoading(false);
      });
    }
  }, [currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (teamLessonUserGrouping.grouping.length > 0 && saveGrouping) {
      saveParticipateGrouping().finally(() => {
        setSaveGrouping(false);
      });
    }
  }, [teamLessonUserGrouping, saveGrouping]);

  useEffect(() => {
    const participantCount = currentParticipantList?.length || 0;
    let groups = 1;

    if (participantCount >= 4 && participantCount <= 8) {
      groups = 2;
    } else if (participantCount >= 9 && participantCount <= 12) {
      groups = 3;
    } else if (participantCount >= 13 && participantCount <= 20) {
      groups = 4;
    } else if (participantCount >= 21 && participantCount <= 29) {
      groups = 5;
    } else if (participantCount >= 30) {
      groups = 6;
    }

    const maxAllowedGroups = Math.min(participantCount, 6);
    const options = Array.from({ length: maxAllowedGroups }, (_, i) => i + 1);
    setGroupNumberOptions(options);
    if (numberOfGroups > maxAllowedGroups || numberOfGroups === 0) {
      setNumberOfGroups(groups);
    }
  }, [currentParticipantList]);

  useEffect(() => {
    doGrouping(numberOfGroups);
  }, [
    currentTeam,
    currentParticipantList,
    currentTeamLessonUserInstanceId,
    numberOfGroups,
  ]);

  const generateGroups = (numberOfGroups: number) => {
    const groups = [];
    for (let i = 1; i <= numberOfGroups; i++) {
      groups.push({
        groupName: `Group ${i}`,
        users: [],
      });
    }
    return groups;
  };

  const doGrouping = (numberOfGroups: number) => {
    if (numberOfGroups === 0) {
      return;
    }
    if (
      currentTeamLessonUserInstanceId &&
      currentParticipantList &&
      currentTeam
    ) {
      const newGrouping: {
        groupName: string;
        users: GroupEditorUser[];
      }[] =
        teamLessonUserGrouping?.grouping?.length === numberOfGroups
          ? teamLessonUserGrouping.grouping.map((group) => ({
              ...group,
              users: [...group.users],
            }))
          : generateGroups(numberOfGroups);

      const existingUserIds = new Set(
        newGrouping.flatMap((group) => group.users.map((user) => user.userId))
      );

      const currentParticipantIds = new Set(
        currentParticipantList.map((participant) => participant.userId)
      );

      for (const group of newGrouping) {
        group.users = group.users.filter((user) =>
          currentParticipantIds.has(user.userId)
        );
      }

      if (existingUserIds.size === currentParticipantList.length) {
        return;
      }

      for (const participant of currentParticipantList) {
        if (existingUserIds.has(participant.userId)) {
          continue;
        }

        let smallestGroup = newGrouping.reduce(
          (smallest, group) =>
            group.users.length < smallest.users.length ? group : smallest,
          newGrouping[0]
        );

        smallestGroup.users.push({
          userId: participant.userId,
          fullName: participant.user.fullName,
          groupLead: smallestGroup.users.length === 0,
        });
      }

      setTeamLessonUserGrouping({ grouping: newGrouping });
      setReadOnly(true);
      setSaveGrouping(true);
    }
  };

  const handleDragStart = (event: any) => {
    draggedUser.current = event.dataItem;
  };

  const assignGroupLead = (newGrouping: any[]) => {
    newGrouping.forEach((group) => {
      if (
        !group.users.some((user: { groupLead: boolean }) => user.groupLead) &&
        group.users.length > 0
      ) {
        group.users[0].groupLead = true;
      }
    });
  };

  const findCurrentGroupIndex = (grouping: any[], userId: string) => {
    return grouping.findIndex((group) =>
      group.users.some((user: { userId: string }) => user.userId === userId)
    );
  };

  const handleDrop = (targetGroupIndex: number) => {
    if (!draggedUser.current || readOnly) return;

    if (previousGrouping.grouping.length === 0) {
      setPreviousGrouping({
        grouping: cloneDeep(teamLessonUserGrouping.grouping)
      });
    }

    const currentGroupIndex = findCurrentGroupIndex(
      teamLessonUserGrouping.grouping,
      draggedUser.current.userId
    );
    if (currentGroupIndex === targetGroupIndex) return;

    const newGrouping = [...teamLessonUserGrouping.grouping];
    for (const group of newGrouping) {
      group.users = group.users.filter(
        (user) => user.userId !== draggedUser.current.userId
      );
    }
    draggedUser.current.groupLead = false;
    newGrouping[targetGroupIndex].users.push(draggedUser.current);

    assignGroupLead(newGrouping);
    setTeamLessonUserGrouping({ grouping: newGrouping });
    draggedUser.current = null;
  };

  const getSingleUserGroups = () => {
    return teamLessonUserGrouping.grouping.filter(
      (group) => group.users.length === 1
    );
  };

  const generateSingleUserGroupsMessage = (
    groups: TeamLessonUserInstanceGroupingDto["grouping"]
  ) => {
    const groupNumbers = groups
      .map((group) => teamLessonUserGrouping.grouping.indexOf(group) + 1)
      .join(", ");
    return HVLocalizeStrings.TEAM_TEAM_GROUPING_ONLY_ONE_USER.replace(
      "{1}",
      groupNumbers
    );
  };

  const { trackEvent } = useSportTeam();

  const saveParticipateGrouping = async () => {
    setLoading(true);
    if (currentTeamLessonUserInstanceId) {
      trackEvent(SPORT_EVENTS.PresenterGroupsEdited);
      await setParticipateGroupingForTeamLessonUserInstanceToServer(
        currentTeamLessonUserInstanceId,
        teamLessonUserGrouping
      ).finally(() => {
        setLoading(false);
      });
    }
  };

  const showSingleUserGroupDialog = (
    groups: TeamLessonUserInstanceGroupingDto["grouping"]
  ) => {
    const message = generateSingleUserGroupsMessage(groups);
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_TEAM_GROUPING_CATCH_UP_END,
      <Text
        color={Black}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />,
      ConfirmationButtons.JSX,
      () => {},
      [
        <Flex
          key="singleUserGroupDialog"
          flexDirection={"column"}
          gap={"5"}
          w={"100%"}
        >
          <Flex
            flexDirection={!isMobile ? "row" : "column"}
            justify={"center"}
            justifyContent={"center"}
            align={"center"}
            gap={"5"}
            w={"100%"}
          >
            <AnimatedButton
              text={HVLocalizeStrings.CONTINUE}
              onClick={async () => {
                setCloseDialog(true);
                await saveParticipateGrouping();
                setReadOnly(true);
              }}
              w={250}
            ></AnimatedButton>
            <AnimatedButton
              text={HVLocalizeStrings.CANCEL}
              colorSet={AnimatedButtonColorSet.Third}
              onClick={() => {
                setCloseDialog(true);
              }}
              w={250}
            ></AnimatedButton>
          </Flex>
        </Flex>,
      ],
      null,
      "2xl"
    );
  };

  const handleSaveGrouping = async () => {
    const groups = getSingleUserGroups();
    if (groups.length > 0) {
      showSingleUserGroupDialog(groups);
    } else {
      await saveParticipateGrouping();
      setReadOnly(true);
      setPreviousGrouping({ grouping: [] });
    }
  };

  const handleCancel = () => {
    setTeamLessonUserGrouping(previousGrouping);
    setReadOnly(true);
    setPreviousGrouping({ grouping: [] });
  };

  const handleDoneGrouping = () => {
    if (currentTeamLessonUserInstanceId) {
      setLoading(true);
      setTeamLessonInProgressStepToServer(currentTeamLessonUserInstanceId, "");
      setActivity(undefined);
    }
  };

  const renderUserListItem = (props: any) => {
    return (
      <UserListItem
        {...props}
        readOnly={readOnly}
        teamLessonUserGrouping={teamLessonUserGrouping}
        setTeamLessonUserGrouping={setTeamLessonUserGrouping}
      />
    );
  };

  return (
    <PresentationContainer>
      <PresentationContent
        hr={false}
        title={HVSportLocalizeStrings.CHARTER_GROUP_TITLE}
        description={HVSportLocalizeStrings.CHARTER_GROUP_DESCRIPTION}
      >
        <Box w={"100%"}>
          <Flex
            my={5}
            justifyContent="start"
            alignItems="start"
            flexDir={"column"}
          >
            <HvSelect
              value={numberOfGroups}
              isDisabled={loading}
              onChange={(event) => {
                const value = parseInt(event.target.value);
                if (!isNaN(value)) {
                  trackEvent(SPORT_EVENTS.PresenterGroupsChanged, {
                    HV_Nb_Groups: value,
                  });
                  setNumberOfGroups(value);
                }
              }}
              w={"150px"}
              bg="white"
              color={Black}
            >
              {groupNumberOptions.map((option) => (
                <option key={option} value={option}>
                  {`${option} ${
                    option === 1
                      ? HVSportLocalizeStrings.CHARTER_GROUP.toLowerCase()
                      : HVSportLocalizeStrings.CHARTER_GROUPS.toLowerCase()
                  }`}
                </option>
              ))}
            </HvSelect>
          </Flex>
          {numberOfGroups !== 0 && (
            <Flex
              flexDirection={isMobile ? "column" : "row"}
              justify={"start"}
              align={"start"}
              w="100%"
              gap={8}
              flexWrap={"wrap"}
              borderWidth={"1px"}
              borderRadius={Border_Radius}
              boxShadow={"sm"}
              borderColor={"#eee"}
              padding={"20px"}
            >
              {teamLessonUserGrouping.grouping.map((g, index) => (
                <Flex
                  flexDir={"column"}
                  p="0"
                  m="0"
                  w={isMobile ? "100%" : "300px"}
                  align={"center"}
                  justify={"center"}
                  key={g.groupName}
                  onDrop={() => handleDrop(index)}
                  flexBasis={"30.53%"}
                  boxSizing={"border-box"}
                >
                  <Text color={Black} mb="2" fontWeight={"semibold"}>
                    {g.groupName}
                  </Text>
                  <StyledListBox>
                    <ListBox
                      style={{
                        height: "auto",
                        width: "100%",
                        minHeight: "51px",
                        textAlign: "start",
                      }}
                      data={g.users}
                      textField={"fullName"}
                      onDragStart={handleDragStart}
                      draggable={!readOnly}
                      item={renderUserListItem}
                    />
                  </StyledListBox>
                </Flex>
              ))}
              <Flex
                mt={1}
                align={"center"}
                justify={"center"}
                gap={"3"}
                flexDir={["column"]}
                w={"100%"}
              >
                <AnimatedButton
                  text={readOnly ? HVLocalizeStrings.EDIT : HVLocalizeStrings.SAVE}
                  colorSet={AnimatedButtonColorSet.Third}
                  onClick={() => {
                    if (readOnly) {
                      setPreviousGrouping({
                        grouping: cloneDeep(teamLessonUserGrouping.grouping)
                      });
                      setReadOnly(false);
                      return;
                    }
                    if (
                      currentTeamLessonUserInstanceId &&
                      teamLessonUserGrouping?.grouping?.length > 0
                    ) {
                      handleSaveGrouping();
                    }
                  }}
                />
                {!readOnly && (
                  <Link
                    fontSize={"14px"}
                    style={{ textDecoration: "underline" }}
                    onClick={handleCancel}
                    mt={2}
                  >
                    {HVLocalizeStrings.CANCEL}
                  </Link>
                )}
              </Flex>
            </Flex>
          )}
        </Box>
      </PresentationContent>
      <Flex
        mt={isMobile ? 2 : 6}
        align={"center"}
        justify={"center"}
        gap={"3"}
        flexDir={["column"]}
        w={"100%"}
      >
        {numberOfGroups !== 0 && (
          <>
            <Text w={"100"} mb={"2"}>
              {HVSportLocalizeStrings.CHARTER_CLICK_PROCEED}
            </Text>
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Primary}
              text={HVLocalizeStrings.DONE}
              w={219}
              onClick={() => {
                if (
                  currentTeamLessonUserInstanceId &&
                  teamLessonUserGrouping?.grouping?.length > 0
                ) {
                  handleDoneGrouping();
                }
              }}
              disabled={loading || (!readOnly && previousGrouping.grouping.length > 0)}
            ></AnimatedButton>
          </>
        )}
      </Flex>
    </PresentationContainer>
  );
};
