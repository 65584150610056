import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { CertViewModel } from "../../Cert/CertViewModels";
import { PathDto } from "@headversity/contract";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HeaderWelcomeText } from "../../Common/HeaderWelcomeText";
import { getCertViewModelFromCert } from "../../../Utils/CertUtil";
import { DateTime } from "luxon";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";
import { CharterBox } from "./CharterBox";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";
import { SportTeamInfo } from "../Management/SportTeamInfo";

export const SportHome = () => {
  const {
    certifications,
    selectedUserLanguage,
    resourceLock,
    paths,
    currentTeam,
  } = useContext(GlobalContext);

  const [cert, setCert] = useState<CertViewModel>();

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const { isLeader } = useSportTeam();

  useEffect(() => {
    const c = certifications.find((x) => x.id === 7);
    if (!c) return;

    setCert(getCertViewModelFromCert(c));
  }, [certifications, selectedUserLanguage, resourceLock]);

  const [charterPath, setCharterPath] = useState<PathDto>();
  const [futureStartDate, setFutureStartDate] = useState<
    DateTime | undefined
  >();

  useEffect(() => {
    if (paths) {
      let currentPaths;
      if (paths["0"] && currentTeam === null) {
        currentPaths = paths["0"];
      } else if (currentTeam?.id != null) {
        currentPaths = paths[currentTeam.id];
      }
      const path = currentPaths?.find((p) => p.internalTitle === "CH_1_P");
      setCharterPath(path);
      if (path?.pathTeamLessons[0]?.futureStartDate) {
        setFutureStartDate(
          DateTime.fromISO(
            path?.pathTeamLessons[0]?.futureStartDate?.toString()
          )
        );
      } else {
        setFutureStartDate(undefined);
      }
    }
  }, [paths, currentTeam]);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }}>
        <BlockSection fadeIn={true} columns={1} noPaddingBottom={true} mx={"0"}>
          <HeaderWelcomeText
            blockSection={true}
            blockSectionMb={0}
            largeFont={isDesktop}
          />
        </BlockSection>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, lg: 1 }}>
        <Box py="10px" px="20px" mb="10px">
          <SportTeamInfo />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, lg: 2 }}>
        {/* temporarily hiding the foundational training section */}
        {/*
          <BlockSection
            fadeIn={true}
            includeSpacing={true}
            title={
              <BlockSectionTitle
                title={HVLocalizeStrings.FOUNDATIONAL_TRAINING}
                description={
                  HVSportLocalizeStrings.CHARTER_FOUNDATIONAL_TRAINING_DESCRIPTION
                }
              />
            }
            columns={1}
            mx={"0"}
          >
            <Box>
              <LessonCourseProvider>
                <CertBlock cert={cert} w="100%" />
              </LessonCourseProvider>
            </Box>
          </BlockSection>
        )}
        */}

        <BlockSection
          fadeIn={true}
          includeSpacing={true}
          title={
            <BlockSectionTitle
              title={HVLocalizeStrings.TEAM_CHARTER}
              description={
                HVSportLocalizeStrings.CHARTER_TEAM_CHARTER_DESCRIPTION
              }
            />
          }
          columns={1}
          mx={"0"}
        >
          <CharterBox
            charterPath={charterPath}
            futureStartDate={futureStartDate}
            currentTeam={currentTeam}
            isLeader={isLeader}
          />
        </BlockSection>
      </SimpleGrid>

      {/* Charter Check-In -- Disabled for now and to be introduced later on
      {!isLeader && (
        <SimpleGrid columns={{ base: 1, lg: 2 }}>
          <BlockSection
            fadeIn={true}
            includeSpacing={true}
            title={
              <BlockSectionTitle
                title={
                  isLeader
                    ? HVSportLocalizeStrings.CHARTER_TEAM_CHECK_IN_LEADER
                    : HVSportLocalizeStrings.CHARTER_TEAM_CHECK_IN_PLAYER
                }
                description={
                  HVSportLocalizeStrings.CHARTER_TEAM_CHECK_IN_DESCRIPTION
                }
              />
            }
            columns={1}
            mx={"0"}
          >
            {isLeader ? <CharterCheckInSummary /> : <CharterCheckIn />}
          </BlockSection>
          {isLeader ? (
            <BlockSection
              fadeIn={true}
              includeSpacing={true}
              title={
                <BlockSectionTitle
                  title={
                    HVSportLocalizeStrings.CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS
                  }
                  description={
                    HVSportLocalizeStrings.CHARTER_TEAM_CHECK_IN_TEAM_PROGRESS_DESCRIPTION
                  }
                />
              }
              columns={1}
              mx={"0"}
            >
              <SportTeamProgress />
            </BlockSection>
          ) : (
            <Box />
          )}
        </SimpleGrid>
      )} */}
    </Box>
  );
};
