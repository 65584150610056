import { Stack, Text } from "@chakra-ui/react";
import { Black } from "../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../Common/HvTextInput";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import { HvSpinner } from "../../Common/HvSpinner";
import { BottomActionBar } from "../../Common/BottomActionBar";

interface PollSingleFreeTextInputProps {
  isPreview?: boolean;
  currentTextAnswer: string;
  setCurrentTextAnswer: (value: string) => void;
  submitPollResponse: () => void;
  instruction?: string;
  questionText?: string;
  disableSubmit: boolean;
  setDisableSubmit: (value: boolean) => void;
  loading: boolean;
  doneButtonMessage?: string;
  questionTextAlignment?: string;
  questionTextFontWeight?: string;
  questionTextColor?: string;
  submitButtonColorSet?: AnimatedButtonColorSet;
  width?: string;
  maxLength?: number;
  useBottomActionBar?: boolean;
}
export const PollSingleFreeTextInput = (
  props: PollSingleFreeTextInputProps
) => {
  const {
    isPreview,
    currentTextAnswer,
    setCurrentTextAnswer,
    questionText,
    instruction,
    submitPollResponse,
    disableSubmit,
    setDisableSubmit,
    loading,
    doneButtonMessage,
    questionTextAlignment,
    questionTextFontWeight,
    questionTextColor,
    submitButtonColorSet,
    width,
    maxLength,
    useBottomActionBar,
  } = props;

  return (
    <>
      <Text
        alignSelf={questionTextAlignment ?? "flex-start"}
        color={questionTextColor ?? Black}
        fontWeight={questionTextFontWeight}
      >
        {questionText as string}
      </Text>
      {instruction && (
        <Text alignSelf={"flex-start"} color={Black} fontSize={"sm"}>
          {instruction}
        </Text>
      )}
      <HvTextInput
        isReadOnly={isPreview}
        disabled={disableSubmit && currentTextAnswer?.trim().length > 0}
        w={width ?? "100%"}
        bg={"white"}
        color={Black}
        value={currentTextAnswer}
        placeholder={HVLocalizeStrings.ENTER_YOUR_RESPONSE}
        onEnterKeyDown={() => {
          if (currentTextAnswer) {
            submitPollResponse();
          }
        }}
        onChange={(e) => {
          setCurrentTextAnswer(e.currentTarget.value);
          setDisableSubmit(e.currentTarget.value?.trim().length === 0);
        }}
        maxLength={maxLength}
        enterKeyHint={"done"}
      />
      {!useBottomActionBar && (
        <Stack spacing={2} h="40px" justify="center" align="center">
          {loading ? (
            <HvSpinner />
          ) : (
            <AnimatedButton
              disabled={disableSubmit}
              colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Primary}
              w={150}
              text={doneButtonMessage ?? HVLocalizeStrings.DONE}
              onClick={submitPollResponse}
              dataTestId={HVTestId.PollQuestion.submitButton}
            />
          )}
        </Stack>
      )}
      {useBottomActionBar && (
        <BottomActionBar
          showOptions={false}
          nextButtonText={HVLocalizeStrings.DONE}
          isNextButtonDisabled={() => disableSubmit}
          colorSet={AnimatedButtonColorSet.Primary}
          onNextButtonClick={submitPollResponse}
          dataTestId={HVTestId.PollQuestion.submitButton}
          loading={loading}
        />
      )}
    </>
  );
};
