import { useContext, useEffect, useRef, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  PollQuestionDto,
  ResponseState,
} from "@headversity/contract";
import _ from "lodash";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { Flex, Icon, Text } from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  sportAllSetGreen,
  Error_Red,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  QuestionUserResponseEditor,
  QuestionUserResponseEditorIconState,
} from "../Shared/QuestionUserResponseEditor";
import { RiThumbUpFill } from "react-icons/ri";
import { GoCommentDiscussion } from "react-icons/go";
import { PresentationContent } from "../Shared/PresentationContent";
import { CharterTimer } from "../Shared/CharterTimer";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { DiscussIcon } from "../Shared/DiscussText";
import { HvSpinner } from "../../../Common/HvSpinner";
import { weStatementLength } from "../Shared/CharterUtil";

interface PresentationWeStatementStepTwoProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationWeStatementStepTwo = (
  props: PresentationWeStatementStepTwoProps
) => {
  const { setActivity } = props;
  const {
    pollQuestions,
    pollInstances,
    currentTeamLessonUserInstanceId,
    setPollInstanceToServer,
    setTeamLessonInProgressStepToServer,
    updatePollUserResponsesToServer,
  } = useContext<IGlobalProvider>(GlobalContext);
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [weStatementPollQuestion, setWeStatementPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const [discussionPollQuestion, setDiscussionPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const { count, total, pollQuestionResults, disableSubmit, setDisableSubmit } =
    usePollQuestionInformation({
      pollQuestion: discussionPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const { pollQuestionResults: weStatementPollResults } =
    usePollQuestionInformation({
      pollQuestion: weStatementPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const [discussionPollResults, setDiscussionPollResults] = useState<number[]>(
    []
  );

  const [goodPollResults, setGoodPollResults] = useState<
    { id: number; responseText: string; state: ResponseState }[]
  >([]);

  const [needDiscussionPollResults, setNeedDiscussionPollResults] = useState<
    { id: number; responseText: string; state: ResponseState }[]
  >([]);

  const [step, setStep] = useState<number>(0);

  const goodPollRef = useRef<HTMLDivElement>(null);
  const needDiscussionPollRef = useRef<HTMLDivElement>(null);
  const [draggingFrom, setDraggingFrom] = useState<string | null>(null); // Track which container the drag is from
  const [dragTarget, setDragTarget] = useState<string | null>(null);

  const handleDragStart = (event: React.DragEvent, key: number) => {
    // Attach the dragged item's ID to the event
    event.dataTransfer.setData("text/plain", key.toString());
  };

  const updateHeights = () => {
    // Reset heights to auto first before recalculating
    if (goodPollRef.current) {
      goodPollRef.current.style.height = "auto";
    }
    if (needDiscussionPollRef.current) {
      needDiscussionPollRef.current.style.height = "auto";
    }

    // Calculate the heights of both columns
    const goodPollHeight = goodPollRef.current?.offsetHeight || 0;
    const needDiscussionPollHeight =
      needDiscussionPollRef.current?.offsetHeight || 0;

    // Set the maxHeight to the tallest column
    const newMaxHeight = Math.max(goodPollHeight, needDiscussionPollHeight);
    if (goodPollRef.current) {
      goodPollRef.current.style.height = newMaxHeight
        ? `${newMaxHeight}px`
        : "auto";
    }
    if (needDiscussionPollRef.current) {
      needDiscussionPollRef.current.style.height = newMaxHeight
        ? `${newMaxHeight}px`
        : "auto";
    }

  };

  const handleDrop = (
    event: React.DragEvent,
    targetList: { id: number; responseText: string; state: ResponseState }[]
  ) => {
    event.preventDefault();

    // Retrieve the ID of the dragged item
    const itemId = parseInt(event.dataTransfer.getData("text/plain"), 10);

    // Find the dragged item in the current lists
    const sourceItem =
      goodPollResults.find((item) => item.id === itemId) ||
      needDiscussionPollResults.find((item) => item.id === itemId);

    // If the item doesn't exist, exit
    if (!sourceItem) return;

    // Avoid adding duplicates
    if (targetList.some((item) => item.id === itemId)) return;

    // Update the state of the source and target lists
    if (goodPollResults.includes(sourceItem)) {
      setGoodPollResults((prev) => prev.filter((item) => item.id !== itemId));
      setNeedDiscussionPollResults((prev) => [...prev, sourceItem]);
    } else {
      setNeedDiscussionPollResults((prev) =>
        prev.filter((item) => item.id !== itemId)
      );
      setGoodPollResults((prev) => [...prev, sourceItem]);
    }

    // After the drop, recalculate heights
    updateHeights();
  };

  // Calculate the heights when the component mounts and when the content changes
  useEffect(() => {
    updateHeights();
  }, []);

  useEffect(() => {
    updateHeights(); // Recalculate heights whenever the content changes (ensuring the columns are the same height to make drag/drop area clearer)
  }, [goodPollResults, needDiscussionPollResults]);

  useEffect(() => {
    if (pollQuestions) {
      const weStatementPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setWeStatementPollQuestion(weStatementPollQuestion);

      const discussionPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_8_PQ"
      );
      setDiscussionPollQuestion(discussionPollQuestion);
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (
      discussionPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const currentPollInstance = getLatestPollInstanceByPollQuestionId(
        discussionPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepTwo
        );
        return;
      }
      setPollInstanceToServer(
        discussionPollQuestion.id,
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepTwo
        );
      });
    }
  }, [
    discussionPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (pollQuestionResults) {
      const finalIdsNeedToDiscuss: number[] = [];
      pollQuestionResults.forEach((item) => {
        if (item.responseText) {
          const idsNeedToDiscuss = item.responseText.split(",");
          idsNeedToDiscuss.forEach((id) => {
            finalIdsNeedToDiscuss.push(parseInt(id));
          });
        }
      });

      setDiscussionPollResults(_.uniq(finalIdsNeedToDiscuss));
    }
  }, [pollQuestionResults]);

  useEffect(() => {
    if (weStatementPollResults && discussionPollResults) {
      const result: any[] = [];
      const discussionResult: any[] = [];
      weStatementPollResults.forEach((item) => {
        if (item.state !== ResponseState.active) {
          return;
        }
        if (discussionPollResults.indexOf(item.id) > -1) {
          discussionResult.push({
            id: item.id,
            responseText: item.responseText,
            state: item.state,
          });
        } else {
          result.push({
            id: item.id,
            responseText: item.responseText,
            state: item.state,
          });
        }
      });
      setGoodPollResults(result);
      setNeedDiscussionPollResults(discussionResult);
    }
  }, [weStatementPollResults, discussionPollResults]);

  return (
    <PresentationContainer>
      {step === 0 ? (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
          description={
            HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_DESCRIPTION
          }
          listItems={[
            {
              icon: "https://cdn.headversity.com/app/sport/group-reporter-device.svg",
              text: HVSportLocalizeStrings.CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE,
            },
            {
              icon: "https://cdn.headversity.com/app/sport/discussion.svg",
              text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={discussionPollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
        </PresentationContent>
      ) : (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_FINAL_TITLE}
          description={
            <Flex flexDir={"column"} gap={"5"}>
              <Flex flexDir={"row"} alignItems={"center"} gap={"2"}>
                <DiscussIcon />
                <Text>
                  {
                    HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_INSTRUCTION_ONE
                  }
                </Text>
              </Flex>
            </Flex>
          }
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={discussionPollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
          <Flex flexDir={"row"} align={"start"} justify={"start"} w={"100%"}>
            <Flex
              ref={goodPollRef}
              flexDir={"column"}
              align={"start"}
              justify={"start"}
              w={"50%"}
              p={"5"}
              gap={"2"}
              onDragOver={(e) => {
                e.preventDefault(); // Allow drop
                if (draggingFrom !== "goodPollResults") {
                  setDragTarget("goodPollResults"); // Set target border only if it's not coming from this column
                }
                updateHeights();
              }}
              onDragLeave={() => {
                if (dragTarget === "goodPollResults") {
                  setDragTarget(null); // Reset drag target
                }
              }}
              onDrop={(e) => {
                handleDrop(e, goodPollResults);
                setDragTarget(null); // Reset after drop
              }}
              onDragStart={() => {
                setDraggingFrom("goodPollResults"); // Track that drag started from this container
              }}
              border={
                dragTarget === "goodPollResults"
                  ? `2px dotted ${sportAllSetGreen}`
                  : "none"
              } // Apply border only if target
            >
              <Flex
                align={"center"}
                justify={"center"}
                flexDir={"column"}
                color={sportAllSetGreen}
                w={"100%"}
              >
                <Icon
                  w={"30px"}
                  h={"30px"}
                  aria-label={"like"}
                  as={RiThumbUpFill}
                  color={sportAllSetGreen}
                />
                <Text textAlign={"center"}>
                  {HVSportLocalizeStrings.ALL_SET}
                </Text>
              </Flex>
              <QuestionUserResponseEditor
                editedPollResults={goodPollResults}
                setEditedPollResults={setGoodPollResults}
                iconState={QuestionUserResponseEditorIconState.alwaysShowRemove}
                maxLength={weStatementLength}
                onDragStart={handleDragStart}
                draggable={true}
                disableEditOnClick={true}
              />
            </Flex>
            <Flex
              ref={needDiscussionPollRef}
              flexDir={"column"}
              align={"start"}
              justify={"start"}
              w={"50%"}
              p={"5"}
              gap={"2"}
              onDragOver={(e) => {
                e.preventDefault(); // Allow drop
                if (draggingFrom !== "needDiscussionPollResults") {
                  setDragTarget("needDiscussionPollResults"); // Set target border only if it's not coming from this column
                }
                updateHeights();
              }}
              onDragLeave={() => {
                if (dragTarget === "needDiscussionPollResults") {
                  setDragTarget(null); // Reset drag target
                }
              }}
              onDrop={(e) => {
                handleDrop(e, needDiscussionPollResults);
                setDragTarget(null); // Reset after drop
              }}
              onDragStart={() => {
                setDraggingFrom("needDiscussionPollResults"); // Track that drag started from this container
              }}
              border={
                dragTarget === "needDiscussionPollResults"
                  ? `2px dotted ${Error_Red}`
                  : "none"
              } // Apply border only if target
            >
              <Flex
                align={"center"}
                justify={"center"}
                flexDir={"column"}
                color={Error_Red}
                w={"100%"}
              >
                <Icon
                  w={"30px"}
                  h={"30px"}
                  aria-label={"like"}
                  as={GoCommentDiscussion}
                  color={Error_Red}
                />
                <Text textAlign={"center"}>
                  {HVSportLocalizeStrings.DISCUSS_EDIT}
                </Text>
              </Flex>
              <QuestionUserResponseEditor
                editedPollResults={needDiscussionPollResults}
                setEditedPollResults={setNeedDiscussionPollResults}
                iconState={QuestionUserResponseEditorIconState.alwaysShowRemove}
                maxLength={weStatementLength}
                onDragStart={handleDragStart}
                draggable={true}
                disableEditOnClick={true}
              />
            </Flex>
          </Flex>
        </PresentationContent>
      )}
      <Flex
        flexDir={"column"}
        align={"center"}
        data-testid={HVTestId.PollResult.totalVotes}
      >
        <Text color={Black} fontWeight={"semibold"}>
          {HVSportLocalizeStrings.CHARTER_STATISTICS}
        </Text>
        <Text color={Black} fontWeight={"semibold"} fontSize={"xl"}>
          {count} / {total}
        </Text>
      </Flex>

      {loading ? (
        <HvSpinner />
      ) : (
        <AnimatedButton
          disabled={disableSubmit || count < 1 || loading}
          colorSet={AnimatedButtonColorSet.Primary}
          w={150}
          text={HVLocalizeStrings.CONTINUE}
          onClick={async () => {
            if (currentTeamLessonUserInstanceId) {
              if (step === 0) {
                setStep(1);
              } else {
                setLoading(true);
                await setTeamLessonInProgressStepToServer(
                  currentTeamLessonUserInstanceId,
                  ""
                );

                if (
                  (goodPollResults || needDiscussionPollResults) &&
                  weStatementPollQuestion
                ) {
                  const pollInstance = getLatestPollInstanceByPollQuestionId(
                    weStatementPollQuestion.id,
                    pollInstances,
                    currentTeamLessonUserInstanceId
                  );

                  if (pollInstance) {
                    await updatePollUserResponsesToServer(
                      pollInstance?.id,
                      goodPollResults
                        .concat(needDiscussionPollResults)
                        .map((e) => {
                          return {
                            responseText: e.responseText,
                            state: e.state,
                            questionUserResponseId: e.id,
                          };
                        })
                    );
                  }
                }
                setActivity(undefined);
              }
            }
          }}
        />
      )}
    </PresentationContainer>
  );
};
