import {
  AvailableReportsDto,
  CertDto,
  CertReport,
  GetSelfServeUsersDto,
  GoalWinThemeDto,
  HelpResourcesReport,
  HierarchyAssociationStatusDto,
  HierarchyCompareDto,
  IInsightsFilter,
  InviteCodeForReachDto,
  NodeDto,
  PromotionalMaterialAssetDto,
  PromotionalMaterialDto,
  PsychographicsReport,
  SelfServeUserDto,
  SoloTeamReport,
  UpdateSelfServeUserDto,
  UserCertStatsDto,
  UsersReport,
  HierarchyResponse,
  LocalizationText,
  CreateNodeDto,
  UpdateNodeDto,
  RequestCsvJobResponseDto,
} from "@headversity/contract";
import {
  EligibilityFileJobDto,
  GetEligibilityFileJobsDto,
} from "@headversity/contract/Dto/EligibilityFileJobDto";
import { AxiosResponse } from "axios";
import { EligibilityImportType } from "../../State/Reach/ReachUsersContext";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
const REACH_BASE_URL = "/api/promotionalMaterials";

export const getPromoMaterials = (
  token: string
): Promise<AxiosResponse<PromotionalMaterialDto[]>> => {
  return AxiosClient.get<PromotionalMaterialDto[]>(
    REACH_BASE_URL,
    getConfig(token)
  );
};

const isFalsy = (val?: string): boolean => {
  return val === undefined || val === null || val === "";
};

export const notifyDownloadCsvComplete = async (
  jobId: number,
  token: string
): Promise<any> => {
  const response = await AxiosClient.post<any>(
    `/api/users/requestCsv/${jobId}/complete`,
    null,
    getConfig(token)
  );
  return response.data;
};

export const downloadUserCSv = async (
  jobId: number,
  token: string
): Promise<GetSelfServeUsersDto | undefined> => {
  const downloadUrl = await AxiosClient.get<string>(
    `/api/users/requestCsv/${jobId}`,
    getConfig(token)
  );
  if (downloadUrl.data) {
    const downloadedData = await AxiosClient.get<GetSelfServeUsersDto>(
      downloadUrl.data as string
    );
    return downloadedData.data;
  }
  return undefined;
};

export const requestCsvUsers = async (
  filters: {
    sortBy?: string;
    sortDir?: string;
    searchFilter?: string;
    certsFilter?: number[];
    completionStatusesFilter?: number[];
    unassociatedUsers?: boolean;
    nodesFilter?: number[];
  },
  token: string
): Promise<AxiosResponse<RequestCsvJobResponseDto>> => {
  const {
    sortBy,
    sortDir,
    searchFilter,
    certsFilter,
    completionStatusesFilter,
    unassociatedUsers,
    nodesFilter,
  } = filters;

  let queryString = `page=1&sortBy=${
    !isFalsy(sortBy) ? sortBy : "email"
  }&sortDir=${sortDir ?? "asc"}&searchFilter=${
    searchFilter ?? ""
  }&fetchForCsv=true`;

  if (certsFilter && certsFilter.length > 0) {
    const certsFilterString = certsFilter.join(",");
    queryString += `&certsFilter=${certsFilterString}`;
  }

  if (completionStatusesFilter && completionStatusesFilter.length > 0) {
    const completionStatusesFilterString = completionStatusesFilter.join(",");
    queryString += `&completionStatusesFilter=${completionStatusesFilterString}`;
  }

  if (unassociatedUsers) {
    queryString += `&unassociatedUsers=true`;
  }
  if (nodesFilter && nodesFilter.length > 0) {
    const nodesFilterString = nodesFilter.join(",");
    queryString += `&nodesFilter=${nodesFilterString}`;
  }

  return AxiosClient.post<RequestCsvJobResponseDto>(
    `/api/users/requestCsv?${queryString}`,
    null,
    getConfig(token)
  );
};

export const getReachUsers = async (filters: {
  token: string;
  usersPerPage: number;
  page: number;
  sortBy?: string;
  sortDir?: string;
  searchFilter?: string;
  certsFilter?: number[];
  completionStatusesFilter?: number[];
  fetchForCsv?: boolean;
  unassociatedUsers?: boolean;
  nodesFilter?: number[];
}): Promise<AxiosResponse<GetSelfServeUsersDto>> => {
  const {
    token,
    usersPerPage,
    page,
    sortBy,
    sortDir,
    searchFilter,
    certsFilter,
    completionStatusesFilter,
    fetchForCsv,
    unassociatedUsers,
    nodesFilter,
  } = filters;

  let queryString = `page=${page}&limit=${usersPerPage}&sortBy=${
    !isFalsy(sortBy) ? sortBy : "email"
  }&sortDir=${sortDir ?? "asc"}&searchFilter=${
    searchFilter ?? ""
  }&fetchForCsv=${fetchForCsv ?? false}`;

  if (certsFilter && certsFilter.length > 0) {
    const certsFilterString = certsFilter.join(",");
    queryString += `&certsFilter=${certsFilterString}`;
  }

  if (completionStatusesFilter && completionStatusesFilter.length > 0) {
    const completionStatusesFilterString = completionStatusesFilter.join(",");
    queryString += `&completionStatusesFilter=${completionStatusesFilterString}`;
  }

  if (unassociatedUsers) {
    queryString += `&unassociatedUsers=true`;
  }
  if (nodesFilter && nodesFilter.length > 0) {
    const nodesFilterString = nodesFilter.join(",");
    queryString += `&nodesFilter=${nodesFilterString}`;
  }

  return AxiosClient.get<GetSelfServeUsersDto>(
    `/api/users?${queryString}`,
    getConfig(token)
  );
};

export const getEligibilityJobs = async (
  token: string,
  limit?: number,
  page?: number
): Promise<AxiosResponse<GetEligibilityFileJobsDto>> => {
  return AxiosClient.get<GetEligibilityFileJobsDto>(
    `/api/users/jobs?sortBy=updated_at&sortDir=desc&filter=${page ?? 1}&limit=${
      limit ?? 100
    }`,
    getConfig(token)
  );
};

export const getCompanyInviteCodesForReach = async (
  token: string
): Promise<AxiosResponse<InviteCodeForReachDto[]>> => {
  return AxiosClient.get<InviteCodeForReachDto[]>(
    "/api/users/inviteCodes",
    getConfig(token)
  );
};

export const deleteReachUser = async (
  token: string,
  userId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(`/api/users/${userId}`, getConfig(token));
};

export const upsertReachUser = async (
  token: string,
  userDto?: UpdateSelfServeUserDto
): Promise<AxiosResponse<SelfServeUserDto>> => {
  if (userDto?.id) {
    return AxiosClient.put<SelfServeUserDto>(
      `/api/users/${userDto.id}`,
      userDto,
      getConfig(token)
    );
  }
  return AxiosClient.post<SelfServeUserDto>(
    `/api/users`,
    userDto,
    getConfig(token)
  );
};

export const uploadEligibilityFileCsv = async (
  file: any,
  importType: EligibilityImportType,
  timezone: string,
  certAssignments: number[],
  token: string
): Promise<AxiosResponse<EligibilityFileJobDto>> => {
  // create a new FormData object and append the file to it
  const formData = new FormData();
  formData.append("file", file);
  formData.append("importType", EligibilityImportType[importType]);
  formData.append("certAssignments", certAssignments.join(","));
  const encodedTimezone = encodeURIComponent(timezone);
  return AxiosClient.post<any>(
    `/api/users/upload?tz=${encodedTimezone}`,
    formData,
    getConfig(token)
  );
};

export const getCompanyCertsForReach = async (
  companyId: number,
  token: string
): Promise<AxiosResponse<CertDto[]>> => {
  return AxiosClient.get<CertDto[]>(
    `/api/companies/${companyId}/certs`,
    getConfig(token)
  );
};

export const confirmEligibilityFileJob = async (
  jobId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `/api/users/jobs/${jobId}/confirm`,
    null,
    getConfig(token)
  );
};

export const savePromoMaterialDownloaded = (
  token: string,
  promoMaterialAssetId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `${REACH_BASE_URL}/assetDownloaded/${promoMaterialAssetId}`,
    null,
    getConfig(token)
  );
};

export const getScormUrl = (
  certId: number,
  token: string
): Promise<AxiosResponse<string>> => {
  return AxiosClient.get<string>(`/api/scorm/${certId}`, getConfig(token));
};

export const getPromoMaterialAssets = (
  promoMaterialId: number,
  token: string
): Promise<AxiosResponse<PromotionalMaterialAssetDto[]>> => {
  return AxiosClient.get<PromotionalMaterialAssetDto[]>(
    `${REACH_BASE_URL}/${promoMaterialId}/assets`,
    getConfig(token)
  );
};

export const getCertsStatsForUser = async (
  userId: number,
  token: string
): Promise<AxiosResponse<UserCertStatsDto[]>> => {
  return AxiosClient.get<UserCertStatsDto[]>(
    `/api/users/${userId}/certs`,
    getConfig(token)
  );
};

export const unAssignCertFromUser = async (
  userId: number,
  certId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.delete<void>(
    `/api/users/${userId}/certs/${certId}`,
    getConfig(token)
  );
};

export const assignCertToUser = async (
  userId: number,
  certId: number,
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `/api/users/${userId}/certs/${certId}`,
    null,
    getConfig(token)
  );
};

export const saveCompanyGoalWinThemes = (
  goalWinThemes: GoalWinThemeDto[],
  token: string
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post<void>(
    `api/goal-win-themes`,
    goalWinThemes,
    getConfig(token)
  );
};

export const reportUsers = async (
  token: string,
  filters?: IInsightsFilter,
  allNodes?: boolean
): Promise<AxiosResponse<UsersReport>> => {
  const unassociatedUsers = filters?.nodesFilter?.some((n) => n === -1);
  const validFilters = filters?.nodesFilter?.filter((n) => n >= 1);
  let endpointUrl = `/api/reports/users`;
  endpointUrl = appendNodesFilter(endpointUrl, validFilters, unassociatedUsers);
  if (allNodes) {
    if (endpointUrl.includes("?")) {
      endpointUrl += "&allNodes=true";
    } else {
      endpointUrl += "?allNodes=true";
    }
  }

  return AxiosClient.get<UsersReport>(endpointUrl, getConfig(token));
};

export const reportCert = async (
  token: string,
  filters?: IInsightsFilter
): Promise<AxiosResponse<CertReport>> => {
  const unassociatedUsers = filters?.nodesFilter?.some((n) => n === -1);
  const validFilters = filters?.nodesFilter?.filter((n) => n >= 1);
  let endpointUrl = `/api/reports/cert?unassociatedUsers=${unassociatedUsers}`;
  endpointUrl = appendNodesFilter(endpointUrl, validFilters, unassociatedUsers);
  return AxiosClient.get<CertReport>(endpointUrl, getConfig(token));
};

const appendNodesFilter = (
  endpointUrl: string,
  filters?: number[],
  unassociatedUsers?: boolean
): string => {
  if (filters && filters.length > 0) {
    endpointUrl += `?nodesFilter=${filters.join(",")}`;
  }
  if (unassociatedUsers) {
    if (endpointUrl.includes("?")) {
      endpointUrl += `&unassociatedUsers=true`;
    } else endpointUrl += `?unassociatedUsers=true`;
  }
  return endpointUrl;
};

export const reportSoloTeam = async (
  token: string,
  filters?: IInsightsFilter
): Promise<AxiosResponse<SoloTeamReport>> => {
  const unassociatedUsers = filters?.nodesFilter?.some((n) => n === -1);
  const validFilters = filters?.nodesFilter?.filter((n) => n >= 1);
  let endpointUrl = `/api/reports/soloTeam`;
  endpointUrl = appendNodesFilter(endpointUrl, validFilters, unassociatedUsers);
  return AxiosClient.get<SoloTeamReport>(endpointUrl, getConfig(token));
};

export const reportPsychographics = async (
  token: string,
  filters?: IInsightsFilter
): Promise<AxiosResponse<PsychographicsReport>> => {
  let endpointUrl = "/api/reports/psychographics";
  const unassociatedUsers = filters?.nodesFilter?.some((n) => n === -1);
  const validFilters = filters?.nodesFilter?.filter((n) => n >= 1);
  endpointUrl = appendNodesFilter(endpointUrl, validFilters, unassociatedUsers);
  return AxiosClient.get<PsychographicsReport>(endpointUrl, getConfig(token));
};

export const reportGetHelp = async (
  token: string,
  filters?: IInsightsFilter
): Promise<AxiosResponse<HelpResourcesReport>> => {
  let endpointUrl = "/api/reports/helpResources";
  const unassociatedUsers = filters?.nodesFilter?.some((n) => n === -1);
  const validFilters = filters?.nodesFilter?.filter((n) => n >= 1);
  endpointUrl = appendNodesFilter(endpointUrl, validFilters, unassociatedUsers);
  return AxiosClient.get<HelpResourcesReport>(endpointUrl, getConfig(token));
};

export const getAvailableReports = async (
  token: string
): Promise<AxiosResponse<AvailableReportsDto>> => {
  return AxiosClient.get<AvailableReportsDto>(
    `/api/reports/available`,
    getConfig(token)
  );
};

export const getCompanyHierarchy = async (
  token: string
): Promise<AxiosResponse<NodeDto[]>> => {
  return AxiosClient.get<NodeDto[]>(`/api/hierarchies`, getConfig(token));
};

export const getHierarchy = async (
  token: string,
  rootId: number,
  fetchMode: string
): Promise<AxiosResponse<HierarchyResponse>> => {
  return AxiosClient.get<HierarchyResponse>(
    `/api/hierarchies?rootId=${rootId}&fetchMode=${fetchMode}`,
    getConfig(token)
  );
};

export const getFilterableHierarchy = async (
  token: string
): Promise<AxiosResponse<NodeDto[]>> => {
  return AxiosClient.get<any>(`/api/hierarchies/filterable`, getConfig(token));
};

export const moveUser = async (
  token: string,
  sourceNodeId: number,
  userId: number,
  targetNodeId: number
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/users?sourceId=${sourceNodeId}&resourceId=${userId}&targetId=${targetNodeId}&op=Move`,
    undefined,
    getConfig(token)
  );
};

export const addUser = async (
  token: string,
  targetNodeId: number,
  userId: number
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/users?targetId=${targetNodeId}&resourceId=${userId}&op=Add`,
    undefined,
    getConfig(token)
  );
};

export const deleteUser = async (
  token: string,
  sourceNodeId: number,
  userId: number
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/users?targetId=${sourceNodeId}&resourceId=${userId}&op=Delete`,
    undefined,
    getConfig(token)
  );
};

export const createHierarchy = async (
  token: string,
  name: LocalizationText
): Promise<AxiosResponse<HierarchyResponse>> => {
  return AxiosClient.post<HierarchyResponse>(
    `/api/hierarchies`,
    {
      name,
    } as CreateNodeDto,
    getConfig(token)
  );
};

export const addNode = async (
  token: string,
  targetNodeId: number,
  name: string,
  revision: string
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/nodes?targetId=${targetNodeId}&name=${name}&op=Add&revision=${revision}`,
    undefined,
    getConfig(token)
  );
};

export const moveNode = async (
  token: string,
  nodeId: number,
  newParentId: number,
  revision: string
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/nodes?nodeId=${nodeId}&targetId=${newParentId}&op=Move&revision=${revision}`,
    undefined,
    getConfig(token)
  );
};

export const deleteNode = async (
  token: string,
  nodeIds: number[],
  revision: string
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/nodes?nodeIds=${nodeIds}&op=Delete&revision=${revision}`,
    undefined,
    getConfig(token)
  );
};

export const editNode = async (
  token: string,
  nodeId: number,
  updates: UpdateNodeDto,
  revision: string
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/hierarchies/nodes?nodeId=${nodeId}&op=Edit&revision=${revision}`,
    updates,
    getConfig(token)
  );
};

export const associateUserToNodes = async (
  token: string,
  nodeIds: number[]
): Promise<AxiosResponse<any>> => {
  return AxiosClient.post<any>(
    `/api/self/association`,
    { nodeIds: nodeIds },
    getConfig(token)
  );
};

export const getUserNodes = async (
  token: string
): Promise<AxiosResponse<HierarchyAssociationStatusDto>> => {
  return AxiosClient.get<HierarchyAssociationStatusDto>(
    `/api/self/association`,
    getConfig(token)
  );
};

export const getHierarchySnapshots = async (
  token: string,
  page: number
): Promise<AxiosResponse<any>> => {
  return AxiosClient.get<any>(
    `/api/hierarchies/snapshots?page=${page}`,
    getConfig(token)
  );
};

export const compareSnapshot = async (
  token: string,
  snapshotId: number
): Promise<AxiosResponse<HierarchyCompareDto>> => {
  return AxiosClient.get<HierarchyCompareDto>(
    `/api/hierarchies/snapshots/${snapshotId}/compare`,
    getConfig(token)
  );
};

export const restoreSnapshot = async (
  token: string,
  snapshotId: number
): Promise<AxiosResponse<HierarchyResponse>> => {
  return AxiosClient.post<HierarchyResponse>(
    `/api/hierarchies/snapshots/${snapshotId}/restore`,
    undefined,
    getConfig(token)
  );
};
