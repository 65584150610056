import { Input, InputProps, forwardRef } from "@chakra-ui/react";
import {
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";

interface TextInputProps extends InputProps {
  onEnterKeyDown?: () => void;
  maxLength?: number;
  dataTestId?: string;
  disabled?: boolean;
  bg?: string;
  color?: string;
}

export const HvTextInput = forwardRef<TextInputProps, "div">((props, ref) => {
  const { onEnterKeyDown, maxLength, dataTestId, bg, color, ...rest } = props;
  return (
    <Input
      type={"text"}
      height={"50px"}
      minHeight={"50px"}
      bg={bg ?? INPUT_BACKGROUND_COLOR}
      color={color ?? INPUT_PRIMARY_TEXT_COLOR}
      onKeyDown={(e) => {
        if (onEnterKeyDown && e.key === "Enter" && !e.currentTarget.disabled) {
          onEnterKeyDown();
        }
      }}
      ref={ref}
      {...rest}
      disabled={props.disabled}
      maxLength={maxLength ?? 150}
      data-testid={dataTestId}
      enterKeyHint={
        props.enterKeyHint as
          | "search"
          | "enter"
          | "done"
          | "go"
          | "next"
          | "previous"
          | "send"
          | undefined
      }
    />
  );
});
