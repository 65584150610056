import { useCallback, useEffect, useState } from "react";
import {
  AuthTokensDto,
  CompanyDto,
  GoalWinThemeDto,
  HierarchyAssociationStatusDto,
  InviteCodeDto,
  UserAppAccessInputDto,
  UserDto,
  UserStateDto,
} from "@headversity/contract";
import {
  getSelf,
  saveSiteLoadedEvent,
  updateSelectedUserTheme,
  getRedirectUrl,
  setUserState,
} from "../../Api/People/UserApi";
import {
  getUserNodes,
  saveCompanyGoalWinThemes,
} from "../../Api/Reach/ReachApi";
import { getKey } from "../../Utils/Helpers";
import { identify } from "../../Utils/Analytics";
import { isWebPlatform } from "../../Utils/mobileUtils";
import {
  getUserCompany,
  getUserInviteCode,
  updateCommunicationSettingsAllowedDomains,
  updateFullName,
} from "../../Api/Solo/ProfileApi";
import { languageObj } from "../../App";
import { updateSelectedUserLanguage } from "../../Api/Solo/LanguageApi";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { AxiosResponse } from "axios";
import {
  flushLocalStorage,
  getUserAppInfo,
  setLocalStorageAuthTokens,
} from "../../Utils/LoginUtil";
import { DeviceInfo } from "@capacitor/device";
import { SELECTED_USER_LANGUAGE_KEY } from "../../Utils/LanguageUtil";
import {
  loginWithMsTeamsToken,
  logoutFromApi,
} from "../../Api/People/AccessTokenApi";
import { loginPath } from "../../Utils/NavigationUtils";
import { assignCertsToUserViaUrl } from "../../Api/Cert/CertApi";

export interface IProfileProvider {
  languages: string[];
  self: UserDto | null;
  setSelf: (self: UserDto | null) => void;
  selectedUserLanguage: string;
  selectedUserLocale: string;
  company: CompanyDto | null;
  inviteCode: InviteCodeDto | null;
  getSelfFromServer: () => Promise<UserDto>;
  userHierarchyAssociations: HierarchyAssociationStatusDto | undefined;
  getHierarchyAssocationsFromServer: () => Promise<HierarchyAssociationStatusDto>;
  isHierarchyAssociationStepComplete: boolean;
  setIsHierarchyAssociationStepComplete: (
    isHierarchyAssocationStepComplete: boolean
  ) => void;
  assignCertsToUser: (certIds: string) => Promise<AxiosResponse<any>>;
  setSelectedUserLanguage: (language: string) => void;
  getUserCompanyFromServer: () => Promise<CompanyDto>;
  getInviteCodeCompanyFromServer: () => Promise<InviteCodeDto>;
  updateSelectedUserLanguageToServer: (self: UserDto, language: string) => void;
  updateFullNameToServer: (fullName: string) => Promise<void>;
  updateSelectedUserThemeToServer: (themeSelected: string) => Promise<any>;
  saveSiteLoadedEventToServer: () => Promise<AxiosResponse<any>>;
  setUserStateToServer: (userStateDto: UserStateDto) => Promise<void>;
  deviceInfo?: DeviceInfo;
  updateCommunicationSettingsAllowedDomainsToServer: (
    self: UserDto | null,
    isChecked: boolean
  ) => void;
  saveGoalWinThemes: (goalWinThemes: GoalWinThemeDto[]) => Promise<void>;
  getRedirectUrlFromServer: (
    path: string,
    redirectDomain: string
  ) => Promise<string>;
  logout: () => void;
  loginWithMsTeamsTokenToServer: (
    token: string,
    jwtToken?: string,
    inviteCodeId?: number
  ) => Promise<AuthTokensDto>;
}

export const languages = ["en", "fr", "es"];

export const useProfile = (): IProfileProvider => {
  const [self, setSelf] = useState<UserDto | null>(null);
  const [company, setCompany] = useState<CompanyDto | null>(null);
  const [inviteCode, setInviteCode] = useState<InviteCodeDto | null>(null);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>(
    undefined
  );

  const [selectedUserLanguage, setSelectedUserLanguagePrivate] =
    useState<string>("");
  const [selectedUserLocale, setSelectedUserLocalePrivate] =
    useState<string>("en-US");

  const [userHierarchyAssociations, setUserHierarchyAssociations] = useState<
    HierarchyAssociationStatusDto | undefined
  >();
  const [
    isHierarchyAssociationStepComplete,
    setIsHierarchyAssociationStepComplete,
  ] = useState<boolean>(false);

  const setSelectedUserLanguage = (language: string) => {
    languageObj.selectedLanguage = language;
    localStorage.setItem(SELECTED_USER_LANGUAGE_KEY, language);
    setSelectedUserLanguagePrivate(language);
  };

  const loginWithMsTeamsTokenToServer = useCallback(
    async (token: string, jwtToken?: string, inviteCodeId?: number) => {
      const response = await loginWithMsTeamsToken(
        token,
        jwtToken,
        inviteCodeId
      );
      return response.data as AuthTokensDto;
    },
    []
  );
  const getHierarchyAssocationsFromServer =
    useCallback(async (): Promise<HierarchyAssociationStatusDto> => {
      return getUserNodes(getKey()).then((response) => {
        setUserHierarchyAssociations(response.data);
        if (!response.data.requiresAssociation || response.data.hasRelations) {
          setIsHierarchyAssociationStepComplete(true);
        }
        return response.data;
      });
    }, []);

  const assignCertsToUser = useCallback(async (certIds: string) => {
    return await assignCertsToUserViaUrl(getKey(), certIds);
  }, []);

  /*
 Please refrain from calling this function more than once, as it triggers
 saveSiteLoaded() used to log when users load the site.
  */
  const getSelfFromServer = useCallback(async () => {
    return getSelf(getKey()).then(
      (response) => {
        const user = response.data;

        let lang = "en";
        if (user.language != null && user.language.trim() !== "") {
          lang = user.language;
        }

        setSelectedUserLanguage(lang);
        saveSiteLoadedEventToServer();
        // save to state and return for immediate use
        setSelf(user);
        return user;
      },
      (error) => {
        console.log(error);
        setLocalStorageAuthTokens().then(() => {
          window.location.replace(isWebPlatform() ? "/login" : "/mobileLogin");
        });
      }
    );
  }, []);

  const saveSiteLoadedEventToServer = useCallback(async () => {
    let useAppInfo: UserAppAccessInputDto = await getUserAppInfo(setDeviceInfo);
    return saveSiteLoadedEvent(getKey(), useAppInfo);
  }, []);

  const getUserCompanyFromServer =
    useCallback(async (): Promise<CompanyDto> => {
      return getUserCompany(getKey()).then((response) => {
        const company = response.data;
        setCompany(company);
        return company;
      });
    }, []);

  const getInviteCodeCompanyFromServer =
    useCallback(async (): Promise<InviteCodeDto> => {
      return getUserInviteCode(getKey()).then((response) => {
        const inviteCode = response.data;
        setInviteCode(inviteCode);
        return inviteCode;
      });
    }, []);

  const updateSelectedUserLanguageToServer = useCallback(
    async (self: UserDto, language: string) => {
      identify(self.id, self.companyId, self.inviteCodeId, self.role, language);

      HVLocalizeStrings.setLanguage(language);
      setSelectedUserLanguage(language);

      updateSelectedUserLanguage(getKey(), { language: language });
    },
    []
  );

  const updateFullNameToServer = useCallback(
    async (fullName: string): Promise<void> => {
      await updateFullName(getKey(), fullName).then(async () => {
        await getSelfFromServer();
      });
    },
    []
  );

  const updateSelectedUserThemeToServer = useCallback(
    async (themeSelected: string) => {
      return updateSelectedUserTheme(getKey(), { theme: themeSelected }).then(
        async (response) => {
          return Promise.resolve(response);
        }
      );
    },
    []
  );

  const getRedirectUrlFromServer = useCallback(
    async (path: string, redirectDomain: string) => {
      return getRedirectUrl(getKey(), path, redirectDomain).then(
        (response: any) => {
          return response.data as string;
        }
      );
    },
    []
  );

  const saveGoalWinThemes = async (goalWinThemes: GoalWinThemeDto[]) => {
    await saveCompanyGoalWinThemes(goalWinThemes, getKey());
    await getUserCompanyFromServer();
  };

  const updateCommunicationSettingsAllowedDomainsToServer = useCallback(
    async (self: UserDto | null, isChecked: boolean) => {
      if (!self) return;
      return updateCommunicationSettingsAllowedDomains(
        getKey(),
        isChecked
      ).then(async (response) => {
        await getUserCompanyFromServer();
        return Promise.resolve(response);
      });
    },
    []
  );

  const setUserStateToServer = useCallback(
    async (userStateDto: UserStateDto) => {
      await setUserState(getKey(), userStateDto);
      await getSelfFromServer();
      return Promise.resolve();
    },
    []
  );

  const logout = () => {
    logoutFromApi().then(() => {
      flushLocalStorage();

      setSelf(null);
      window.location.replace(loginPath());
    });
  };

  useEffect(() => {
    switch (selectedUserLanguage) {
      case "fr":
        setSelectedUserLocalePrivate("fr-CA");
        break;
      case "es":
        setSelectedUserLocalePrivate("es-ES");
        break;
      default:
        setSelectedUserLocalePrivate("en-US");
        break;
    }
  }, [selectedUserLanguage]);

  return {
    languages,
    self,
    userHierarchyAssociations,
    setSelf,
    company,
    inviteCode,
    getHierarchyAssocationsFromServer,
    isHierarchyAssociationStepComplete,
    setIsHierarchyAssociationStepComplete,
    assignCertsToUser,
    getSelfFromServer,
    selectedUserLanguage,
    selectedUserLocale,
    setSelectedUserLanguage,
    getUserCompanyFromServer,
    getInviteCodeCompanyFromServer,
    updateSelectedUserLanguageToServer,
    updateFullNameToServer,
    updateSelectedUserThemeToServer,
    saveSiteLoadedEventToServer,
    setUserStateToServer,
    deviceInfo,
    updateCommunicationSettingsAllowedDomainsToServer,
    saveGoalWinThemes,
    getRedirectUrlFromServer,
    logout,
    loginWithMsTeamsTokenToServer,
  };
};
