import { QuestionLayout } from "../../Common/QuestionLayout";
import { useContext, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useBreakpointValue } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { QuestionType } from "../../../Utils/PollsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  PollQuestionDto,
  PollQuestionSessionInputDto,
} from "@headversity/contract";
import { PollSingleFreeTextInput } from "../../Shared/FreeTextInput/PollSingleFreeTextInput";
import { PollMultiFreeTextInput } from "../../Shared/FreeTextInput/PollMultiFreeTextInput";
import { ParticipantContainer } from "../../Shared/Container/ParticipantContainer";
import { PollMultiSelectInput } from "../../Shared/SelectInput/PollMultiSelectInput";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";

interface PollQuestionProps {
  pollQuestion: PollQuestionDto | undefined;
  pollInstanceId: number;
  isPreview?: boolean;
  setUserIsDonePoll?: (isDone: boolean) => void;
}

export const PollQuestion = (props: PollQuestionProps) => {
  const { pollQuestion, pollInstanceId, isPreview, setUserIsDonePoll } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const { setPollInstanceResultToServer, currentTeam } =
    useContext<IGlobalProvider>(GlobalContext);
  const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState<
    number | null
  >(null);
  const [currentTextAnswer, setCurrentTextAnswer] = useState<
    string | undefined
  >(undefined);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [textAnswersToSubmit, setTextAnswersToSubmit] = useState<string[]>([]);

  const submitPollResponse = () => {
    if (isPreview) {
      return;
    }
    track(TEAM_EVENTS.SubmitPollResponse, {
      HV_Team_Id: currentTeam?.id,
      HV_Team_Name: currentTeam?.name,
      HV_Poll_Session_Id: pollInstanceId,
      HV_Poll_Id: pollQuestion?.id,
      HV_Poll_InternalTitle: pollQuestion?.question.questionText,
    });
    setDisableSubmit(true);
    const pollSessionQuestionInputDto: PollQuestionSessionInputDto = {
      questionAlternativeIds: [],
      freeTexts: [],
      feedback: "",
      questionUserResponseIds: [],
    };
    if (pollQuestion?.question?.questionType === QuestionType.Single) {
      pollSessionQuestionInputDto.questionAlternativeIds = [
        currentSelectedAnswer as number,
      ];
      setPollInstanceResultToServer(
        pollInstanceId,
        pollSessionQuestionInputDto
      );
    } else if (pollQuestion?.question?.questionType === QuestionType.FreeText) {
      pollSessionQuestionInputDto.freeTexts = [currentTextAnswer as string];
      setLoading(true);
      setPollInstanceResultToServer(
        pollInstanceId,
        pollSessionQuestionInputDto
      ).then(() => {
        setLoading(false);
      });
      if (setUserIsDonePoll) {
        setUserIsDonePoll(true);
      }
    } else if (pollQuestion?.question?.questionType === QuestionType.Headzone) {
      /* FUTURE: re-implement headzone as generic "group nano-practice" */
    }
  };

  let content: JSX.Element[] | undefined = [<></>];
  if (
    pollQuestion?.question?.questionType === QuestionType.Single &&
    pollQuestion?.question?.questionAlternatives
  ) {
    content = [
      <ParticipantContainer key="PollMultiSelectInput" position="static" p="0">
        <PollMultiSelectInput
          questionTextAlignment="center"
          questionTextFontWeight="semibold"
          questionTextColor={MODAL_PRIMARY_TEXT_COLOR}
          questionText={pollQuestion.question.questionText as string}
          submitButtonColorSet={AnimatedButtonColorSet.Secondary}
          doneButtonMessage={HVLocalizeStrings.WE_ARE_DONE}
          alternatives={pollQuestion?.question?.questionAlternatives.map(
            (questionAlternative) => {
              return {
                id: questionAlternative.id,
                responseText: questionAlternative.alternativeText as string,
              };
            }
          )}
          currentSelectedAnswers={
            currentSelectedAnswer ? [currentSelectedAnswer] : []
          }
          submitPollResponse={submitPollResponse}
          setCurrentSelectedAnswers={(selectedAnswers) => {
            if (!isPreview) {
              const hasSelectedAnswers = selectedAnswers.length > 0;
              setCurrentSelectedAnswer(
                hasSelectedAnswers ? selectedAnswers[0] : null
              );
              setDisableSubmit(!hasSelectedAnswers);
            }
          }}
          disableIamDone={isPreview || !currentSelectedAnswer}
          disableSelection={isPreview || currentSelectedAnswer != null}
          loading={currentSelectedAnswer != null && disableSubmit}
        />
      </ParticipantContainer>,
    ];
  } else if (pollQuestion?.question?.questionType === QuestionType.FreeText) {
    content = [
      <ParticipantContainer
        key="PollSingleFreeTextInput"
        position="static"
        p="0"
      >
        <PollSingleFreeTextInput
          width={isMobile ? "300px" : "md"}
          questionTextAlignment="center"
          questionTextFontWeight="semibold"
          questionTextColor={MODAL_PRIMARY_TEXT_COLOR}
          submitButtonColorSet={AnimatedButtonColorSet.Secondary}
          disableSubmit={isPreview || disableSubmit}
          setDisableSubmit={setDisableSubmit}
          loading={loading}
          questionText={pollQuestion.question.questionText as string}
          currentTextAnswer={currentTextAnswer as any}
          setCurrentTextAnswer={setCurrentTextAnswer}
          submitPollResponse={() => {
            if (currentTextAnswer) {
              submitPollResponse();
            }
          }}
          isPreview={isPreview}
        />
      </ParticipantContainer>,
    ];
  } else if (
    pollQuestion?.question?.questionType === QuestionType.MultipleFreeText
  ) {
    content = [
      <ParticipantContainer
        key="PollMultiFreeTextInput"
        position="static"
        p="0"
      >
        <PollMultiFreeTextInput
          width={isMobile ? "300px" : "md"}
          questionTextAlignment="center"
          questionTextFontWeight="semibold"
          disableAddAnother={isPreview || disableSubmit}
          disableIamDone={isPreview || disableSubmit}
          questionText={pollQuestion.question.questionText as string}
          doneButtonMessage={HVLocalizeStrings.DONE}
          currentTextAnswer={currentTextAnswer as any}
          setCurrentTextAnswer={setCurrentTextAnswer}
          textAnswersToSubmit={textAnswersToSubmit}
          setTextAnswersToSubmit={setTextAnswersToSubmit}
          loading={disableSubmit}
          iamDoneCallBack={() => {}}
        />
      </ParticipantContainer>,
    ];
  } else if (pollQuestion?.question?.questionType === QuestionType.Headzone) {
    content = [
      <>
        {/* FUTURE: re-implement headzone as generic "group nano-practice" */}
      </>,
    ];
  }

  return (
    <>
      {pollQuestion && (
        <QuestionLayout
          title={""}
          spacing={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          submitAreaContentMt={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          answerAreaContentSpacing={
            pollQuestion?.question?.questionType === QuestionType.Headzone
              ? "0"
              : undefined
          }
          answerAreaContent={<>{content}</>}
          useHeading={
            pollQuestion?.question?.questionType === QuestionType.Headzone
          }
          useSubmitAreaContent={
            pollQuestion?.question?.questionType === QuestionType.Headzone
          }
          submitAreaContent={
            <>
              {!props.isPreview && (
                <AnimatedButton
                  disabled={disableSubmit}
                  w={150}
                  text={HVLocalizeStrings.SAVE}
                  onClick={submitPollResponse}
                  dataTestId={HVTestId.PollQuestion.submitButton}
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};
