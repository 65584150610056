import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  requestAccessToken,
  requestAccessTokenByCode,
} from "../../../Api/People/AccessTokenApi";
import { createToast } from "../../../Utils/toastUtil";
import { useToast, Spinner, Button, Icon } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { Error, MODAL_CLOSE_BUTTON } from "../../../Styles/HeadversityStyle";
import { GlobalContext } from "../../../State/GlobalContext";
import { AxiosResponse } from "axios";
import {
  setLocalStorageAuthTokens,
  processHubspotRedirect,
} from "../../../Utils/LoginUtil";
import { generateErrorFromErrorName } from "../Shared/ErrorCodes";
import { InviteCodeSelector } from "./InviteCodeSelector";
import { InviteCodeSelectionDto } from "@headversity/contract";
import { LandingContainer } from "../Shared/LandingContainer";
import { FiArrowLeftCircle } from "react-icons/fi";

export const AuthenticatedPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { selectedUserLanguage, getRedirectUrlFromServer } =
    useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const [inviteCodes, setInviteCodes] = useState<InviteCodeSelectionDto[]>([]);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [showInviteCodeSelector, setShowInviteCodeSelector] = useState(false);

  useEffect(() => {
    // the vendor_access_token gets set in index.tsx
    const vendorAccessToken = sessionStorage.getItem("vendor_access_token");
    const vendorAccessCode = searchParams.get("code");
    const language = selectedUserLanguage !== "" ? selectedUserLanguage : "en";
    const mobileLogin = sessionStorage.getItem("isMobile");

    const successCallback = (response: AxiosResponse<any>) => {
      if (response?.data?.access_token) {
        if (processHubspotRedirect(response, getRedirectUrlFromServer)) {
          return;
        }
        if (mobileLogin) {
          window.location.replace(
            `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?dt=${response?.data?.access_token}&rt=${response?.data?.refresh_token}`
          );
        } else {
          setLocalStorageAuthTokens(response.data).then(() => {
            const path = peekRedirectPath();
            navigate(path, { replace: true });
          });
        }
      } else if (response?.data?.invite_codes && response?.data?.jwt_token) {
        setInviteCodes(response.data.invite_codes);
        setJwtToken(response.data.jwt_token);
        setShowInviteCodeSelector(true);
      } else {
        if (mobileLogin) {
          window.location.replace(
            `${process.env.REACT_APP_DEEP_LINK_URL}/waiting?error=true`
          );
        } else {
          let errorMessage = HVLocalizeStrings.LOGIN_GENERIC_ERROR;
          if (response?.data?.error_message) {
            errorMessage =
              generateErrorFromErrorName(response?.data?.error_message) ??
              errorMessage;
          }
          createToast(toast, Error, "login-generic-error", errorMessage);
          navigate("/login", { replace: true });
        }
      }
    };

    const failCallback = () => {
      createToast(
        toast,
        Error,
        "login-generic-error",
        HVLocalizeStrings.LOGIN_GENERIC_ERROR
      );
      navigate("/login", { replace: true });
    };

    if (vendorAccessToken) {
      sessionStorage.removeItem("vendor_access_token");
      requestAccessToken(vendorAccessToken, language).then(
        successCallback,
        failCallback
      );
    } else if (vendorAccessCode) {
      requestAccessTokenByCode(vendorAccessCode, language).then(
        successCallback,
        failCallback
      );
    } else {
      createToast(
        toast,
        Error,
        "login-generic-error",
        HVLocalizeStrings.LOGIN_GENERIC_ERROR
      );
      navigate("/login", { replace: true });
    }
  }, []);

  const handleInviteCodeSelect = (
    selectedInviteCode: InviteCodeSelectionDto
  ) => {
    const vendorAccessToken = sessionStorage.getItem("vendor_access_token");
    const vendorAccessCode = searchParams.get("code");
    const language = selectedUserLanguage !== "" ? selectedUserLanguage : "en";
    if (vendorAccessToken) {
      requestAccessToken(
        vendorAccessToken,
        language,
        jwtToken!,
        selectedInviteCode.id
      ).then(
        (response) => {
          if (response?.data?.access_token) {
            setLocalStorageAuthTokens(response.data).then(() => {
              const path = peekRedirectPath();
              navigate(path, { replace: true });
            });
          }
        },
        () => {
          createToast(
            toast,
            Error,
            "login-generic-error",
            HVLocalizeStrings.LOGIN_GENERIC_ERROR
          );
          navigate("/login", { replace: true });
        }
      );
    } else if (vendorAccessCode) {
      requestAccessTokenByCode(
        vendorAccessCode,
        language,
        jwtToken!,
        selectedInviteCode.id
      ).then(
        (response) => {
          if (response?.data?.access_token) {
            setLocalStorageAuthTokens(response.data).then(() => {
              const path = peekRedirectPath();
              navigate(path, { replace: true });
            });
          }
        },
        () => {
          createToast(
            toast,
            Error,
            "login-generic-error",
            HVLocalizeStrings.LOGIN_GENERIC_ERROR
          );
          navigate("/login", { replace: true });
        }
      );
    }
    setShowInviteCodeSelector(false);
  };

  return (
    <>
      {showInviteCodeSelector ? (
        <InviteCodeSelector
          domains={inviteCodes}
          onSubmit={handleInviteCodeSelect}
        />
      ) : (
        <LandingContainer
          other={
            <Button
              position={"absolute"}
              left={"20px !important"}
              top={"20px !important"}
              onClick={() => navigate("/login")}
              w={"30px !important"}
              h={"30px !important"}
              className={"button-link"}
              textAlign="left"
              zIndex={2}
              _hover={{
                bgColor: "var(--chakra-colors-blackAlpha-100) !important",
              }}
            >
              <Icon
                as={FiArrowLeftCircle}
                w={"30px"}
                h={"30px"}
                color={MODAL_CLOSE_BUTTON}
              />
            </Button>
          }
        >
          <Spinner display="block" margin="auto" />
        </LandingContainer>
      )}
    </>
  );
};
