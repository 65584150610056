import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { Flex, Text } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { CharterTimer } from "../Shared/CharterTimer";
import { PresentationContent } from "../Shared/PresentationContent";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import {
  CharterActivity,
  PollQuestionDto,
  PollQuestionUserInstanceDto,
} from "@headversity/contract";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { PollResult } from "../../../Team/Polls/PollResult";

interface PresentationTeamValuesProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}

export const PresentationTeamValues = (props: PresentationTeamValuesProps) => {
  const { setActivity } = props;
  const {
    pollQuestions,
    pollInstances,
    setPollInstanceToServer,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [pollInstance, setPollInstance] = useState<
    PollQuestionUserInstanceDto | undefined
  >();
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);

  const [currentPollQuestion, setCurrentPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const { count, total, setDisableSubmit, disableSubmit } =
    usePollQuestionInformation({
      pollQuestion: currentPollQuestion,
      byGroup: false,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_1_PQ"
      );
      if (pollQuestion) {
        setCurrentPollQuestion(pollQuestion);
      }
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (
      currentPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !pollInstance &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const currentPollInstance = getLatestPollInstanceByPollQuestionId(
        currentPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setPollInstance(currentPollInstance);
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.TeamValues
        );
        return;
      }
      setPollInstanceToServer(
        currentPollQuestion.id,
        currentTeamLessonUserInstanceId
      ).then((result) => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.TeamValues
        );
        setPollInstance(result.data);
      });
    }
  }, [
    currentPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  return (
    <PresentationContainer>
      <PresentationContent
        title={HVSportLocalizeStrings.CHARTER_INDIVIDUAL_ACTIVITY}
        description={HVSportLocalizeStrings.CHARTER_TEAM_VALUES_DESCRIPTION}
        listItemsForResults={
          count === 0
            ? undefined
            : [
                {
                  icon: "https://cdn.headversity.com/app/sport/discussion.svg",
                  text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
                },
              ]
        }
        listItems={
          count > 0
            ? undefined
            : [
                {
                  icon: "https://cdn.headversity.com/app/sport/individual-devices.svg",
                  text: HVSportLocalizeStrings.CHARTER_EVERYONE_WILL_PARTICIPATE,
                },
                {
                  icon: 1,
                  text: HVSportLocalizeStrings.CHARTER_MAX_INPUTS_ONE_SENTENCE,
                },
                {
                  icon: "https://cdn.headversity.com/app/sport/discussion.svg",
                  text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
                },
              ]
        }
        hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
      >
        {currentPollQuestion && (
          <CharterTimer
            pollQuestion={currentPollQuestion}
            setDisableSubmit={(val) => {
              setDisableSubmit(false);
            }}
          />
        )}
        <Flex w={"100%"} h={"95%"} overflow={"auto"}>
          <PollResult
            pollQuestion={currentPollQuestion}
            pollInstance={pollInstance}
            showWaitingOnVotes={false}
            w={"100%"}
            gap={"0"}
            boxShadow={"none"}
            hideVote={true}
            responseListPadding={"0"}
            showWordCloud={true}
          />
        </Flex>
      </PresentationContent>

      <Flex
        flexDir={"column"}
        align={"center"}
        data-testid={HVTestId.PollResult.totalVotes}
      >
        <Text color={PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
          {HVLocalizeStrings.SPORT_RESPONSES}
        </Text>
        <Text
          color={PRIMARY_TEXT_COLOR}
          fontWeight={"semibold"}
          fontSize={"xl"}
        >
          {count} / {total}
        </Text>
      </Flex>

      <AnimatedButton
        disabled={disableSubmit || Math.floor(count) < 1 || loading}
        colorSet={AnimatedButtonColorSet.Primary}
        w={150}
        text={HVLocalizeStrings.CONTINUE}
        onClick={async () => {
          if (currentTeamLessonUserInstanceId) {
            setLoading(true);
            await setTeamLessonInProgressStepToServer(
              currentTeamLessonUserInstanceId,
              ""
            );
            setActivity(undefined);
          }
        }}
      />
    </PresentationContainer>
  );
};
