import {
  Box,
  Flex,
  FlexProps,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import {
  DateTimePicker,
  DateTimePickerChangeEvent,
  DateInputProps,
} from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { useContext, useEffect, useState, KeyboardEvent } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import "./ActivationDateTimePicker.css";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import styled from "styled-components";
import { Popup, PopupProps } from "@progress/kendo-react-popup";
import { Black, White } from "../../../../Styles/HeadversityStyle";
import dayjs from "dayjs";
import { DateTimePickerProps } from "@progress/kendo-react-dateinputs/dist/npm/datetimepicker/DateTimePicker";

export interface ActivationDateTimePickerProps {
  currentDate?: Date;
  onChange: (date: Date) => void;
  isLightTheme?: boolean;
  placeholder?: string;
  minDate?: Date;
}

interface StyledDateTimePickerProps extends DateTimePickerProps {
  customInputBackgroundColor: any;
  customInputPrimaryTextColor: any;
}

const StyledDateTimePicker = styled(DateTimePicker)<StyledDateTimePickerProps>`
  & .k-input-inner {
    background-color: ${(props) => props.customInputBackgroundColor};
    color: ${(props) => props.customInputPrimaryTextColor};
  }
`;

interface StyledCustomPopupProps extends PopupProps {
  isMobile?: boolean;
}

const StyledCustomPopup = styled(Popup)<StyledCustomPopupProps>`
  & .k-selected {
    background-color: ${(props: any) => props.buttonPrimaryColor};
    border-color: ${(props: any) => props.buttonPrimaryColor};
  }
  // hide today and now buttons
  & .k-calendar-nav.k-hstack {
    display: none;
  }
  & .k-time-now {
    display: none;
  }
  & .k-time-list .k-item:hover {
    color: ${(props: any) => props.buttonPrimaryColor};
  }
  & .k-calendar .k-calendar-view .k-today {
    color: ${(props: any) => props.buttonPrimaryColor};
  }
  & .k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
  .k-calendar .k-calendar-td.k-selected .k-link {
    background-color: ${(props: any) => props.buttonPrimaryColor};
  }
  & .k-time-accept {
    background-color: ${(props: any) => props.buttonPrimaryColor};
    border-color: ${(props: any) => props.buttonPrimaryColor};
  }
  & .k-popup {
    z-index: 9999;
    position: ${(props: any) => (props.isMobile ? "fixed" : "")};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    max-height: ${(props: any) => (props.isMobile ? "90vh" : "none")};
    width: ${(props: any) => (props.isMobile ? "90%" : "auto")};
    overflow-y: auto;
    background: white;
  }
  
  &::before {
    content: '';
    display: ${(props: any) => (props.isMobile ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    pointer-events: none;
  }
`;

const MobileAnchorElement = styled(Flex)<FlexProps>`
  .k-animation-container {
    position: static !important;
    z-index: 1 !important;
  }
`;

export const ActivationDateTimePicker = (
  props: ActivationDateTimePickerProps
) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { selectedUserLanguage, selectedUserLocale } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    if (props.currentDate) {
      setSelectedDate(props.currentDate);
    }
  }, [props.currentDate]);

  const handleChange = (event: DateTimePickerChangeEvent) => {
    if (event.value) {
      props.onChange(event.value);
      setSelectedDate(event.value);
    }
  };

  const inputBackgroundColor = useToken("colors", ["INPUT_BACKGROUND_COLOR"]);
  const inputPrimaryTextColor = useToken("colors", [
    "INPUT_PRIMARY_TEXT_COLOR",
  ]);
  const customInputPrimaryTextColor = props.isLightTheme
    ? Black
    : inputPrimaryTextColor;
  const customInputBackgroundColor = props.isLightTheme
    ? White
    : inputBackgroundColor;

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      toggleDatePicker();
    }
  };

  return (
    <Box id={"schedule-box"}>
      <LocalizationProvider language={selectedUserLocale}>
        <IntlProvider locale={selectedUserLanguage}>
          <Box onKeyDown={handleKeyDown}>
            <StyledDateTimePicker
              customInputBackgroundColor={customInputBackgroundColor}
              customInputPrimaryTextColor={customInputPrimaryTextColor}
              dateInput={
                selectedDate === null ? CustomNoDateInput : CustomDateInput
              }
              format={{
                skeleton: HVLocalizeStrings.DATE_FORMAT_KENDO_DATETIME,
              }}
              size="large"
              popup={CustomPopUp}
              value={selectedDate}
              onChange={handleChange}
              placeholder={props.placeholder}
              min={props.minDate} // Prevent past dates
              steps={{
                minute: 5
              }}
            />
          </Box>
        </IntlProvider>
      </LocalizationProvider>
      <MobileAnchorElement
        id={"anchorElement"}
        mt={"3"}
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        w={"100%"}
      ></MobileAnchorElement>
    </Box>
  );
};

const toggleDatePicker = () => {
  const element = document
    .getElementById("schedule-box")
    ?.querySelector('button[title="Toggle date-time selector"]');
  if (element) {
    (element as HTMLElement).click();
  }
};

const handleDateInputClick = () => {
  toggleDatePicker();
};

interface CustomDateInputProps extends Partial<DateInputProps> {
  isLightTheme?: boolean; // Make this optional
}

const CustomDateInput = ({
  isLightTheme,
  value,
  ...props
}: CustomDateInputProps) => {
  const inputBackgroundColor = useToken("colors", ["INPUT_BACKGROUND_COLOR"]);
  const inputPrimaryTextColor = useToken("colors", [
    "INPUT_PRIMARY_TEXT_COLOR",
  ]);

  const customInputPrimaryTextColor = isLightTheme
    ? Black
    : inputPrimaryTextColor;
  const customInputBackgroundColor = isLightTheme
    ? White
    : inputBackgroundColor;

  return (
    <Flex
      w={"100%"}
      onClick={handleDateInputClick}
      bgColor={customInputBackgroundColor}
      tabIndex={0} // Make it focusable
      alignItems={"center"}
      cursor={"pointer"}
    >
      <Text pl={2} color={customInputPrimaryTextColor}>
        {dayjs
          .utc(value)
          .local()
          .format(HVLocalizeStrings.DATE_FORMAT_WITH_TIME)}
      </Text>
    </Flex>
  );
};

const CustomNoDateInput = ({
  isLightTheme,
  ...props
}: CustomDateInputProps) => {
  const inputBackgroundColor = useToken("colors", ["INPUT_BACKGROUND_COLOR"]);
  const customInputBackgroundColor = isLightTheme
    ? White
    : inputBackgroundColor;

  return (
    <Flex
      w={"100%"}
      onClick={handleDateInputClick}
      bgColor={customInputBackgroundColor}
      tabIndex={0} // Make it focusable
      alignItems={"center"}
      cursor={"pointer"}
    ></Flex>
  );
};

const CustomPopUp = (props: any) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const buttonPrimaryColor = useToken("colors", [
    "BUTTON_PRIMARY_BACKGROUND_COLOR",
  ]);

  return (
    <StyledCustomPopup
      buttonPrimaryColor={buttonPrimaryColor}
      isMobile={isMobile}
      appendTo={isMobile ? document.body : undefined}
      {...props}
    />
  );
};
