import {
  Flex,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { GoDotFill } from "react-icons/go";
import { Black, Border_Radius } from "../../../Styles/HeadversityStyle";

interface PollResponseListProps {
  children: ReactNode[];
  w?: string;
  p?: string;
  maxHeight?: string;
  boxShadow?: string;
  noBorder?: boolean;
  noDefaultHeight?: boolean;
  mb?: string;
}

interface PollResponseListItemProps {
  children: any;
}

export const PollResponseList = (props: PollResponseListProps) => {
  const { children, w, maxHeight, boxShadow, p, noBorder, noDefaultHeight, mb } =
    props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  return (
    <List
      bg={"white"}
      w={w}
      maxH={maxHeight ?? noDefaultHeight ? undefined : "300px"}
      overflowY={"auto"}
      borderRadius={noBorder ? undefined : Border_Radius}
      boxShadow={noBorder ? undefined : boxShadow ?? "lg"}
      p={p ?? (isMobile ? "2" : "10")}
      spacing={"2"}
      mb={mb}
    >
      {children}
    </List>
  );
};

export const PollResponseListItem = (
  props: PollResponseListItemProps,
  ...rest: any
) => {
  const { children } = props;

  return (
    <ListItem>
      <Flex align={"center"} gap={"2"} {...rest}>
        <Icon as={GoDotFill} fontSize={"13px"} color={Black} />
        {children}
      </Flex>
    </ListItem>
  );
};
