import { Box, Center, Fade, Flex, Text } from "@chakra-ui/react";
import { ScaleQuestion } from "./ScaleQuestion";
import { SliderQuestion } from "./SliderQuestion";
import { AlternativeButton } from "../../Common/AlternativeButton";
import { QuestionDto, QuestionType } from "@headversity/contract";
import { useEffect, useState } from "react";
import { QuestionLayout } from "../../Common/QuestionLayout";
import { HvTextarea } from "../../Common/HvTextarea";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import { Respect_Maroon } from "../../../Styles/HeadversityStyle";
import { HapticsType, performHapticFeedback } from "../../../Api/Utils";

interface QuestionProps {
  question: QuestionDto;
  onSelectedResponseChanged?: (val: string | number, index: number) => void;
  textColor: string;
  isDisabled: boolean;
  onEnterKeyPress?: () => void;
}

export const Question = ({
  question,
  onSelectedResponseChanged,
  textColor,
  isDisabled,
  onEnterKeyPress,
}: QuestionProps) => {
  const [resetGUI, setResetGUI] = useState(false);
  const [showGUI, setShowGUI] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedResponseText, setSelectedResponseText] = useState("");
  const [selectedAlternativeId, setSelectedAlternativeId] = useState<
    number | undefined
  >();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // handle enter key press
      if (onEnterKeyPress && event.key === "Enter") {
        onEnterKeyPress();
      }

      // ignore non-single letter keys like "Enter"
      if (event.key.length !== 1) return;

      // get the ASCII value of the letter and convert to an index starting from 0
      const asciiValue = event.key.toLowerCase().charCodeAt(0);
      const index = asciiValue - "a".charCodeAt(0);
      if (index >= 0 && index < question.questionAlternatives.length) {
        onSelectedIndexChanged(
          index,
          index,
          question.questionAlternatives[index]?.id
        );
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [question, selectedIndex]); // question and selectedIndex here so we have up-to-date state

  const onSelectedIndexChanged = (
    val: number,
    index: number,
    alternativeId?: number
  ) => {
    setSelectedIndex(index);
    setSelectedAlternativeId(alternativeId);

    if (question) {
      setSelectedResponseText(
        question.questionAlternatives[index].alternativeText.toString()
      );
    }

    if (onSelectedResponseChanged) {
      performHapticFeedback(HapticsType.LightImpact);

      onSelectedResponseChanged(val, index);
    }
  };

  const getScaleMax = (question: QuestionDto): number => {
    if (!question) return 0;

    return [QuestionType.Scale5, QuestionType.Slider5].includes(
      question.questionType
    )
      ? 5
      : [QuestionType.Scale7, QuestionType.Slider7].includes(
          question.questionType
        )
      ? 7
      : [QuestionType.Scale11, QuestionType.Slider11].includes(
          question.questionType
        )
      ? 11
      : 0;
  };

  useEffect(() => {
    setSelectedAlternativeId(undefined);

    // for slider and scale questions, this reset technique is a hack to get the chakra component value to reset
    // we'll fade first, then do the reset to get a smoother transition
    if (isSliderQuestion || isScaleQuestion) {
      setShowGUI(false);

      setTimeout(() => {
        setSelectedIndex(null);
        setSelectedResponseText(HVLocalizeStrings.SELECT_A_VALUE);
        setQuestionText(question.questionText.toString());

        // reset component
        setResetGUI(true);

        setTimeout(() => {
          setResetGUI(false);

          // now that the component is reset, fade back in
          setTimeout(() => {
            setShowGUI(true);
          }, 1);
        }, 1);
      }, 100);
    } else {
      setQuestionText(question.questionText.toString());
    }
  }, [question]);

  const isScaleQuestion =
    question &&
    [QuestionType.Scale5, QuestionType.Scale7, QuestionType.Scale11].includes(
      question.questionType
    );

  const isSliderQuestion =
    question &&
    [
      QuestionType.Slider5,
      QuestionType.Slider7,
      QuestionType.Slider11,
    ].includes(question.questionType);

  const isSingleQuestion =
    question && question.questionType === QuestionType.Single;

  const isTextQuestion =
    question && question.questionType === QuestionType.FreeText;

  const scaleMax = question ? getScaleMax(question) : 0;

  // only show for slider question when scale is beyond 0 - 10
  const showAlternativeLabel =
    isScaleQuestion || question.questionAlternatives.length > 11;

  return (
    <QuestionLayout
      spacing="10px"
      title={questionText}
      textColor={textColor}
      answerAreaContent={
        <Box width="100%" mt={"25px"}>
          <>
            {(isScaleQuestion || isSliderQuestion) && (
              <Fade in={showGUI}>
                {!resetGUI && (
                  <Box
                    mt={{ base: "0px", md: "10px" }}
                    mb={"40px"}
                    width="100%"
                  >
                    {showAlternativeLabel && (
                      <Center h="25px" mb={{ base: "25px", md: "35px" }}>
                        <Text
                          fontSize={selectedIndex === null ? "md" : "lg"}
                          fontStyle={
                            selectedIndex === null ? "italic" : undefined
                          }
                          color={textColor}
                          dangerouslySetInnerHTML={{
                            __html: selectedResponseText,
                          }}
                        />
                      </Center>
                    )}
                    <Box h="55px">
                      <>
                        {isScaleQuestion ? (
                          <Box mb={"2px"}>
                            <ScaleQuestion
                              scaleMax={scaleMax}
                              onSelectedIndexChanged={onSelectedIndexChanged}
                              isDisabled={isDisabled || !showGUI}
                            />
                          </Box>
                        ) : (
                          <Box mb={"30px"}>
                            <SliderQuestion
                              alternativeCount={
                                question.questionAlternatives.length
                              }
                              scaleMax={scaleMax - 1}
                              onSelectedIndexChanged={onSelectedIndexChanged}
                              isDisabled={isDisabled || !showGUI}
                              textColor={textColor}
                            />
                          </Box>
                        )}

                        <FlavorText
                          options={scaleMax}
                          isScaleQuestion={isScaleQuestion}
                          leftText={question.questionAlternatives[0].alternativeText.toString()}
                          rightText={question.questionAlternatives[
                            question.questionAlternatives.length - 1
                          ].alternativeText.toString()}
                          color={textColor}
                        />
                      </>
                    </Box>
                  </Box>
                )}
              </Fade>
            )}
            {isSingleQuestion && (
              <Flex flexDir={"column"} gap={"20px"} width="100%">
                {question.questionAlternatives.map((alt, idx) => (
                  <Center key={alt.id}>
                    <AlternativeButton
                      minH="65px"
                      disabled={isDisabled}
                      setSelectedResponse={() => {
                        onSelectedIndexChanged(idx, idx, alt.id);
                      }}
                      questionId={question?.id}
                      alternativeId={alt.id}
                      alternativeName={alt.alternativeText}
                      selected={selectedAlternativeId === alt.id}
                      dataTestId={HVTestId.Question.alternative}
                      index={idx}
                      defaultBorderColor={"transparent"}
                      selectedColor={Respect_Maroon}
                    />
                  </Center>
                ))}
              </Flex>
            )}
            {isTextQuestion && (
              <Box w="100%" px={{ base: "0px", md: "0px" }}>
                <HvTextarea
                  minH={"100px"}
                  placeholder={
                    question.optional
                      ? HVLocalizeStrings.ENTER_YOUR_RESPONSE_OPTIONAL
                      : HVLocalizeStrings.ENTER_YOUR_RESPONSE
                  }
                  onChange={(e) => {
                    if (onSelectedResponseChanged) {
                      onSelectedResponseChanged(e.target.value, 0);
                    }
                  }}
                />
              </Box>
            )}
          </>
        </Box>
      }
      submitAreaContent={<Box h="20px" />}
    />
  );
};

interface FlavorTextProps {
  options: number;
  isScaleQuestion: boolean | undefined;
  leftText: string;
  rightText: string;
  color: string;
}

const FlavorText = ({
  options,
  isScaleQuestion,
  leftText,
  rightText,
  color,
}: FlavorTextProps) => {
  return (
    <Box>
      <Flex
        mx={{
          base: 0,
          md:
            options === 11 && !isScaleQuestion
              ? "40px"
              : options === 5 && isScaleQuestion
              ? "100px"
              : 4,
        }}
        flexDir={"row"}
        justifyContent="space-between"
      >
        {Array.from(Array(options).keys()).map((x) => {
          return (
            <Box
              key={x}
              textAlign={"center"}
              fontSize={{ base: "13px", md: "14px" }}
              lineHeight="1rem"
              w="100%"
            >
              {x === 0 && (
                <Text
                  color={color}
                  dangerouslySetInnerHTML={{
                    __html: leftText.replace(" ", "<br />"),
                  }}
                />
              )}

              {x === options - 1 && (
                <Text
                  color={color}
                  dangerouslySetInnerHTML={{
                    __html: rightText.replace(" ", "<br />"),
                  }}
                />
              )}
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
