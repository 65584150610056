import { Box, Link, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  GlobalContext,
  IGlobalProvider,
  TrainingType,
} from "../../../State/GlobalContext";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
} from "../../../State/ShellContext";
import { MODAL_BACKGROUND_COLOR } from "../../../Styles/HeadversityStyle";
import { LargeModal } from "../../Common/LargeModal";
import { StepPanel } from "../../Common/StepPanel";
import { HeadScanQuestionModal } from "../../Solo/HeadScan/HeadScanQuestionModal";
import { StepConfirmSkills } from "./StepConfirmSkills";
import { StepToBegin } from "./StepToBegin";
import { StepVideo } from "./StepVideo";
import { FeatureFlags } from "../../../Utils/FeatureFlags";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HVTestId } from "../../../Testing/dataTestIds";
import { BottomActionBar } from "../../Common/BottomActionBar";
import {
  GoalDto,
  MicroLessonDto,
  NanoPracticeDto,
} from "@headversity/contract";
import { getIntroVideoUrl } from "../../../Utils/Helpers";
import { StepSelectGoal } from "./StepSelectGoal";
import {
  getBgImageSource,
  getGoalMinSkillLevel,
  getSuggestedGoalFromHeadscanResponses,
  saveSelectedGoal,
} from "../../../Utils/SkillsUtil";
import { SOLO_PRODUCT_ANNOUNCEMENT } from "../../../App";
import { StepStartTraining } from "./StepStartTraining";
import { HeadZoneStateRanges } from "../../../Utils/HeadzoneUtil";
import { ImmediateAssistance } from "./ImmediateAssistance";
import { WelcomeModalSubheader } from "./WelcomeModalSubheader";

enum Step {
  ToBegin = 0,
  Video = 1,
  ConfirmSkills = 2,
  StartTraining = 3,
}

const STEPS = [Step.Video, Step.ConfirmSkills];

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  goalsOnly?: boolean;
  showReturningGoalMessage?: boolean;
  preventClose?: boolean;
}

export default function WelcomeModal({
  open,
  setOpen,
  goalsOnly,
  showReturningGoalMessage,
  preventClose,
}: Props) {
  const {
    theme,
    doOSThemeCheck,
    setIsGoalCheckInModalOpen,
    isGoalOverviewSeeAllGoals,
    setIsGoalOverviewSeeAllGoals,
  } = useContext<IShellProvider>(ShellContext);
  const {
    company,
    selectedUserLanguage,
    getFeatureVersion,
    userSkillStats,
    selectedGoal,
    selectedGoalBgImage,
    goals,
    setGoalToServer,
    openNextActivity,
  } = useContext<IGlobalProvider>(GlobalContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [step, setStep] = useState(goalsOnly ? Step.ConfirmSkills : 0);

  const [headScanModelOpen, setHeadScanQuestionModalOpen] = useState(false);
  const [headScanQuestionAnswered, setHeadScanQuestionAnswered] =
    useState(goalsOnly);
  const [showImmediateHelp, setShowImmediateHelp] = useState(false);

  const [customLogoUrl, setCustomLogoUrl] = useState("");
  const [howWorksHeadline, setHowWorksHeadline] = useState("");
  const [showVideo, setShowVideo] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");

  const [themeReady, setThemeReady] = useState(false);

  const [bgImage, setBgImage] = useState<string | undefined>(undefined);
  const [lastSelectedGoal, setLastSelectedGoal] = useState<GoalDto>();
  const [suggestedGoal, setSuggestedGoal] = useState<GoalDto>();

  const [selectedTrainingType, setSelectedTrainingType] =
    useState<TrainingType>(TrainingType.Practice);
  const [selectedPractice, setSelectedPractice] = useState<NanoPracticeDto>();
  const [selectedLesson, setSelectedLesson] = useState<MicroLessonDto>();

  useEffect(() => {
    const featureDetails = getFeatureVersion(FeatureFlags.WELCOME_2023) as any;

    // get video url
    const introVideoUrl = getIntroVideoUrl(
      featureDetails,
      selectedUserLanguage,
      "https://player.vimeo.com/video/817056371?h=94de250fff&autoplay=1"
    );

    if (introVideoUrl === null) {
      setShowVideo(false);
    } else {
      setVideoUrl(introVideoUrl);
    }

    if (company?.logo) {
      setCustomLogoUrl(company?.logo);
    }

    if (!featureDetails) return;

    // check if we have a feature override
    if (featureDetails.customLogoUrl !== undefined && !company?.logo) {
      setCustomLogoUrl(featureDetails.customLogoUrl);
    }

    if (featureDetails.howWorksHeadline !== undefined) {
      setHowWorksHeadline(
        featureDetails.howWorksHeadline[selectedUserLanguage]
      );
    }
  }, [selectedUserLanguage]);

  useEffect(() => {
    if (!open) return;

    doOSThemeCheck(() => {
      setThemeReady(true);
    });
  }, [open]);

  const handleNextButtonClick = () => {
    switch (step) {
      case Step.ToBegin:
        if (!showVideo) {
          track(EVENTS.WelcomeHeadzoneShown);
          setStep(step + 2);
          return;
        }

        track(EVENTS.WelcomeVideoShown);
        break;

      case Step.Video:
        track(EVENTS.WelcomeHeadzoneShown);
        break;

      case Step.ConfirmSkills:
        if (!headScanQuestionAnswered) {
          track(EVENTS.WelcomeHeadScanShown);
          setHeadScanQuestionModalOpen(true);

          return;
        } else if (showImmediateHelp) {
          setShowImmediateHelp(false);

          // track this here since it was delayed due to immediate help
          track(EVENTS.WelcomeConfirmSkillsShown, {
            HV_Goal: suggestedGoal?.name,
            HV_GoalId: suggestedGoal?.id,
          });

          return;
        } else {
          if (lastSelectedGoal) {
            saveSelectedGoal(
              setGoalToServer,
              userSkillStats,
              lastSelectedGoal,
              selectedGoal
            );
          }

          if (!goalsOnly) {
            track(EVENTS.WelcomeFlowCompleted);
          }

          track(EVENTS.StartTrainingShown, {
            HV_From: "Welcome",
          });
        }

        break;

      case Step.StartTraining:
        openNextActivity(
          selectedTrainingType,
          selectedPractice,
          selectedLesson
        );

        setOpen(false);
        setStep(0);
        setHeadScanQuestionAnswered(false);

        return;
    }

    setStep(step + 1);
  };

  const handleBackClick = () => {
    const prevStep = step - 1;

    track(EVENTS.WelcomeModalBackClicked, { HV_Step: step });

    setHeadScanQuestionAnswered(false);

    if (prevStep === Step.Video && !showVideo) {
      setStep(prevStep - 1);
      return;
    }

    setStep(prevStep);
  };

  const handleGoalChanged = (goal: GoalDto) => {
    setLastSelectedGoal(goal);
    setBgImage(getBgImageSource(goal.imageUrl.toString(), isDesktop));
  };

  const handleReviewProgress = () => {
    setOpen(false);

    setIsGoalCheckInModalOpen(true);
  };

  const isViewingAllGoals =
    isGoalOverviewSeeAllGoals || (!selectedGoal && !suggestedGoal);

  const minLevel = getGoalMinSkillLevel(userSkillStats, selectedGoal);
  const showReviewProgress = goalsOnly && !isViewingAllGoals && minLevel > 0;

  const isAllZero =
    userSkillStats.length === 0 || userSkillStats.every((x) => x.points === 0);

  return !themeReady ? (
    <></>
  ) : (
    <LargeModal
      open={open}
      setOpen={setOpen as any}
      bgColor={MODAL_BACKGROUND_COLOR}
      preventClose={
        userSkillStats.length === 0 ||
        (preventClose && step !== Step.StartTraining)
      }
      bgTransitionSeconds={0.25}
      bgImage={
        bgImage || goalsOnly
          ? bgImage
          : step === Step.ToBegin
          ? getBgImageSource(
              "https://cdn.headversity.com/app/bg-goals/{0}/improved-collaboration.jpg",
              isDesktop
            )
          : step === Step.Video
          ? getBgImageSource(
              "https://cdn.headversity.com/app/bg-goals/{0}/enhanced-self-awareness.jpg",
              isDesktop
            )
          : step === Step.StartTraining && selectedGoal
          ? selectedGoalBgImage
          : getBgImageSource(
              "https://cdn.headversity.com/app/bg-goals/{0}/sustained-energy.jpg",
              isDesktop
            )
      }
      dataTestId={HVTestId.WelcomeModal.modalContent}
      forceDarkMode={true}
    >
      {theme.name !== NOSTALGIC_THEME_NAME && (
        <Box
          pos="absolute"
          width="100%"
          height="100%"
          bgColor={"#ffffff22"}
          left="0"
          top="0"
        />
      )}
      <Box>
        <StepPanel
          step={0}
          totalStep={STEPS.length}
          value={0}
          showProgressBar={false}
          showBackArrow={
            step > 0 &&
            !(step === Step.ConfirmSkills && headScanQuestionAnswered) &&
            step !== Step.StartTraining
          }
          onBackClick={() => handleBackClick()}
          forceDarkMode={true}
        >
          <Box
            minH={
              step === Step.ConfirmSkills && headScanQuestionAnswered
                ? "480px"
                : step === Step.StartTraining
                ? "520px"
                : "500px"
            }
            m={isDesktop ? 2 : 0}
            mt={
              step === Step.ToBegin
                ? isDesktop
                  ? "0px"
                  : "-90px"
                : step === Step.StartTraining
                ? isDesktop
                  ? "-20px"
                  : "-30px"
                : 0
            }
          >
            {step === Step.ToBegin && (
              <StepToBegin customLogoUrl={customLogoUrl} />
            )}

            {step === Step.Video && (
              <StepVideo
                videoUrl={videoUrl}
                howWorksHeadline={howWorksHeadline}
              />
            )}

            {step === Step.ConfirmSkills && !headScanQuestionAnswered && (
              <StepConfirmSkills selectedSkillIds={[]} />
            )}

            {step === Step.ConfirmSkills && headScanModelOpen && (
              <HeadScanQuestionModal
                open={headScanModelOpen}
                onSaveComplete={(responses) => {
                  if (responses.length === 0) return;

                  const goal = getSuggestedGoalFromHeadscanResponses(
                    responses,
                    goals,
                    company
                  );

                  setSuggestedGoal(goal);
                  handleGoalChanged(goal);

                  setHeadScanQuestionAnswered(true);

                  // new user shouldn't ever see the "product announcement" interstitial
                  localStorage.setItem(SOLO_PRODUCT_ANNOUNCEMENT, "true");

                  // if the headscan score was too low, show a message
                  // get avg value from headscan responses
                  const avg =
                    responses.reduce((acc, curr) => acc + curr.scale, 0) /
                    responses.length;

                  if (avg * 10 < HeadZoneStateRanges.burnOut) {
                    track(EVENTS.ImmediateHelpShown);

                    setShowImmediateHelp(true);
                  } else {
                    track(EVENTS.WelcomeConfirmSkillsShown, {
                      HV_Goal: goal.name,
                      HV_GoalId: goal.id,
                    });
                  }
                }}
                setOpen={(val: any) => {
                  setHeadScanQuestionModalOpen(val);
                }}
                skillIds={[]}
                isWelcomeModal={true}
              />
            )}

            {step === Step.ConfirmSkills && showImmediateHelp && (
              <WelcomeModalSubheader mt={0}>
                {HVLocalizeStrings.WELCOME_HEADSCAN_LOW}
                <ImmediateAssistance from="Welcome" />
              </WelcomeModalSubheader>
            )}

            {step === Step.ConfirmSkills &&
              !showImmediateHelp &&
              !headScanModelOpen &&
              headScanQuestionAnswered && (
                <StepSelectGoal
                  isCheckIn={goalsOnly && selectedGoal !== undefined}
                  suggestedGoal={suggestedGoal}
                  onGoalChanged={handleGoalChanged}
                  seeAllGoals={isViewingAllGoals}
                />
              )}

            {step === Step.StartTraining && (
              <StepStartTraining
                selectedTrainingType={selectedTrainingType}
                setSelectedTrainingType={setSelectedTrainingType}
                selectedPractice={selectedPractice}
                setSelectedPractice={setSelectedPractice}
                selectedLesson={selectedLesson}
                setSelectedLesson={setSelectedLesson}
                isPostWelcome={!isAllZero}
              />
            )}
          </Box>
        </StepPanel>
        <BottomActionBar
          showOptions={step === 0}
          nextButtonWidth={showReviewProgress ? "175" : undefined}
          nextButtonText={
            showReviewProgress
              ? HVLocalizeStrings.REVIEW_PROGRESS
              : showReturningGoalMessage || step === Step.StartTraining
              ? HVLocalizeStrings.START_TRAINING
              : HVLocalizeStrings.NEXT
          }
          onNextButtonClick={
            showReviewProgress ? handleReviewProgress : handleNextButtonClick
          }
          actionAreaExtra={
            step === Step.ConfirmSkills &&
            !showImmediateHelp &&
            headScanQuestionAnswered &&
            !isViewingAllGoals ? (
              <Link
                textDecoration={"underline"}
                fontSize="14px"
                onClick={(e) => {
                  track(EVENTS.GoalChooseDifferent, { HV_From: "Welcome" });

                  setIsGoalOverviewSeeAllGoals(true);
                }}
              >
                {selectedGoal
                  ? HVLocalizeStrings.PURSUIT_DIFFERENT
                  : HVLocalizeStrings.PURSUIT_OWN}
              </Link>
            ) : undefined
          }
          dataTestId={HVTestId.WelcomeModal.button}
        />
      </Box>
    </LargeModal>
  );
}
