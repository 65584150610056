import {
  Box,
  Center,
  Flex,
  Link,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { AppType, GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import {
  BG_CERT_BASE,
  BG_REACH_BASE,
  BG_TEAM_BASE,
  BLOCK_SECTION_SECONDARY_TEXT_COLOR,
  Error_Red,
  HEADER_ACHIEVEMENT_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../Testing/dataTestIds";
import { BlockSection } from "../../Common/BlockSection";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import StatCardPanel from "../../Common/Cards/statsCard/StatCardPanel";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { LanguageModal } from "../../Shared/Language/LanguageModal";
import ThemeSelectModal from "../../Shared/themeSelectModal/ThemeSelectModal";
import WelcomeModal from "../../Shared/Welcome/WelcomeModal";
import { CustomFieldModal } from "../CustomFields/CustomFieldModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import {
  ConfirmationButtons,
  ConfirmationDialog,
} from "../../Common/ConfirmationDialog";
import { deleteSelf, getFamilyInviteCode } from "../../../Api/People/UserApi";
import { getKey } from "../../../Utils/Helpers";
import { EVENTS, track } from "../../../Utils/Analytics";
import {
  IOptimismToolProvider,
  OptimismToolContext,
} from "../../../State/Solo/OptimismToolContext";
import {
  FocusToolContext,
  IFocusToolProvider,
} from "../../../State/Solo/FocusToolContext";
import { getMissingDayChallenge } from "../Tools/Optimism/Utils/OptimismUtils";
import { getUpComingFocusPlans } from "../Tools/Focus/Utils/FocusUtils";
import { FOCUS_BEFORE_AFTER_MINUTE_TRIGGER } from "../../../Hooks/Solo/useNotification";
import dayjs from "dayjs";
import { TermsOfUse } from "../../People/Login/TermsOfUse";
import { PrivacyPolicy } from "../../People/Login/PrivacyPolicy";
import { requestPasswordResetCodeForLoggedUser } from "../../../Api/People/PasswordApi";
import {
  InviteCodeForReachDto,
  RegistrationLoginStates,
  UserRole,
} from "@headversity/contract";
import { isMobile } from "react-device-detect";
import { HvSelect } from "../../Common/HvSelect";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import PasswordInput from "../../People/Shared/PasswordInput";
import { ActivityHistoryModal } from "../../Shared/ActivitiyHistory/ActivityHistoryModal";
import { enableEmailSubscriptions } from "../../../Api/Solo/ProfileApi";
import { PracticeReminderModal } from "../../Shared/PracticeReminder/PracticeReminderModal";
import { LargeModal } from "../../Common/LargeModal";
import { FamilyInviteCode } from "../../Common/GetHelpModal";
import { Language } from "../../../Utils/HelpResourcesUtils";
import {
  OnboardingStep,
  SportOnboarding,
} from "../../Sport/Onboarding/SportOnboarding";
import HierarchyModal from "../../Shared/Hierarchy/HierarchyModal";
import { CertFirstTimeInterstitialModal } from "../../Cert/CertFirstTimeInterstitialModal";
import { AboutTeamModal } from "../../Team/AboutTeam/AboutTeamModal";
import { UpdateNameModal } from "../../Shared/ChangeName/UpdateNameModal";
import { FeatureFlags } from "../../../Utils/FeatureFlags";
import {
  HapticsType,
  HAPTICS_DISABLED_KEY,
  performHapticFeedback,
} from "../../../Api/Utils";
import { Haptics } from "@capacitor/haptics";

export const ProfilePage = () => {
  const { isMainContainerXL } = useContext(ShellContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const {
    getPageTitle,
    getNotificationCountForTools,
    customFields,
    promptForPushPermission,
    setIsTourOpen,
    pushDisabled,
    currentApp,
    self,
    updateApp,
    logout,
    selectedGoal,
    inviteCode,
    userHierarchyAssociations: userAssociations,
    getHierarchyAssocationsFromServer,
    featureImplementations,
    hasFeature,
  } = useContext(GlobalContext);

  const { currentPlannedItem } =
    useContext<IOptimismToolProvider>(OptimismToolContext);

  const { focusPlanItems } = useContext<IFocusToolProvider>(FocusToolContext);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [activityHistoryModalOpen, setActivityHistoryModalOpen] =
    useState(false);
  const [termsOfUseModalOpen, setTermsOfUseModalOpen] = useState(false);
  const [inviteFamilyMembersModalOpen, setInviteFamilyMembersModalOpen] =
    useState(false);
  const [privatePolicyModalOpen, setPrivatePolicyModalOpen] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [isTeamWelcomeOpen, setIsTeamWelcomeOpen] = useState(false);
  const [isCertWelcomeOpen, setIsCertWelcomeOpen] = useState(false);
  const [isSportWelcomeOpen, setIsSportWelcomeOpen] = useState(false);

  const [customFieldsModalOpen, setCustomFieldsModalOpen] = useState(false);
  const [hierarchyModalOpen, setHierarchyModalOpen] = useState(false);
  const [updateNameModalOpen, setUpdateNameModalOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [emailSubscriptionEnabled, setEmailSubscriptionEnabled] =
    useState<boolean>(false);
  const [sharedFamilyInviteCode, setSharedFamilyInviteCode] =
    useState<InviteCodeForReachDto>();

  const [
    isProfilePracticeReminderModalOpen,
    setIsProfilePracticeReminderModalOpen,
  ] = useState(false);
  const [isUpdateNameEnabled, setIsUpdateNameEnabled] = useState(false);

  const [hapticsDisabled, setHapticsDisabled] = useState(
    localStorage && !!localStorage.getItem(HAPTICS_DISABLED_KEY)
  );

  const { confirmationDialogRef } = useContext(ShellContext);
  const mobileEnvs = process.env.REACT_APP_MOBILE_ENVS;

  const [searchParams] = useSearchParams();

  const openDeleteAccountDialogFinal = () => {
    confirmationDialogRef.current.confirmation(
      HVLocalizeStrings.DELETE_ACCOUNT_TITLE_2,
      HVLocalizeStrings.DELETE_ACCOUNT_CONFIRM_MESSAGE,
      ConfirmationButtons.JSX,
      null,
      [<DeleteAccountConfirmation logout={logout} />],
      null,
      null,
      null,
      true,
      true
    );
  };

  const onEnableEmailNotifications = () => {
    enableEmailSubscriptions(getKey()).then((response) => {
      setEmailSubscriptionEnabled(response.data.success);
    });
  };

  const openDeleteAccountDialogStart = () => {
    confirmationDialogRef.current.confirmation(
      HVLocalizeStrings.DELETE_ACCOUNT_TITLE_1,
      HVLocalizeStrings.DELETE_ACCOUNT_MESSAGE.replace(
        "{0}",
        self ? self?.email : ""
      ),
      ConfirmationButtons.YesNo,
      () => {
        openDeleteAccountDialogFinal();
      },
      null,
      null,
      false,
      null,
      true,
      false
    );
  };

  const onChangePasswordClick = () => {
    confirmationDialogRef.current.confirmation(
      HVLocalizeStrings.PROFILE_CHANGE_PASSWORD,
      HVLocalizeStrings.PROFILE_CHANGE_PASSWORD_EMAIL,
      ConfirmationButtons.Customs,
      (button: string) => {
        if (button === HVLocalizeStrings.PROFILE_SEND_EMAIL) {
          const isMobileDevice =
            sessionStorage.getItem("isMobile") === "true" || isMobile;
          requestPasswordResetCodeForLoggedUser(getKey(), isMobileDevice);
          track(EVENTS.ChangePasswordEmailRequested);
        }
      },
      [HVLocalizeStrings.PROFILE_SEND_EMAIL, HVLocalizeStrings.CANCEL]
    );
  };

  const navigate = useNavigate();

  /*
   * This is called to sync up the data for the notofications
   * when the user navigates to the profile page
   * Reference ticket HV-3916
   */
  useEffect(() => {
    setNotificationCount(
      getNotificationCountForTools(currentPlannedItem, focusPlanItems)
    );
  }, [currentPlannedItem, focusPlanItems]);

  useEffect(() => {
    if (self) {
      setEmailSubscriptionEnabled(!self.unsubscribeEmails);
    }
    getHierarchyAssocationsFromServer();
  }, []);

  useEffect(() => {
    if (inviteCode) {
      if (inviteCode.isFamily) {
        return;
      }
      getFamilyInviteCode(getKey()).then((response) => {
        if (response.data) {
          setSharedFamilyInviteCode(response.data);
        }
      });
    }
  }, [inviteCode]);

  useEffect(() => {
    // check if we need to open a modal based on search params
    if (searchParams.has("challenge")) {
      track(EVENTS.ManageChallenge, {
        HV_From: "ProfilePage",
      });

      setIsProfilePracticeReminderModalOpen(true);
    } else {
      setIsProfilePracticeReminderModalOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (featureImplementations && featureImplementations.length > 0) {
      setIsUpdateNameEnabled(
        hasFeature(FeatureFlags.UPDATE_NAME, featureImplementations)
      );
    }
  }, [featureImplementations]);

  const contactUsJsx = (
    <ListItem mb={2}>
      <Link
        textDecoration={"underline"}
        href="#"
        onClick={(e) => {
          window.open("mailto:help@headversity.com");
          e.preventDefault();
        }}
        data-testid={HVTestId.ProfilePage.contactUsLink}
      >
        {HVLocalizeStrings.SOLO_MENU_CONTACT}
      </Link>
    </ListItem>
  );

  const isOnSolo = currentApp === AppType.SOLO;

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(`Profile - ${HVLocalizeStrings.HEADVERSITY}`)}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.SOLO_PROFILE_TITLE}
        description={
          currentApp === AppType.REACH
            ? HVLocalizeStrings.REACH_PROFILE_DESCRIPTION
            : HVLocalizeStrings.SOLO_PROFILE_DESCRIPTION
        }
        backgroundImage={HEADER_ACHIEVEMENT_BACKGROUND}
        backgroundImageBase={
          currentApp === AppType.TEAM
            ? BG_TEAM_BASE
            : currentApp === AppType.REACH
            ? BG_REACH_BASE
            : currentApp === AppType.CERT
            ? BG_CERT_BASE
            : undefined
        }
      />
      <FadeAfterDelay>
        <Box
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          mx={"auto"}
          color={BLOCK_SECTION_SECONDARY_TEXT_COLOR}
        >
          {notificationCount > 0 && (
            <BlockSection
              columns={0}
              fadeIn={true}
              title={
                <BlockSectionTitle
                  title={HVLocalizeStrings.SOLO_MENU_NOTIFICATION}
                />
              }
            >
              {currentPlannedItem &&
                getMissingDayChallenge(currentPlannedItem).length > 0 && (
                  <Text mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={() => {
                        navigate("/tools/optimism");
                      }}
                    >
                      {HVLocalizeStrings.SOLO_MENU_NOTIFICATION_OPTIMISM_SINGLE}
                    </Link>
                  </Text>
                )}

              {focusPlanItems &&
                getUpComingFocusPlans(
                  focusPlanItems,
                  FOCUS_BEFORE_AFTER_MINUTE_TRIGGER
                ).length > 0 && (
                  <Text mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={() => {
                        localStorage.setItem(
                          "focus_notification",
                          dayjs().unix().toString()
                        );
                        setNotificationCount(
                          getNotificationCountForTools(
                            currentPlannedItem,
                            focusPlanItems
                          )
                        );
                        navigate("/tools/focus");
                      }}
                    >
                      {HVLocalizeStrings.SOLO_MENU_NOTIFICATION_FOCUS_SINGLE}
                    </Link>
                  </Text>
                )}
            </BlockSection>
          )}

          {isOnSolo && (
            <Box mb="30px">
              <StatCardPanel />
            </Box>
          )}

          <BlockSection
            columns={0}
            fadeIn={true}
            title={
              <BlockSectionTitle
                title={HVLocalizeStrings.SOLO_SETTINGS_TITLE}
              />
            }
          >
            <SimpleGrid
              columns={currentApp === AppType.SPORT ? 1 : 2}
              maxW={isDesktop ? "850px" : undefined}
              data-testid={HVTestId.ProfilePage.settingsContainer}
              mb="20px"
              lineHeight="1.4"
            >
              <UnorderedList mr={2}>
                <ListItem mb={2}>
                  <Link
                    textDecoration={"underline"}
                    href="#"
                    onClick={(e) => {
                      setLanguageModalOpen(true);
                      e.preventDefault();
                    }}
                    data-testid={HVTestId.ProfilePage.languageLink}
                  >
                    {HVLocalizeStrings.PROFILE_CHANGE_LANGUAGE}
                  </Link>
                </ListItem>

                {currentApp !== AppType.SPORT && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        setIsThemeModalOpen(true);
                        e.preventDefault();
                      }}
                      data-testid={HVTestId.ProfilePage.themeLink}
                    >
                      {HVLocalizeStrings.PROFILE_CHANGE_THEME}
                    </Link>
                  </ListItem>
                )}

                {userAssociations?.hierarchyId &&
                  self!.role >= UserRole.Learner && (
                    <ListItem mb={2}>
                      <Link
                        textDecoration={"underline"}
                        href="#"
                        onClick={(e) => {
                          setHierarchyModalOpen(true);
                          e.preventDefault();
                        }}
                      >
                        {HVLocalizeStrings.PROFILE_CHANGE_HIERARCHY}
                      </Link>
                    </ListItem>
                  )}

                {customFields && customFields.length > 0 && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        setCustomFieldsModalOpen(true);
                        e.preventDefault();
                      }}
                      data-testid={HVTestId.ProfilePage.userInfoLink}
                    >
                      {HVLocalizeStrings.CUSTOM_FIELDS_TITLE}
                    </Link>
                  </ListItem>
                )}

                {!isWebPlatform() && pushDisabled && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={async () => {
                        promptForPushPermission();
                      }}
                    >
                      {HVLocalizeStrings.SOLO_MENU_PUSH_NOTIFICATION_PERMISSION}
                    </Link>
                  </ListItem>
                )}

                {self && !emailSubscriptionEnabled && (
                  <ListItem mb={2} color={Error_Red}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        onEnableEmailNotifications();
                        e.preventDefault();
                      }}
                      color={Error_Red}
                      data-testid={
                        HVTestId.ProfilePage.enableEmailSubscriptionsLink
                      }
                    >
                      {HVLocalizeStrings.ENABLE_EMAIL_NOTIFICATIONS}
                    </Link>
                  </ListItem>
                )}

                {isOnSolo && selectedGoal && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        navigate("?challenge=1", { replace: true });

                        e.preventDefault();
                      }}
                      data-testid={HVTestId.ProfilePage.languageLink}
                    >
                      {HVLocalizeStrings.PRACTICE_REMINDER_MANAGE_CHALLENGE}
                    </Link>
                  </ListItem>
                )}

                {!isWebPlatform() &&
                  localStorage &&
                  typeof Haptics !== "undefined" && (
                    <ListItem mb={2}>
                      <Link
                        textDecoration={"underline"}
                        href="#"
                        onClick={(e) => {
                          if (hapticsDisabled) {
                            localStorage.removeItem(HAPTICS_DISABLED_KEY);
                            performHapticFeedback(HapticsType.Impact);
                          } else {
                            localStorage.setItem(HAPTICS_DISABLED_KEY, "true");
                          }

                          setHapticsDisabled(!hapticsDisabled);

                          e.preventDefault();
                        }}
                      >
                        {hapticsDisabled
                          ? HVLocalizeStrings.SOLO_MENU_ENABLE_HAPTICS
                          : HVLocalizeStrings.SOLO_MENU_DISABLE_HAPTICS}
                      </Link>
                    </ListItem>
                  )}
              </UnorderedList>

              <UnorderedList mr={2}>
                {isOnSolo && (
                  <>
                    <ListItem mb={2}>
                      <Link
                        textDecoration={"underline"}
                        href="#"
                        onClick={(e) => {
                          track(EVENTS.WelcomeModalShownProfilePage);
                          setIsWelcomeModalOpen(true);
                          e.preventDefault();
                        }}
                        data-testid={HVTestId.ProfilePage.viewIntroLink}
                      >
                        {HVLocalizeStrings.INTRO_BUTTON_TITLE}
                      </Link>
                    </ListItem>

                    <ListItem mb={2}>
                      <Link
                        textDecoration={"underline"}
                        href="#"
                        onClick={(e) => {
                          navigate("/");
                          setIsTourOpen(true);
                          e.preventDefault();
                        }}
                        data-testid={HVTestId.ProfilePage.takeTourLink}
                      >
                        {HVLocalizeStrings.TOUR_MENU}
                      </Link>
                    </ListItem>

                    <ListItem mb={2}>
                      <Link
                        textDecoration={"underline"}
                        href="#"
                        onClick={(e) => {
                          track(EVENTS.ActivityHistoryOpened, {
                            HV_From: "ProfilePage",
                          });

                          setActivityHistoryModalOpen(true);
                          e.preventDefault();
                        }}
                      >
                        {
                          HVLocalizeStrings.PROFILE_PAGE_PRACTICE_REMINDERS_HISTORY
                        }
                      </Link>
                    </ListItem>
                  </>
                )}

                {currentApp === AppType.TEAM && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        track(EVENTS.TeamFirstTimeInterstitialShown);

                        setIsTeamWelcomeOpen(true);
                      }}
                      data-testid={HVTestId.ProfilePage.viewIntroLink}
                    >
                      {HVLocalizeStrings.INTRO_BUTTON_TITLE}
                    </Link>
                  </ListItem>
                )}

                {currentApp === AppType.CERT && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        track(EVENTS.CertFirstTimeInterstitialShown);

                        setIsCertWelcomeOpen(true);
                      }}
                      data-testid={HVTestId.ProfilePage.viewIntroLink}
                    >
                      {HVLocalizeStrings.INTRO_BUTTON_TITLE}
                    </Link>
                  </ListItem>
                )}

                {currentApp === AppType.SPORT && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        setIsSportWelcomeOpen(true);
                      }}
                      data-testid={HVTestId.ProfilePage.viewIntroLink}
                    >
                      {HVLocalizeStrings.INTRO_BUTTON_TITLE}
                    </Link>
                  </ListItem>
                )}

                <ListItem mb={2}>
                  <Link
                    textDecoration={"underline"}
                    href="#"
                    onClick={(e) => {
                      logout();
                      e.preventDefault();
                    }}
                    data-testid={HVTestId.ProfilePage.logOutLink}
                  >
                    {HVLocalizeStrings.SOLO_MENU_LOGOUT}
                  </Link>
                </ListItem>
              </UnorderedList>
            </SimpleGrid>
          </BlockSection>

          <BlockSection
            columns={0}
            fadeIn={true}
            title={<BlockSectionTitle title={HVLocalizeStrings.OTHER} />}
          >
            <UnorderedList mt={4} lineHeight="1.3">
              {contactUsJsx}

              {sharedFamilyInviteCode && (
                <ListItem mb={2}>
                  <Link
                    textDecoration={"underline"}
                    href="#"
                    onClick={(e) => {
                      setInviteFamilyMembersModalOpen(true);
                      e.preventDefault();
                    }}
                  >
                    {HVLocalizeStrings.SOLO_MENU_INVITE_FAMILY_MEMBERS}
                  </Link>
                </ListItem>
              )}
              <ListItem mb={2}>
                <Link
                  textDecoration={"underline"}
                  href="#"
                  onClick={(e) => {
                    setTermsOfUseModalOpen(true);
                    e.preventDefault();
                  }}
                >
                  {HVLocalizeStrings.SOLO_MENU_TERMS_OF_SERVICE}
                </Link>
              </ListItem>
              <ListItem mb={2}>
                <Link
                  textDecoration={"underline"}
                  href="#"
                  onClick={(e) => {
                    setPrivatePolicyModalOpen(true);
                    e.preventDefault();
                  }}
                >
                  {HVLocalizeStrings.SOLO_MENU_PRIVACY_POLICY}
                </Link>
              </ListItem>
              {isUpdateNameEnabled &&
                self &&
                !self.isOnSso &&
                !localStorage.getItem("impersonatingUserId") && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        setUpdateNameModalOpen(true);
                        e.preventDefault();
                      }}
                    >
                      {HVLocalizeStrings.PROFILE_UPDATE_NAME}
                    </Link>
                  </ListItem>
                )}
              {self &&
                !self.isOnSso &&
                !localStorage.getItem("impersonatingUserId") && (
                  <ListItem mb={2}>
                    <Link
                      textDecoration={"underline"}
                      href="#"
                      onClick={(e) => {
                        onChangePasswordClick();
                        e.preventDefault();
                      }}
                      data-testid={HVTestId.ProfilePage.changePwdLink}
                    >
                      {HVLocalizeStrings.PROFILE_CHANGE_PASSWORD}
                    </Link>
                  </ListItem>
                )}
              {self && !self.isOnSso && (
                <ListItem mb={2} color={Error_Red}>
                  <Link
                    textDecoration={"underline"}
                    href="#"
                    onClick={(e) => {
                      openDeleteAccountDialogStart();
                      e.preventDefault();
                    }}
                    color={Error_Red}
                    data-testid={HVTestId.ProfilePage.deleteAccountLink}
                  >
                    {HVLocalizeStrings.DELETE_ACCOUNT_TITLE_2}
                  </Link>
                </ListItem>
              )}
            </UnorderedList>
          </BlockSection>

          {process.env.REACT_APP_BUILD_NUMBER && (
            <BlockSection
              columns={0}
              fadeIn={true}
              title={<BlockSectionTitle title={"Build Number"} />}
            >
              <Text>{process.env.REACT_APP_BUILD_NUMBER}</Text>
            </BlockSection>
          )}

          {mobileEnvs && (
            <BlockSection
              columns={0}
              fadeIn={true}
              title={<BlockSectionTitle title={"Environments"} />}
            >
              <HvSelect
                fontSize={"sm"}
                backgroundColor={"white"}
                color={"black"}
                onChange={(x: any) => {
                  if (
                    localStorage.getItem("override_live_channel") !==
                    x.target.value
                  ) {
                    localStorage.setItem(
                      "override_live_channel",
                      x.target.value
                    );
                    updateApp();
                  }
                }}
                value={localStorage.getItem("override_live_channel") as any}
              >
                <option
                  key={"none_option"}
                  value={""}
                  selected={!localStorage.getItem("override_live_channel")}
                >
                  none
                </option>
                {mobileEnvs.split(",")?.map((item: any) => {
                  return (
                    <option
                      key={item}
                      value={item}
                      selected={
                        item === localStorage.getItem("override_live_channel")
                      } // this shows a React warning but doesn't work right without it
                    >
                      {item}
                    </option>
                  );
                })}
              </HvSelect>
            </BlockSection>
          )}
        </Box>

        <LanguageModal
          open={languageModalOpen}
          setOpen={setLanguageModalOpen}
        />

        {activityHistoryModalOpen && (
          <ActivityHistoryModal
            open={activityHistoryModalOpen}
            setOpen={setActivityHistoryModalOpen}
          />
        )}

        <CustomFieldModal
          open={customFieldsModalOpen}
          setOpen={setCustomFieldsModalOpen}
          preventClose={false}
        />

        {isWelcomeModalOpen && (
          <WelcomeModal
            open={isWelcomeModalOpen}
            setOpen={(val: any) => {
              setIsWelcomeModalOpen(val);
            }}
          />
        )}

        <PracticeReminderModal
          open={isProfilePracticeReminderModalOpen}
          setOpen={setIsProfilePracticeReminderModalOpen}
          onClose={() => {
            navigate(window.location.pathname, { replace: true });
          }}
        />

        {sharedFamilyInviteCode && (
          <InviteFamilyMembersModal
            familyInviteCode={sharedFamilyInviteCode}
            isOpen={inviteFamilyMembersModalOpen}
            setOpen={setInviteFamilyMembersModalOpen}
          />
        )}

        <ThemeSelectModal
          open={isThemeModalOpen}
          setOpen={setIsThemeModalOpen}
        />

        {userAssociations?.hierarchyId && (
          <HierarchyModal
            hierarchyId={userAssociations.hierarchyId}
            isChangeHierarchy={true}
            open={hierarchyModalOpen}
            setOpen={setHierarchyModalOpen}
            displayAsModal={true}
          />
        )}

        <UpdateNameModal
          open={updateNameModalOpen}
          setOpen={setUpdateNameModalOpen}
        />

        <TermsOfUse
          termsOfUseModalOpen={termsOfUseModalOpen}
          openTermsOfUseModal={setTermsOfUseModalOpen}
        />
        <PrivacyPolicy
          privacyPolicyOpen={privatePolicyModalOpen}
          openPrivacyPolicy={setPrivatePolicyModalOpen}
        />

        <ConfirmationDialog ref={confirmationDialogRef} />

        <AboutTeamModal
          open={isTeamWelcomeOpen}
          setOpen={(val: any) => {
            setIsTeamWelcomeOpen(val);
          }}
        />

        <CertFirstTimeInterstitialModal
          open={isCertWelcomeOpen}
          setOpen={(val: any) => {
            setIsCertWelcomeOpen(val);
          }}
        />

        {currentApp === AppType.SPORT && (
          <SportOnboarding
            open={isSportWelcomeOpen}
            setOpen={(open: boolean) => {
              setIsSportWelcomeOpen(open);
            }}
            initialStep={OnboardingStep.Welcome}
          />
        )}
      </FadeAfterDelay>
    </>
  );
};

type InviteFamilyMembersModalProps = {
  familyInviteCode: InviteCodeForReachDto;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

const InviteFamilyMembersModal = (props: InviteFamilyMembersModalProps) => {
  const { familyInviteCode, isOpen, setOpen } = props;
  const { selectedUserLanguage } = useContext(GlobalContext);
  return (
    <LargeModal
      open={isOpen}
      setOpen={setOpen}
      title={HVLocalizeStrings.SOLO_MENU_INVITE_FAMILY_MEMBERS}
    >
      <FamilyInviteCode
        language={selectedUserLanguage as Language}
        familyInviteCode={familyInviteCode}
      />
    </LargeModal>
  );
};

interface DeleteAccountConfirmationProps {
  logout: () => void;
}
const DeleteAccountConfirmation = (props: DeleteAccountConfirmationProps) => {
  const { confirmationDialogRef } = useContext(ShellContext);
  const { logout } = props;
  const [password, setPassword] = useState<string>("");
  const [deleteAccountError, setDeleteAccountError] = useState<string>("");

  const deleteAccount = (password: string) => {
    if (!password || password.trim() === "") {
      return;
    }
    return deleteSelf(getKey(), password).then((response) => {
      if (response.data.errorMessage) {
        setDeleteAccountError(response.data.errorMessage);
      } else {
        logout();
      }
    });
  };

  return (
    <Flex px={"6"} pt={"2"} w={"100%"} gap={"2"} flexDir={"column"}>
      <Box>
        <Center>
          <PasswordInput
            border={"1px solid #E2E8F0"}
            width={"unset"}
            passwordError={
              deleteAccountError === "Invalid Password"
                ? HVLocalizeStrings.INVALID_PASSWORD
                : ""
            }
            hideToolTip={true}
            pwdHasNumber={true}
            pwdHasLowerCase={true}
            pwdHasUpperCase={true}
            pwdHasMinLength={true}
            handleChange={(
              stateToUpdate: RegistrationLoginStates,
              stateText: string
            ) => {
              setPassword(stateText);
            }}
            onKeyDown={(e: any) => {
              console.log("key down");
              console.log(e.key);
              if (e.key === "Enter") {
                deleteAccount(password);
              }
            }}
          />
        </Center>
      </Box>
      <Box>
        <Center>
          <AnimatedButton
            text={HVLocalizeStrings.CONFIRM_YES}
            colorSet={AnimatedButtonColorSet.Fifth}
            onClick={() => {
              deleteAccount(password);
            }}
            disabled={!password || password.trim() === ""}
          ></AnimatedButton>
          &nbsp;&nbsp;
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Third}
            text={HVLocalizeStrings.CONFIRM_NO as any}
            onClick={() => {
              setDeleteAccountError("");
              setPassword("");
              confirmationDialogRef.current.close();
            }}
          ></AnimatedButton>
        </Center>
      </Box>
    </Flex>
  );
};
