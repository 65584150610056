import React, { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { PollQuestionDto } from "@headversity/contract";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import { PollMultiSelectInput } from "../../../Shared/SelectInput/PollMultiSelectInput";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { performHapticFeedback } from "../../../../Api/Utils";
import { Box, Flex } from "@chakra-ui/react";
import { HvSpinner } from "../../../Common/HvSpinner";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";

export const ParticipantAmbassador = () => {
  const {
    pollQuestions,
    self,
    currentParticipantList,
    setPollInstanceResultToServer,
    currentTeamLessonUserInstanceId,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [pollQuestion, setPollQuestion] = useState<PollQuestionDto | undefined>(
    undefined
  );

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const { trackEvent, lessonName } = useSportTeam();

  const { currentUserPollQuestionResults, currentPollInstanceId } =
    usePollQuestionInformation({
      pollQuestion: pollQuestion,
      byGroup: false,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  useEffect(() => {
    performHapticFeedback();
  }, []);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "AMBASSADOR_1_PQ"
      );
      setPollQuestion(pollQuestion);
    }
  }, [pollQuestions]);

  if (!currentPollInstanceId) {
    return <HvSpinner centerVertically />;
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={false} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {currentUserPollQuestionResults.length < 1 ? (
        <>
          {currentParticipantList && (
            <Box mb="8px">
              <Flex alignSelf={"start"}>
                <BlockSectionTitle
                  title={
                    HVSportLocalizeStrings.CHARTER_AMBASSADOR_VOTE_QUESTION
                  }
                  primaryTextColor={Black}
                  borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
                />
              </Flex>
              <PollMultiSelectInput
                questionText={
                  currentParticipantList.length === 1
                    ? HVSportLocalizeStrings.CHARTER_AMBASSADOR_VOTE_INSTRUCTIONS_SINGLE_USER
                    : HVSportLocalizeStrings.CHARTER_AMBASSADOR_VOTE_INSTRUCTIONS
                }
                doneButtonMessage={HVLocalizeStrings.DONE}
                alternatives={
                  currentParticipantList
                    ?.filter((p) => p.userId !== self?.id)
                    .map((p) => {
                      return {
                        id: p.userId,
                        responseText:
                          p.user.fullName ?? p.user.email ?? "unknown",
                      } as any;
                    }) ?? ([] as any)
                }
                currentSelectedAnswers={selectedUserIds}
                submitPollResponse={() => {
                  trackEvent(SPORT_EVENTS.IndividualInputSubmitted, {
                    HV_Presentation: lessonName,
                    HV_Question_Id: pollQuestion?.id,
                  });
                  if (currentPollInstanceId) {
                    setLoading(true);
                    setPollInstanceResultToServer(currentPollInstanceId, {
                      questionAlternativeIds: [],
                      feedback: "",
                      questionUserResponseIds: [],
                      freeTexts:
                        currentParticipantList.length === 1
                          ? [currentParticipantList[0].userId.toString()]
                          : selectedUserIds.map((su) => su.toString()),
                    });
                  }
                }}
                setCurrentSelectedAnswers={setSelectedUserIds}
                disableSelection={
                  (selectedUserIds.length > 2 &&
                    selectedUserIds.length !==
                      currentParticipantList?.length - 1) ||
                  loading
                }
                disableIamDone={
                  (selectedUserIds.length < 1 &&
                    selectedUserIds.length !==
                      currentParticipantList?.length - 1) ||
                  loading
                }
                loading={loading}
                useBottomActionBar={true}
              />
            </Box>
          )}
        </>
      ) : (
        <ParticipantWaitingScreen />
      )}
    </ParticipantContainer>
  );
};
