import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
import axios from "axios";
import { parseJSON } from "../Utils/Helpers";

export const getConfig = (
  token: string | null,
  contentType: string | null = null
) => {
  if (!token || token == "") {
    return null;
  }
  let config = {} as any;
  if (token) {
    config["headers"] = {
      Authorization: `Bearer ${token}`,
    };
  }
  if (contentType) config["headers"]["Content-Type"] = contentType;
  return config;
};

export const currentAppVersion = {
  value: "",
};

export const getBlobUrlAndDownload = (text: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([text]));
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const HAPTICS_DISABLED_KEY = "disableHaptics";

export enum HapticsType {
  LightImpact,
  Impact,
  Success,
  Vibrate,
}

/**
 * Perform haptic feedback (if available). Default is vibration.
 *
 * @param type - The type of haptic feedback to perform (optional).
 */
export const performHapticFeedback = (type?: HapticsType) => {
  if (localStorage && localStorage.getItem(HAPTICS_DISABLED_KEY)) return;

  if (typeof Haptics !== "undefined") {
    if (type === HapticsType.LightImpact && Haptics.impact) {
      Haptics.impact({ style: ImpactStyle.Light });
    } else if (type === HapticsType.Impact && Haptics.impact) {
      Haptics.impact({ style: ImpactStyle.Medium });
    } else if (type === HapticsType.Success && Haptics.notification) {
      Haptics.notification({ type: NotificationType.Success });
    } else if (Haptics.vibrate) {
      Haptics.vibrate({ duration: 500 });
    }
  }
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  if (!fileName || fileName === "") fileName = fileUrl.split("/").slice(-1)[0];
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    getBlobUrlAndDownload(response.data, fileName);
  });
};

export const cachedApiCall = async <T>(
  key: string,
  duration: number,
  callback: () => Promise<T>
): Promise<T> => {
  const now = Date.now();
  const cachedData = sessionStorage.getItem(key);
  if (cachedData) {
    const { data, timestamp } = parseJSON(cachedData);
    if (now - timestamp < duration) {
      return data;
    }
  }
  const data = await callback();
  sessionStorage.setItem(
    key,
    JSON.stringify({
      data,
      timestamp: now,
    })
  );
  return data;
};

export const retryWithDelay = (
  callback: () => Promise<any>,
  maxRetries: number,
  initialDelay: number,
  shouldRetryOnError: (errorMessage: string | null) => boolean
): Promise<any> => {
  return new Promise((resolve, reject) => {
    function retry() {
      let retryCount = 0;
      callback()
        .then(resolve)
        .catch((errorResponse) => {
          if (
            retryCount < maxRetries &&
            shouldRetryOnError(errorResponse?.message)
          ) {
            retryCount++;
            const timeToWait = 2 ** retryCount * initialDelay;
            setTimeout(retry, timeToWait);
          } else {
            reject(errorResponse);
          }
        });
    }
    retry();
  });
};
