import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  SkeletonText,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  Border_Radius,
  Equity_Orange,
  HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import { LargeModal } from "../../Common/LargeModal";
import { HvSpinner } from "../../Common/HvSpinner";
import { AnimatedButton } from "../../Common/AnimatedButton";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import { BlogPostDto } from "@headversity/contract";
import { LikeAndDislikeButton } from "../../Common/LikeAndDislikeButton";
import { getFeedbackQuestionAnswers } from "../../../Utils/Helpers";
import { EVENTS, track } from "../../../Utils/Analytics";
import { ShellContext } from "../../../State/ShellContext";
import { HvTextarea } from "../../Common/HvTextarea";
import { isAndroid, isWebPlatform } from "../../../Utils/mobileUtils";
import { PointsButton } from "../Shared/PointsButton";
import { getTotalPoints } from "../../../Utils/SkillsUtil";

// use for new resilience posts
const HVResiliencePostContent = styled(Container)`
  ul {
    list-style-type: circle;
    padding-left: 30px;
  }

  ol {
    list-style-type: decimal;
    padding-left: 30px;
  }

  a {
    text-decoration: underline;
    color: blue;
  }

  h3 {
    font-size: 20px !important;
  }

  p {
    padding: 10px;
  }

  div:has(> iframe) {
    margin-bottom: 40px;
  }

  .btn-primary {
    position: relative;
    top: 20px;
    padding: 10px 20px;
    background-color: ${Equity_Orange};
    display: block;
    text-align: center;
  }
`;

// keep previous content styling for legacy posts to prevent UI styling regressions
const HVResiliencePostLegacyContent = styled(Flex)`
  h3 {
    font-size: 20px !important;
  }

  p {
    padding: 10px;
  }

  div:has(> iframe) {
    margin-bottom: 40px;
  }

  .btn-primary {
    position: relative;
    top: 20px;
    padding: 10px 20px;
    background-color: ${Equity_Orange};
    display: block;
    text-align: center;
  }
`;

export const ResiliencePostModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openedTimeout, setOpenedTimeout] = useState<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    allResilienceFeedPosts,
    saveBlogViewToServer,
    getBlogByIdFromServer,
    saveBlogQuestionAnswersToServer,
    setGlobalBlogPost,
    userSkillStats,
    selectedGoal,
    globalBlogPostCollection,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets, showToast } = useContext(ShellContext);

  const [resiliencePost, setResiliencePost] = useState<
    BlogPostDto | undefined
  >();
  const [content, setContent] = useState<any>(null);
  const [hideCTAButton, setHideCTAButton] = useState(false);
  const [hasCTAButton, setHasCTAButton] = useState(false);

  const [feedback1, setFeedback1] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackSaved, setFeedbackSaved] = useState(false);

  const [initialPoints, setInitialPoints] = useState<number>(0);
  const [currentPoints, setCurrentPoints] = useState<number>(0);

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [showNextButton, setShowNextButton] = useState(false);
  let [searchParamCounter, setSearchParamCounter] = useState<number>(0);

  useEffect(() => {
    setInitialPoints(getTotalPoints(userSkillStats));
  }, [resiliencePost?.id]);

  useEffect(() => {
    setCurrentPoints(getTotalPoints(userSkillStats));
  }, [userSkillStats]);

  useEffect(() => {
    // for now:
    // - strip out hard-coded font family
    // - anything deep-linked with “/tabs/train” will go to our “/lessons
    // - anything otherwise deep-linked will go to our “/”
    if (resiliencePost?.content) {
      let content =
        (resiliencePost.content as string)
          .replace(/font-family:.*?;/gm, "")
          .replace(/font-size:.*?;/gm, "")
          .replace(
            /<div style="padding: 56.25% 0 0 0; position: relative;">/gm,
            '<div style="padding: 56.25% 0 0 0; position: relative; display: block; width: 100%;">'
          )
          .replace(
            /background-color: #ff9900; color: #ffffff;/gm,
            "text-decoration: underline;"
          )
          .replace(/background-color: #ffffff;/gm, "")
          .replace(/href/gm, 'target="_blank" href')
          .replace(
            /<a class="btn btn-primary" target="_blank"/gm,
            '<a class="btn btn-primary"'
          )
          .replace(
            /"deeplink\$\$\$\/tabs\/practice\$\$\$HeadzoneToolPage.*?"/gm,
            '"/tools/headzone?feed=1"'
          )
          .replace(
            /"deeplink\$\$\$\/tabs\/practice\$\$\$ThinkingToolPage.*?"/gm,
            '"/tools/thinking?feed=1"'
          )
          .replace(
            /"deeplink\$\$\$\/tabs\/practice\$\$\$FocusToolPage.*?"/gm,
            '"/tools/focus?feed=1"'
          )
          .replace(
            /"deeplink\$\$\$\/tabs\/practice\$\$\$OptimismToolPage.*?"/gm,
            '"/tools/optimism?feed=1"'
          )
          .replace(/"deeplink\$\$\$\/tabs\/train.*?"/gm, '"/lessons?feed=1"')
          .replace(/"deeplink\$\$\$\/tabs\/practice.*?"/gm, '"/tools?feed=1"')
          .replace(/"deeplink\$\$\$.*?"/gm, '"/?feed=1"') ?? "";

      if (hideCTAButton) {
        content = content.replace(
          /<a class="btn btn-primary"/gm,
          '<a style="display:none;"'
        );
      }

      // old posts had a CTA button at the bottom... in that case, we want to hide "Close" button
      //  but, if the post has no CTA button, we want to show the "Close" button, so that there's always a button at the bottom
      setHasCTAButton(
        content.indexOf('<a class="btn btn-primary"') > -1 ||
          content.indexOf('<a style="display:none;"') > -1
      );

      // reset feedback
      setFeedback1("");
      setFeedbackText("");
      setFeedbackSaved(false);

      // apply legacy styling fix only to legacy posts
      if (resiliencePost.isLegacy) {
        content = content.replace(/text-align: center;/gm, "text-align: left;");
      }

      setContent(content);
    }
  }, [resiliencePost, hideCTAButton]);

  useEffect(() => {
    const handleSearchParamsUpdated = () => {
      setSearchParamCounter(searchParamCounter++);
    };

    window.addEventListener("blogPostParamUpdated", handleSearchParamsUpdated);

    return () => {
      window.removeEventListener(
        "blogPostParamUpdated",
        handleSearchParamsUpdated
      );
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("post") && allResilienceFeedPosts?.length > 0) {
      // CTA only on resilience feed page
      setHideCTAButton(window.location.href.indexOf("feed") === -1);

      const id = parseInt(searchParams.get("post") as string);

      // don't reload the post we're already looking at
      if (resiliencePost && resiliencePost.id === id) {
        setOpen(true);
        return;
      }

      setResiliencePost(undefined);
      setContent(null);

      getBlogByIdFromServer(id).then(
        (post) => {
          setResiliencePost(post);
          setOpen(true);
        },
        () => {
          showToast(
            false,
            "error",
            HVLocalizeStrings.RESILIENCE_FEED_POST_NOT_FOUND
          );
        }
      );
    }
  }, [searchParams, allResilienceFeedPosts, setOpen, searchParamCounter]);

  useEffect(() => {
    if (!resiliencePost) return;

    // clear any previous timeout
    if (openedTimeout) {
      clearTimeout(openedTimeout);
      setOpenedTimeout(null);
      setShowNextButton(false);
    }

    if (open) {
      // if we had the post open more than 5s, we'll count it as "viewed"
      const timeout = setTimeout(() => {
        saveBlogViewToServer(resiliencePost.id).then(() => {
          setShowNextButton(hideCTAButton || !hasCTAButton);
        });
      }, 5000);
      setOpenedTimeout(timeout);
    }

    return () => {
      if (openedTimeout) {
        clearTimeout(openedTimeout);
        setOpenedTimeout(null);
      }
    };
  }, [open, resiliencePost]);

  useEffect(() => {
    if (content && open) {
      const contentInterval = setInterval(() => {
        const element = document.getElementById("resiliencyPostElement");
        if (element) {
          clearInterval(contentInterval);
          if (process.env.REACT_APP_DEEP_LINK_URL) {
            const deepLinkUrl =
              process.env.REACT_APP_DEEP_LINK_URL.toLocaleLowerCase();
            const allAnchors = element.getElementsByTagName("a");
            for (let i = 0; i < allAnchors.length; i++) {
              const a = allAnchors[i];
              if (a.href?.toLocaleLowerCase()?.indexOf(deepLinkUrl) > -1) {
                const url = new URL(a.href);
                a.onclick = () => {
                  navigate(`${url.pathname}${url.search}`);
                  setOpen(false);
                  return false;
                };
                a.href = "";
                a.target = "";
              }
            }
          }
        }
      }, 500);

      return () => {
        clearInterval(contentInterval);
      };
    }
  }, [content, open]);

  const handleClose = () => {
    setOpen(false);

    setGlobalBlogPost(undefined);
    searchParams.delete("post");
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const feedbackRef = useRef(null);

  return (
    <LargeModal
      title={resiliencePost?.name as string}
      open={open}
      setOpen={
        ((val: boolean) => {
          if (!val) {
            handleClose();
          }
          setOpen(val);
        }) as any
      }
    >
      {initialPoints !== currentPoints && selectedGoal && (
        <PointsButton
          points={initialPoints}
          currentPoints={currentPoints}
          top={isWebPlatform() ? "23px" : `${3 + safeAreaInsets.top}px`}
          pos="absolute"
          isInModal={true}
        />
      )}

      {!resiliencePost ? (
        <Box>
          <Center h="220px">
            <HvSpinner />
          </Center>
          <SkeletonText noOfLines={6} spacing="6" mx={16} />
        </Box>
      ) : (
        <Box
          paddingBottom={
            isAndroid() && feedback1 === "dislike" && !feedbackSaved
              ? `${window.screen.height / 2}px`
              : undefined
          }
        >
          <Box
            bgColor={HEADER_RESILIENCE_FEED_CONTENT_BACKGROUND}
            boxShadow={"xl"}
            borderRadius={Border_Radius}
          >
            <Image
              src={resiliencePost?.imageUrl as string}
              mt={isDesktop ? 4 : 1}
              mx="auto"
              height={"200px"}
              boxShadow={isDesktop ? "xs  " : ""}
              alt=""
            />
            {resiliencePost.isLegacy ? (
              <HVResiliencePostLegacyContent
                id={"resiliencyPostElement"}
                flexDir={"column"}
                justify={"center"}
                align={"center"}
                maxW={"container.md"}
                mx={"auto"}
                w={"100%"}
                p={4}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : (
              <HVResiliencePostContent
                id={"resiliencyPostElement"}
                maxW="container.md"
                p={4}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}

            <Box pb={4}>
              {!resiliencePost.isLiveSession && (
                <>
                  <Box px={10}>
                    <Divider my={4} />
                  </Box>

                  <Center
                    pb={
                      !showNextButton
                        ? `${10 + safeAreaInsets.bottom}px`
                        : undefined
                    }
                  >
                    <Box w="100%">
                      <LikeAndDislikeButton
                        noShadow={true}
                        maxW="350px"
                        result={feedback1}
                        description={
                          HVLocalizeStrings.RESILIENCE_FEED_FEEDBACK1
                        }
                        setResult={(result: string) => {
                          // always save feedback with English question text
                          const englishText =
                            HVLocalizeStringsObj.en.RESILIENCE_FEED_FEEDBACK1;

                          track(EVENTS.ResiliencePostFeedback, {
                            HV_Question: englishText,
                            HV_PostId: resiliencePost.id,
                            HV_PostTitle: resiliencePost.name_loc?.en,
                            HV_Response: result === "like" ? "up" : "down",
                          });

                          saveBlogQuestionAnswersToServer(
                            resiliencePost.id,
                            getFeedbackQuestionAnswers(englishText, result)
                          );

                          setFeedback1(result);

                          // ensure button is in view
                          if (result === "dislike") {
                            setTimeout(() => {
                              (feedbackRef.current as any).scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              });
                            }, 250);
                          }
                        }}
                        dataTestId={
                          HVTestId.ResiliencePostModal.feedbackButtonContainer
                        }
                      />
                    </Box>
                  </Center>

                  <Center display={feedbackSaved ? "flex" : "none"} mt="20px">
                    <Text mb={1}>{HVLocalizeStrings.THANK_YOU}.</Text>
                  </Center>

                  <Center
                    display={
                      feedback1 === "dislike" && !feedbackSaved
                        ? "flex"
                        : "none"
                    }
                  >
                    <Box
                      px="10px"
                      mt="20px"
                      w={!isDesktop ? "100%" : "400px"}
                      fontSize="15px"
                    >
                      <Text mb={"5px"}>
                        {HVLocalizeStrings.NEGATIVE_FEEDBACK_WHY}
                      </Text>

                      <HvTextarea
                        onChange={(e) => {
                          setFeedbackText(e.target.value);
                        }}
                        data-testid={
                          HVTestId.ResiliencePostModal.feedbackTextArea
                        }
                      />

                      <Center
                        mt="10px"
                        ref={feedbackRef}
                        pb={
                          !showNextButton
                            ? `${10 + safeAreaInsets.bottom}px`
                            : undefined
                        }
                      >
                        <AnimatedButton
                          disabled={feedbackText.length === 0}
                          text={HVLocalizeStrings.SAVE}
                          onClick={() => {
                            track(EVENTS.ResiliencePostFeedbackComments, {
                              HV_PostId: resiliencePost.id,
                              HV_PostTitle: resiliencePost.name_loc?.en,
                            });

                            setFeedbackSaved(true);
                            saveBlogQuestionAnswersToServer(
                              resiliencePost.id,
                              getFeedbackQuestionAnswers(
                                "FeedbackComments",
                                feedbackText
                              )
                            );
                          }}
                          dataTestid={
                            HVTestId.ResiliencePostModal.feedbackSaveButton
                          }
                        />
                      </Center>
                    </Box>
                  </Center>
                </>
              )}
            </Box>
          </Box>

          <Box h={showNextButton ? "auto" : "0px"}>
            <Center
              opacity={showNextButton ? 1 : 0}
              pointerEvents={showNextButton ? "auto" : "none"}
              transition="opacity 0.2s ease-in-out"
              mt={"30px"}
              pb={`${10 + safeAreaInsets.bottom}px`}
            >
              <AnimatedButton
                text={
                  resiliencePost.isLiveSession ||
                  globalBlogPostCollection.length === 0
                    ? HVLocalizeStrings.CLOSE
                    : HVLocalizeStrings.NEXT
                }
                onClick={() => {
                  if (
                    resiliencePost.isLiveSession ||
                    globalBlogPostCollection.length === 0
                  ) {
                    handleClose();
                    return;
                  }

                  // find this post after this one in the collection
                  const index = globalBlogPostCollection.findIndex(
                    (p) => p.id === resiliencePost.id
                  );

                  // if we can't find it, close the modal
                  if (index === -1) {
                    showToast(
                      false,
                      "error",
                      HVLocalizeStrings.RESILIENCE_FEED_POST_NOT_FOUND
                    );
                    handleClose();
                    return;
                  }

                  track(EVENTS.ResilienceFeedPostOpened, {
                    HV_PostId: resiliencePost.id,
                    HV_PostTitle: resiliencePost.name_loc?.en,
                    HV_From: "Next",
                  });

                  // go to the next post in the collection; if we hit the end, go back to the beginning
                  const nextPost =
                    globalBlogPostCollection[
                      index + 1 === globalBlogPostCollection.length
                        ? 0
                        : index + 1
                    ];

                  // go to the next post via url
                  setShowNextButton(false);
                  navigate(`?post=${nextPost.id}`, { replace: true });
                }}
              />
            </Center>
          </Box>
        </Box>
      )}
    </LargeModal>
  );
};
