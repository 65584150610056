import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import wordcloud from "highcharts/modules/wordcloud";
require("highcharts/modules/wordcloud")(Highcharts);

interface WordCloudChartProps {
  words: string[];
  colors?: string[];
  title?: string;
  width?: number;
  rotation?: { from: number; to: number; orientations: number };
}

interface WordCloudData {
  name: string;
  weight: number;
  color: string;
}

// Initialize the Word Cloud module
wordcloud(Highcharts);

export const WordCloudChart = (props: WordCloudChartProps) => {
  const {
    words,
    colors = ["rgb(0, 95, 123)"],
    title,
    width = null, // null defaults the chart to filling it's container
    rotation = { from: 0, to: 0, orientations: 1 },
  } = props;

  const [wordCloudData, setWordCloudData] = useState<WordCloudData[]>([]);

  // Converts a weight range to a scale (between 0 and 1) to be used as an alpha value in rgb color
  // word.weight is provided as the input parameter value which then determines the final alpha value for the word
  const makeRgbAlphaValue = (
    wordWeight: number,
    weights: [minWeight: number, maxWeight: number],
    range: [rangeStart: number, rangeEnd: number]
  ) => {
    const minWeight = weights[0];
    const maxWeight = weights[1];
    const rangeStart = range[0];
    const rangeEnd = range[1];

    return minWeight === maxWeight
      ? 1 // prevent NaN where rangeEnd - rangeStart equals 0
      : rangeStart +
          (rangeEnd - rangeStart) *
            ((wordWeight - minWeight) / (maxWeight - minWeight));
  };

  // Parse through the words array and create new objects with the word, it's weight, color, and alpha color value
  useEffect(() => {
    if (words && words.length > 0) {
      // Create a Map to store words and their accumulated weight
      const wordWeightMap = new Map();

      // Iterate over the words array
      words.forEach((word) => {
        // If the word already exists, increase the weight by 10
        if (wordWeightMap.has(word)) {
          wordWeightMap.set(word, wordWeightMap.get(word) + 10);
        } else {
          // If it's the first occurrence, assign a random weight between 5 and 10
          wordWeightMap.set(word, Math.floor(Math.random() * 6) + 5);
        }
      });

      // Convert the Map into an array of objects
      const weightedWordCloudData = Array.from(
        wordWeightMap,
        ([name, weight]) => ({
          name,
          weight,
        })
      );

      // Determine the minimum weight to be used later in alpha scale
      const minWeight = weightedWordCloudData.reduce(
        (min, word) => Math.min(min, word.weight),
        Infinity
      );

      // Determine the maximum weight to be used later in alpha scale
      const maxWeight = weightedWordCloudData.reduce(
        (max, word) => Math.max(max, word.weight),
        -Infinity
      );

      // Determine color and alpha value for each word
      const coloredWordCloudData = weightedWordCloudData.map((word) => {
        // Select a random color for each word
        const randomColor = colors[Math.floor(Math.random() * colors.length)];

        // Extract RGB values from the color string
        const rgbValues = randomColor.match(/\d+/g);

        const alphaValue = makeRgbAlphaValue(
          word.weight,
          [minWeight, maxWeight],
          [0.4, 1]
        );

        return {
          name: word.name,
          weight: word.weight,
          color: `rgba(${rgbValues![0]}, ${rgbValues![1]}, ${
            rgbValues![2]
          }, ${alphaValue})`,
        };
      });

      setWordCloudData(coloredWordCloudData);
    }
  }, [words]);

  const options: Highcharts.Options = {
    chart: {
      type: "wordcloud",
      width: width,
    },
    title: {
      text: title, // chart title
    },
    series: [
      {
        type: "wordcloud",
        data: wordCloudData,
        rotation: rotation, // degrees at which the words can be displayed
        colors: colors, // colors to be used when words are drawn
        enableMouseTracking: false, // disable hover on words
        minFontSize: 7,
        style: {
          fontFamily: "Arial", // Originally was using Barlow -- found on the first draw it always made the word too big. Arial didn't have this issue.
        },
      },
    ],
    credits: {
      enabled: false, // hide "Highcharts.com" label
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
