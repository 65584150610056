import { InviteCodeForReachDto } from "@headversity/contract";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  downloadUserCSv,
  notifyDownloadCsvComplete,
} from "../../Api/Reach/ReachApi";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { ShellContext } from "../../State/ShellContext";
import { createAndDownloadCSV, getKey } from "../../Utils/Helpers";
import { useReachGlobal } from "./useReachGlobal";

export const useUserReportDownload = () => {
  const [requestCsvJob, setRequestCsvJob] = useState<number | undefined>();
  const { reformatUsersDataForCSV } = useReachGlobal();
  const { selectedUserLanguage } = useContext<IGlobalProvider>(GlobalContext);
  const { showToast } = useContext(ShellContext);

  const location = useLocation();

  const markDownloadCsvComplete = useCallback(
    async (jobId: number) => {
      return await notifyDownloadCsvComplete(jobId, getKey());
    },

    []
  );
  const downloadCsv = useCallback(
    async (inviteCodesForReach: InviteCodeForReachDto[], jobId: number) => {
      const userData = await downloadUserCSv(jobId, getKey());
      if (userData) {
        const reformattedUsers = reformatUsersDataForCSV(
          userData.data,
          inviteCodesForReach,
          selectedUserLanguage
        );
        createAndDownloadCSV(
          reformattedUsers,
          HVLocalizeStrings.REACH_USERS_DOWNLOAD_CSV_FILE_NAME
        );

        showToast(
          true,
          "self-serve-user-report-download-started",
          HVLocalizeStrings.REACH_DOWNLOADED_REPORT
        );
        return true;
      }
      return false;
    },
    []
  );

  const handleSetRequestCsvJob = useCallback((jobId: number | undefined) => {
    setRequestCsvJob(jobId);
    // set to local storage
    if (jobId) {
      localStorage.setItem("requestCsv", jobId.toString());
    } else {
      localStorage.removeItem("requestCsv");
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let downloadCsvParam = queryParams.get("downloadCsv");

    // if downloadCsvParam is present, use that first (since this is from download url link)
    if (downloadCsvParam) {
      setRequestCsvJob(+downloadCsvParam);
    } else {
      // if we do not have downloadCsvParam, check if we have it in local storage
      const jobId = localStorage.getItem("requestCsv");
      if (jobId) {
        setRequestCsvJob(+jobId);
      }
    }
  }, [location]);

  return {
    downloadCsv,
    markDownloadCsvComplete,
    setRequestCsvJob: handleSetRequestCsvJob,
    requestCsvJob,
  };
};
