import {
  Box,
  Flex,
  Image,
  SlideFade,
  Stack,
  Text,
  useBreakpointValue,
  useToken,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { performHapticFeedback } from "../../../Api/Utils";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../../State/GlobalContext";
import { ShellContext } from "../../../State/ShellContext";
import {
  Border_Radius,
  SlideFade_Offset,
} from "../../../Styles/HeadversityStyle";
import { isWebPlatform } from "../../../Utils/mobileUtils";
import {
  getDescriptionFromLevel,
  getLevelFromPoints,
  getNameFromLevel,
  getPointsForLevel,
  getTotalPoints,
} from "../../../Utils/SkillsUtil";
import { Step } from "../../Common/Stepper/Step";
import { WelcomeModalHeader } from "../../Shared/Welcome/WelcomeModalHeader";
import { WelcomeModalSubheader } from "../../Shared/Welcome/WelcomeModalSubheader";

interface PointsMapProps {
  fromNewLevel?: boolean;
}
export const PointsMap = ({ fromNewLevel }: PointsMapProps) => {
  const { userSkillStats } = useContext(GlobalContext);
  const { safeAreaInsets } = useContext(ShellContext);
  const [showMap, setShowMap] = useState(false);
  const [showFullMap, setShowFullMap] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [fourthTextColor] = useToken("colors", ["FOURTH_TEXT_COLOR"]);

  useEffect(() => {
    const t = setTimeout(() => {
      setShowMap(true);

      if (level > 2) {
        setTimeout(() => {
          if (isDesktop) {
            ref.current?.scrollTo({
              left: level >= 9 ? 99999 : ref.current?.scrollWidth - 1111,
              behavior: "smooth",
            });
          } else {
            ref2.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 1000);
      }
    }, 1500);

    return () => clearTimeout(t);
  }, [isDesktop]);

  useEffect(() => {
    if (fromNewLevel) {
      performHapticFeedback();
    }
  }, [fromNewLevel]);

  const totalPoints = getTotalPoints(userSkillStats);
  const level = getLevelFromPoints(totalPoints);
  const pointsToNextLevel = getPointsForLevel(level + 1) - totalPoints;

  let levelsToShow: number[] = [];
  if (showFullMap) {
    for (let i = 1; i <= (level > 10 ? level : 10); i++) {
      levelsToShow.push(i);
    }
  } else {
    for (let i = 1; i <= Math.max(3, level + 1); i++) {
      levelsToShow.push(i);
    }

    if (level + 1 < 9) {
      levelsToShow.push(-1);
    }
    if (level + 1 < 10) {
      levelsToShow.push(10);
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDesktop) return;

    const interval = setInterval(() => {
      const x = window.innerHeight;
      const msg = document.querySelector(".points-message");
      const bar = document.querySelector(".bottom-action-bar");

      if (msg && bar) {
        const t1 = msg.getBoundingClientRect().height;
        const t2 = bar.getBoundingClientRect().height;

        const h = x - t1 - t2 - safeAreaInsets.bottom - 115; // 115px is area above the message

        ref.current?.setAttribute("style", `height: ${h}px`);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isDesktop]);

  const showFirstTimeMsg = totalPoints <= 3;

  return (
    <Box
      px={{ base: undefined, lg: "20px" }}
      mt={isWebPlatform() ? "10px" : "40px"}
    >
      <Box className="points-message">
        <WelcomeModalHeader>
          {fromNewLevel
            ? HVLocalizeStrings.LEVEL_LEVELED_UP
            : HVLocalizeStrings.LEVEL_ENCOURAGE}
        </WelcomeModalHeader>
        <WelcomeModalSubheader>
          {showFirstTimeMsg ? (
            <Text
              fontSize={isDesktop ? undefined : "md"}
              dangerouslySetInnerHTML={{
                __html: HVLocalizeStrings.LEVEL_FIRST_TIME,
              }}
            />
          ) : (
            <>
              {HVLocalizeStrings.LEVEL_NOW_AT}{" "}
              <b style={{ color: fourthTextColor }}>
                {" "}
                {HVLocalizeStrings.LEVEL} {level}: {getNameFromLevel(level)}
              </b>
              .{" "}
              {level < 10 && (
                <Text
                  as="span"
                  dangerouslySetInnerHTML={{
                    __html: HVLocalizeStrings.LEVEL_KEEP_GOING,
                  }}
                />
              )}
            </>
          )}
        </WelcomeModalSubheader>
      </Box>

      <SlideFade in={showMap} offsetY={SlideFade_Offset}>
        <Box
          bg="#ffffff22"
          borderRadius={Border_Radius}
          mt="20px"
          h={"340px"} // on mobile will get reset based on screen height
          w={{ base: "100%", lg: "875px" }}
          overflowX={isDesktop ? "scroll" : undefined}
          overflowY={!isDesktop ? "scroll" : undefined}
          ref={ref}
        >
          <Stack
            color="white"
            mt={{ base: "0px", lg: "30px" }}
            pt={{ base: "30px", lg: "0px" }}
            spacing="0"
            direction={{
              base: "column",
              lg: "row",
            }}
            mx={{ base: "40px", lg: "30px" }}
          >
            {levelsToShow.map((item) =>
              item === -1 ? (
                <Box
                  key={item}
                  w={{ base: "41px", lg: "50px" }}
                  top={{ base: undefined, lg: "-30px" }}
                  textAlign={"center"}
                  transform={{ base: "rotate(90deg)", lg: "none" }}
                  p="10px"
                  as="button"
                  onClick={() => {
                    setShowFullMap(true);
                  }}
                  alignItems="flex-start"
                  display="inline-flex"
                >
                  <Box w="30px" mt={{ base: "10px", lg: "-14px" }}>
                    {"..."}
                  </Box>
                </Box>
              ) : (
                <>
                  {item === level + 1 && (
                    <Box
                      key={item + "x"}
                      pos="relative"
                      top={{ base: "5px", lg: "-12px" }}
                      left={{ base: "-18px", lg: "0px" }}
                      textAlign={"center"}
                      p="10px"
                      fontSize="12px"
                      lineHeight={1.2}
                    >
                      <Text w="45px" h="45px">
                        <Text
                          as="span"
                          dangerouslySetInnerHTML={{
                            __html: HVLocalizeStrings.LEVEL_TO_GO_PRE,
                          }}
                        />
                        <b style={{ color: fourthTextColor }}>
                          {pointsToNextLevel} XP
                        </b>{" "}
                        <br />
                        {HVLocalizeStrings.LEVEL_TO_GO_POST}
                      </Text>
                    </Box>
                  )}
                  <Step
                    key={item}
                    showCompleteAsCircle={true}
                    description={
                      <Flex
                        alignItems="center"
                        direction={{
                          base: "row",
                          lg: "column",
                        }}
                        mt={{
                          base: "-3px",
                          lg: "5px",
                        }}
                        w={{ base: "250px", lg: "225px" }}
                        opacity={item > level ? 0.8 : 1}
                        ref={level === item ? ref2 : undefined}
                      >
                        <Flex
                          direction="column"
                          textAlign={"center"}
                          w="100%"
                          fontWeight={level === item ? "500" : "300"}
                        >
                          <Text
                            mx={2}
                            mb={0}
                            fontSize={level === item ? "15px" : "13px"}
                            lineHeight="1.2"
                            w={{ base: undefined, lg: "calc(100% - 18px)" }}
                          >
                            {HVLocalizeStrings.LEVEL} {item}:{" "}
                            {getNameFromLevel(item)}
                          </Text>
                          <Text
                            mt={1}
                            mx={2}
                            fontSize={level === item ? "15px" : "13px"}
                            w={{ base: undefined, lg: "calc(100% - 18px)" }}
                          >
                            {level === item ? (
                              <b style={{ fontWeight: 600 }}>
                                {totalPoints} XP
                              </b>
                            ) : (
                              <>{getPointsForLevel(item)} XP</>
                            )}{" "}
                          </Text>
                          <Image
                            src={`https://cdn.headversity.com/app/resources/levels/level${Math.min(
                              10,
                              item
                            )}.png`}
                            h={level === item ? "100px" : "80px"}
                            w={level === item ? "100px" : "80px"}
                            mx="auto"
                            my={2}
                            filter={
                              level === item
                                ? "brightness(1.05)"
                                : "grayscale(1)"
                            }
                          />
                          <Text
                            fontSize={level === item ? "15px" : "14px"}
                            lineHeight="1.2"
                            textAlign={"center"}
                            mt={2}
                            mx={1}
                          >
                            {getDescriptionFromLevel(item)}
                          </Text>
                        </Flex>
                      </Flex>
                    }
                    isComplete={level !== item}
                    isChecked={level >= item}
                    isNotChecked={level < item}
                    isAllComplete={false}
                    isActive={false}
                    isFirst={item === 1}
                    isFirstStep={item === 1}
                    isLast={item === Math.max(level + 1, 10)}
                    isLastStep={item === Math.max(level + 1, 10)}
                  />
                </>
              )
            )}
          </Stack>
        </Box>
      </SlideFade>
    </Box>
  );
};
