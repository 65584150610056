import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  DESTRUCTIVE_TEXT_COLOR,
  MODAL_PRIMARY_TEXT_COLOR,
  MODAL_THIRD_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HvSelect } from "../../../Common/HvSelect";
import {
  EligibilityImportType,
  ReachUsersContext,
} from "../../../../State/Reach/ReachUsersContext";
import { useContext } from "react";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { UserRole } from "@headversity/contract";

interface StepUploadTypeProps {
  importType: EligibilityImportType;
  setImportType: (importType: EligibilityImportType) => void;
}

const getTemplateUrl = (
  importType: EligibilityImportType,
  hierarchy: boolean
) => {
  const templateFolder = "eligibility_template/";
  const changeTemplateFilename = "headversity_eligibility_template_change_file";
  const allEligibleUsersTemplateFilename =
    "headversity_eligibility_template_all_eligible_users";
  if (!hierarchy) {
    return `${process.env.REACT_APP_CLOUD_FRONT_URL}${templateFolder}${
      importType === EligibilityImportType.CHANGE_FILE
        ? `${changeTemplateFilename}.csv`
        : `${allEligibleUsersTemplateFilename}.csv`
    }`;
  }
  return `${process.env.REACT_APP_CLOUD_FRONT_URL}${templateFolder}${
    importType === EligibilityImportType.CHANGE_FILE
      ? `${changeTemplateFilename}_h.csv`
      : `${allEligibleUsersTemplateFilename}_h.csv`
  }`;
};

const StepUploadType = ({ importType, setImportType }: StepUploadTypeProps) => {
  const { companyCerts } = useContext(ReachUsersContext);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const getChangeFileDescription = (hierarchy: boolean) => {
    let optionalDescription = "";
    if (companyCerts.length > 0) {
      optionalDescription =
        HVLocalizeStrings.REACH_USERS_CHANGE_FILE_CERT_DESCRIPTION;
    }
    if (hierarchy) {
      optionalDescription +=
        HVLocalizeStrings.REACH_USERS_CHANGE_FILE_HIERARCHY_DESCRIPTION;
    }
    return HVLocalizeStrings.REACH_USERS_CHANGE_FILE_DESCRIPTION.replace(
      "{0}",
      optionalDescription
    );
  };

  const { userHierarchyAssociations, self } =
    useContext<IGlobalProvider>(GlobalContext);
  const hierarchyEnabled = !!userHierarchyAssociations?.hierarchyId;
  const templateUrl = getTemplateUrl(importType, hierarchyEnabled);

  return (
    <>
      <Text fontSize={"xl"} fontWeight={"semibold"}>
        {HVLocalizeStrings.REACH_USERS_UPLOAD_STEP_1_TITLE}
      </Text>
      <Text fontSize={"md"}>
        {HVLocalizeStrings.REACH_USERS_UPLOAD_INSTRUCTIONS_1}
      </Text>
      <Text fontSize={"md"}>
        {HVLocalizeStrings.REACH_USERS_UPLOAD_INSTRUCTIONS_2}
      </Text>

      <Divider my={2} />

      <Text fontSize={"xl"} fontWeight={"semibold"}>
        {`${HVLocalizeStrings.REACH_USERS_IMPORT_TYPE}:`}
      </Text>
      <HvSelect
        value={importType}
        onChange={(event) => {
          setImportType(+event.target.value);
        }}
        w={isMobile ? "90%" : "460px"}
        data-testid={HVTestId.StepUploadType.select}
      >
        <option
          key={EligibilityImportType.CHANGE_FILE}
          value={EligibilityImportType.CHANGE_FILE}
        >
          {HVLocalizeStrings.REACH_USERS_CHANGE_FILE}
        </option>
        <option
          key={EligibilityImportType.FULL_LIST}
          value={EligibilityImportType.FULL_LIST}
        >
          {HVLocalizeStrings.REACH_USERS_BULK_FULL_LIST}
        </option>
      </HvSelect>
      {self &&
        self.role <= UserRole.HVAdmin &&
        importType === EligibilityImportType.CHANGE_FILE && (
          <Text fontSize={"md"} color={DESTRUCTIVE_TEXT_COLOR}>
            <strong>
              {HVLocalizeStrings.REACH_USERS_CROSS_COMPANY_UPLOAD}
            </strong>
          </Text>
        )}
      <Text fontSize={"md"} color={MODAL_THIRD_TEXT_COLOR}>
        <Link
          fontSize={"md"}
          textDecoration={"underline"}
          download={templateUrl}
          target="_blank"
          href={templateUrl}
        >
          {HVLocalizeStrings.REACH_USERS_DOWNLOAD_TEMPLATE}
        </Link>
      </Text>
      {importType === EligibilityImportType.FULL_LIST && (
        <Flex gap={3}>
          <Text fontSize="sm" color={MODAL_PRIMARY_TEXT_COLOR}>
            {HVLocalizeStrings.REACH_USERS_FULL_LIST_DESCRIPTION}
            <Text
              as={"span"}
              color={DESTRUCTIVE_TEXT_COLOR}
              ms={2}
              fontWeight={"semibold"}
            >
              {HVLocalizeStrings.REACH_USERS_FULL_LIST_DELETE_WARNING}
            </Text>
          </Text>
        </Flex>
      )}
      {importType === EligibilityImportType.CHANGE_FILE && (
        <Accordion allowToggle>
          <AccordionItem border={"none"}>
            {({ isExpanded }) => (
              <>
                <Heading>
                  <AccordionButton pl={0} width={"fit-content"}>
                    <Box as="span" textAlign="left">
                      {isExpanded
                        ? HVLocalizeStrings.REACH_USERS_HIDE_DETAILS
                        : HVLocalizeStrings.REACH_USERS_SHOW_DETAILS}
                    </Box>
                    <AccordionIcon ms={2} />
                  </AccordionButton>
                </Heading>
                <AccordionPanel pb={4}>
                  <Text
                    fontSize="sm"
                    color={MODAL_PRIMARY_TEXT_COLOR}
                    dangerouslySetInnerHTML={{
                      __html: getChangeFileDescription(hierarchyEnabled),
                    }}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};

export default StepUploadType;
