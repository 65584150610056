import {
  Box,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  PathTeamLessonDto,
  PollQuestionDto,
  QuestionUserResponseDto,
  ResponseState,
  TeamLessonUserInstanceParticipantDto,
  QuestionSettings,
  CharterActivity,
} from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import {
  IGlobalProvider,
  GlobalContext,
} from "../../../../State/GlobalContext";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
  Mid_Blue,
} from "../../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";

import { ScrollPanel } from "../../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../../Common/ScrollPanelBlock";
import { PresentationContent } from "../Shared/PresentationContent";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { CharterDisplay } from "../CharterEditor/CharterDisplay";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { getLatestPollInstanceResultByPollQuestionId } from "../../../../Utils/TeamUtil";
import {
  QuestionUserResponseEditor,
  QuestionUserResponseEditorIconState,
} from "../Shared/QuestionUserResponseEditor";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { CharterTimer } from "../Shared/CharterTimer";
import { DiscussIcon } from "../Shared/DiscussText";
import { parseJSON } from "../../../../Utils/Helpers";
import { weStatementLength } from "../Shared/CharterUtil";

interface PresentationScenarioProps {
  pathTeamLesson: PathTeamLessonDto | undefined;
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationScenario = ({
  pathTeamLesson,
  setActivity,
}: PresentationScenarioProps) => {
  const {
    currentTeamLessonUserInstanceId,
    currentParticipantList,
    createPollInstancesOnServer,
    pollQuestions,
    pollInstances,
    updatePollUserResponsesToServer,
    pollInstanceResults,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [scenarioPollQuestions, setScenarioPollQuestions] =
    useState<PollQuestionDto[]>();
  const [groupNames, setGroupNames] = useState<string[]>();
  const [groupPollQuestions, setGroupPollQuestions] =
    useState<PollQuestionDto[]>();
  const [allPollResponseReceived, setAllPollResponseReceived] =
    useState<boolean>(false);
  const [reporterPollInstanceResults, setReporterPollInstanceResults] =
    useState<QuestionUserResponseDto[]>();
  const [showCustomizeCharter, setShowCustomizeCharter] =
    useState<boolean>(false);
  const [enableContinueButton, setEnableContinueButton] =
    useState<boolean>(false);
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [statistic, setStatistic] = useState<string>("");
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [currentPollQuestion, setCurrentPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const {
    currentPollInstanceId,
    activePollQuestionResults: weStatementPollResult,
  } = usePollQuestionInformation({
    pollQuestion: currentPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const handleEditedPollResults = async (
    editedPollResults: {
      id: number;
      responseText: string;
      state: ResponseState;
    }[]
  ) => {
    await updatePollUserResponsesToServer(
      currentPollInstanceId!,
      editedPollResults.map((e) => {
        return {
          responseText: e.responseText,
          state: e.state,
          questionUserResponseId: e.id,
        };
      })
    );
  };

  const getWeStatementText = (weSatementId: number) => {
    return (
      weStatementPollResult?.find((r) => r.id === weSatementId)?.responseText ||
      ""
    );
  };

  useEffect(() => {
    if (pollQuestions && pollQuestions.length > 0) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      if (pollQuestion) {
        setCurrentPollQuestion(pollQuestion);
      }
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (currentParticipantList) {
      const names = Array.from(
        new Set(
          currentParticipantList.map(
            (x: TeamLessonUserInstanceParticipantDto) => x.groupName!
          )
        )
      );
      setGroupNames(names);
    }
  }, [currentParticipantList]);

  useEffect(() => {
    if (pollQuestions) {
      const filteredPollQuestions = pollQuestions.filter((pq) =>
        pq.internalTitle?.startsWith("SCENARIO_")
      );
      setScenarioPollQuestions(filteredPollQuestions);
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (groupPollQuestions && groupPollQuestions.length > 0) {
      return;
    }
    if (pathTeamLesson?.inProgressStep === CharterActivity.ScenarioReviews) {
      setLoading(false);
    }
    if (
      (pathTeamLesson?.inProgressStep === CharterActivity.Scenarios ||
        pathTeamLesson?.inProgressStep === CharterActivity.ScenarioReviews) &&
      scenarioPollQuestions &&
      groupNames &&
      pollInstances
    ) {
      const pollQuestionUserInstances = pollInstances.filter(
        (pi) =>
          pi.pollQuestion.internalTitle?.startsWith("SCENARIO_") &&
          pi.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
      );

      if (pollQuestionUserInstances.length > 0) {
        setGroupPollQuestions(
          pollQuestionUserInstances.map((p) => {
            return { id: p.pollQuestionId, group: p.group } as PollQuestionDto;
          })
        );
      } else {
        const selectedQuestions = new Set<PollQuestionDto>();

        groupNames.forEach(() => {
          const availableQuestions = scenarioPollQuestions.filter(
            (q) => !selectedQuestions.has(q)
          );
          if (availableQuestions.length > 0) {
            const randomIndex = Math.floor(
              Math.random() * availableQuestions.length
            );
            const selectedQuestion = availableQuestions[randomIndex];
            selectedQuestions.add(selectedQuestion);
          }
        });

        const clonedPollQuestions = Array.from(selectedQuestions).map(
          (question, index) => ({
            id: question.id,
            group: groupNames[index],
          })
        ) as PollQuestionDto[];
        setGroupPollQuestions(clonedPollQuestions);
      }
    }
  }, [
    pathTeamLesson,
    groupNames,
    scenarioPollQuestions,
    pollInstances,
    groupPollQuestions,
  ]);

  useEffect(() => {
    if (
      groupPollQuestions &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      groupPollQuestions.length > 0 &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const scenarioPollInstancesExist = pollInstances.some(
        (pi) =>
          pi.pollQuestion.internalTitle?.startsWith("SCENARIO_") &&
          pi.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
      );
      if (!scenarioPollInstancesExist) {
        createPollInstancesOnServer(
          groupPollQuestions,
          currentTeamLessonUserInstanceId
        );
      }
    }
  }, [
    groupPollQuestions,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (
      currentTeamLessonUserInstanceId &&
      pathTeamLesson?.inProgressStep !== CharterActivity.Scenarios &&
      pathTeamLesson?.inProgressStep !== CharterActivity.ScenarioReviews
    ) {
      setTeamLessonInProgressStepToServer(
        currentTeamLessonUserInstanceId,
        CharterActivity.Scenarios
      );
    }
  }, [pathTeamLesson, currentTeamLessonUserInstanceId]);

  useEffect(() => {
    if (
      currentParticipantList &&
      groupPollQuestions &&
      currentTeamLessonUserInstanceId &&
      pollInstanceResults &&
      pollInstances
    ) {
      let groupPollInstanceResults = [];
      for (const groupPollQuestion of groupPollQuestions) {
        const groupResult = getLatestPollInstanceResultByPollQuestionId(
          groupPollQuestion.id,
          pollInstances,
          currentTeamLessonUserInstanceId,
          pollInstanceResults
        );
        if (groupResult && groupResult.length > 0) {
          groupPollInstanceResults.push(groupResult![0]);
        }
      }
      const total = groupPollQuestions.length;
      setStatistic(groupPollInstanceResults.length + " / " + total);
      setReporterPollInstanceResults(groupPollInstanceResults);
      if (groupPollInstanceResults.length === total) {
        setAllPollResponseReceived(true);
      }
    }
  }, [
    currentParticipantList,
    groupPollQuestions,
    pollInstances,
    pollInstanceResults,
    currentTeamLessonUserInstanceId,
  ]);

  useEffect(() => {
    if (
      pathTeamLesson?.inProgressStep === CharterActivity.ScenarioReviews &&
      reporterPollInstanceResults &&
      reporterPollInstanceResults.length === 1
    ) {
      setEnableContinueButton(true);
    }
  }, [reporterPollInstanceResults, pathTeamLesson]);

  let content;
  if (showCustomizeCharter && currentTeamLessonUserInstanceId) {
    content = (
      <>
        <PresentationContent hr={false}>
          <Tabs>
            <TabList>
              <Tab>{HVSportLocalizeStrings.CHARTER_Title}</Tab>
              <Tab>{HVSportLocalizeStrings.CHARTER_WE_STATEMENTS}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel h={"560px"}>
                <CharterDisplay
                  teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
                  isEditor={false}
                />
              </TabPanel>
              <TabPanel>
                <QuestionUserResponseEditor
                  editedPollResults={
                    weStatementPollResult as {
                      id: number;
                      responseText: string;
                      state: ResponseState;
                    }[]
                  }
                  setEditedPollResults={handleEditedPollResults}
                  iconState={QuestionUserResponseEditorIconState.editOnly}
                  maxLength={weStatementLength}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PresentationContent>
        <AnimatedButton
          text={HVLocalizeStrings.BACK}
          colorSet={AnimatedButtonColorSet.Primary}
          w={219}
          onClick={() => {
            setShowCustomizeCharter(false);
          }}
        ></AnimatedButton>
      </>
    );
  } else if (
    pathTeamLesson?.inProgressStep === CharterActivity.ScenarioReviews
  ) {
    const paddingForQuestionText = isMobile ? undefined : "30px";
    content = (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={5}
        p={"3"}
        pt={"0"}
      >
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={HVSportLocalizeStrings.CHARTER_SCENARIO_TITLE}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
        <Flex
          w={"100%"}
          flexDir={"column"}
          align={"center"}
          justify={"start"}
          bg={"white"}
          ml={5}
        >
          <Text w={"100%"} textAlign={"start"}>
            <Flex flexDir={"row"} gap={"2"} ml={5}>
              <DiscussIcon />
              <Flex>
                {HVSportLocalizeStrings.CHARTER_SCENARIO_INSTRUCTION_ONE}{" "}
                {HVSportLocalizeStrings.CHARTER_SCENARIO_INSTRUCTION_TWO}
              </Flex>
            </Flex>
          </Text>
        </Flex>
        <Box maxW={"1000px"}>
          <ScrollPanel
            onSlideChangeCallBack={(number) => {
              setCurrentSlideIndex(number);
              if (
                reporterPollInstanceResults &&
                number === reporterPollInstanceResults.length - 1
              ) {
                setEnableContinueButton(true);
              }
            }}
            currentIndex={currentSlideIndex}
            spaceBetween={8}
            showChevronContainer={true}
            slidesPerGroup={1}
            slidesPerView={"auto"}
            isPaginationOnContainer={true}
          >
            {reporterPollInstanceResults?.map((item) => {
              let questionId = item.questionId;
              let scenarioPollQuestion = scenarioPollQuestions!.find(
                (question) => question.questionId === questionId
              )!;
              const settings = scenarioPollQuestion.question
                .settings as QuestionSettings;
              return ScrollPanelBlock(
                <Flex
                  w={"100%"}
                  px={paddingForQuestionText}
                  flexDir={"column"}
                  align={"center"}
                  justify={"start"}
                  py={isMobile ? undefined : "10"}
                  borderRadius={Border_Radius}
                  bg={"white"}
                  boxShadow={"lg"}
                  minH={"480px"}
                >
                  <Text
                    px={paddingForQuestionText}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    <b>
                      {HVSportLocalizeStrings.CHARTER_SCENARIO_LABEL +
                        settings.title!.en!}
                    </b>
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={1}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    {scenarioPollQuestion.question.questionText as string}
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={5}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    <b>
                      {HVSportLocalizeStrings.CHARTER_SCENARIO_DOES_IT_VIOLATE}
                    </b>
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={1}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    {parseJSON(item.responseText).isViolation === "1"
                      ? "Yes"
                      : "No"}
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={5}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    <b>
                      {
                        HVSportLocalizeStrings.CHARTER_SCENARIO_QUESTION_INSTRUCTION_TWO
                      }
                    </b>
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={1}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    {parseJSON(item.responseText).reason!}
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={5}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    <b>
                      {
                        HVSportLocalizeStrings.CHARTER_SCENARIO_QUESTION_CHOOSE_WE
                      }
                    </b>
                  </Text>
                  <Text
                    px={paddingForQuestionText}
                    mt={1}
                    w={"100%"}
                    textAlign={"start"}
                  >
                    {getWeStatementText(
                      Number(parseJSON(item.responseText).weStatement)
                    )}
                  </Text>
                </Flex>,
                "1000px",
                item.id
              );
            })}
          </ScrollPanel>
        </Box>
        <Link
          w={300}
          textAlign="center"
          display="block"
          color={Mid_Blue}
          cursor={"pointer"}
          onClick={() => {
            setShowCustomizeCharter(true);
          }}
        >
          {HVSportLocalizeStrings.CHARTER_SCENARIO_VIEW_CHARTER}
        </Link>
        <AnimatedButton
          colorSet={AnimatedButtonColorSet.Primary}
          text={HVLocalizeStrings.CONTINUE}
          w={300}
          disabled={!enableContinueButton}
          onClick={async () => {
            if (currentTeamLessonUserInstanceId) {
              setLoading(true);
              await setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                ""
              );
              setActivity(undefined);
            }
          }}
        ></AnimatedButton>
      </Flex>
    );
  } else {
    content = (
      <>
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
          description={
            <Flex flexDir={"column"} gap={"5"}>
              <Text>
                {HVSportLocalizeStrings.CHARTER_SCENARIO_INSTRUCTION_THREE}
              </Text>
              <Text>
                {HVSportLocalizeStrings.CHARTER_SCENARIO_INSTRUCTION_FOUR}
              </Text>
            </Flex>
          }
          listItems={[
            {
              icon: "https://cdn.headversity.com/app/sport/group-reporter-device.svg",
              text: HVSportLocalizeStrings.CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE,
            },
            {
              icon: "https://cdn.headversity.com/app/sport/discussion.svg",
              text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={currentPollQuestion}
            onTimeChange={(time) => {
              if (
                time <= 240 &&
                (reporterPollInstanceResults?.length ?? 0) > 0
              ) {
                setAllPollResponseReceived(true);
              }
            }}
          />
        </PresentationContent>
        <Flex flexDir={"column"} align={"center"}>
          <Text color={Black} fontWeight={"semibold"}>
            {HVSportLocalizeStrings.CHARTER_STATISTICS}
          </Text>
          <Text color={Black} fontWeight={"semibold"} fontSize={"xl"}>
            {statistic}
          </Text>
        </Flex>
        <AnimatedButton
          text={HVLocalizeStrings.CONTINUE}
          colorSet={AnimatedButtonColorSet.Primary}
          w={219}
          onClick={() => {
            if (currentTeamLessonUserInstanceId) {
              setLoading(true);
              setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                CharterActivity.ScenarioReviews
              );
            }
          }}
          disabled={!allPollResponseReceived || loading}
        ></AnimatedButton>
      </>
    );
  }

  return (
    <PresentationContainer>
      {pathTeamLesson?.inProgressStep === CharterActivity.ScenarioReviews ? (
        <>
          <Box
            width={isMobile ? "100%" : "900px"}
            position={"relative"}
            top={"-12"}
            zIndex={"2"}
            overflow={"visible"}
          >
            <CharterTimer
              pollQuestion={currentPollQuestion}
              onTimeChange={(time) => {
                if (
                  time <= 240 &&
                  (reporterPollInstanceResults?.length ?? 0) > 0
                ) {
                  setAllPollResponseReceived(true);
                }
              }}
            />
          </Box>
          {content}
        </>
      ) : (
        <> {content}</>
      )}
    </PresentationContainer>
  );
};
