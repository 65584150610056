import { useContext, useRef, useState } from "react";
import { Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { LargeModal } from "../../Common/LargeModal";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { HvTextInput } from "../../Common/HvTextInput";
import { HvSelect } from "../../Common/HvSelect";
import { HVTestId } from "../../../Testing/dataTestIds";
import { Link } from "react-router-dom";
import { TeamDto } from "@headversity/contract";
import { ShellContext } from "../../../State/ShellContext";

interface Props {
  open: boolean;
  close: () => void;
  type: "add" | "select" | "edit" | "join" | null;
  teamId?: number;
  teamName?: string;
  dataTestId?: string;
}

const WithLargeModal = (props: {
  open: boolean;
  close: () => void;
  children: JSX.Element[];
  dataTestId?: string;
}) => {
  const { open, close, children, dataTestId } = props;
  return (
    <LargeModal
      open={open}
      setOpen={(val: boolean) => {
        close();
      }}
      dataTestId={dataTestId}
    >
      <Flex
        flexDir={"column"}
        gap={10}
        alignItems={"center"}
        mb={40}
        color={MODAL_PRIMARY_TEXT_COLOR}
      >
        {children}
      </Flex>
    </LargeModal>
  );
};

export const TeamCommandModals = ({
  open,
  close,
  type,
  teamId,
  teamName,
}: Props) => {
  const {
    teams,
    setCurrentTeam,
    currentTeam,
    saveTeamToServer,
    teamDataLoading,
    joinTeamToServer,
    self,
    getPollInstancesFromServer,
    getTeamsFromServer,
  } = useContext<IGlobalProvider>(GlobalContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const { showToast } = useContext(ShellContext);

  const [disabled, setDisabled] = useState<boolean>(true);

  const handleSelectTeamClick = () => {
    const items = teams?.filter(
      (item: TeamDto) =>
        inputRef?.current && item.id.toString() == inputRef?.current.value
    );
    if (items.length > 0) {
      track(TEAM_EVENTS.ChangeSelectedTeam, {
        HV_Team_Id: items[0].id,
        HV_Team_Name: items[0].name,
      });
      setCurrentTeam(items[0]);
      close();
    }
  };

  const handleAddTeam = () => {
    if (inputRef?.current) {
      setDisabled(true);
      track(TEAM_EVENTS.CreateTeam, {
        HV_Team_Name: inputRef.current.value,
      });
      saveTeamToServer(inputRef.current.value).then(
        (teams: TeamDto[]) => {
          // select the newly created team
          const team = teams?.find(
            (item) => item.name == inputRef?.current?.value
          );

          if (team) {
            setCurrentTeam(team);
          }

          showToast(
            true,
            "team-create-success",
            HVLocalizeStrings.TEAM_CREATED_SUCCESS
          );
          close();
        },
        () => {
          close();
        }
      );
    }
  };

  const handleEditTeamName = () => {
    setDisabled(true);
    track(TEAM_EVENTS.EditTeamName, {
      HV_Team_Id: teamId,
      HV_Team_Name: inputRef?.current?.value,
    });
    saveTeamToServer(inputRef?.current?.value as string, teamId).then(() => {
      getTeamsFromServer();
      showToast(
        true,
        "team-create-success",
        HVLocalizeStrings.TEAM_NAME_UPDATED_SUCCESS
      );
      close();
    });
  };

  const handleJoinNewTeam = () => {
    setDisabled(true);
    const inputCode = inputRef?.current?.value.replace(/[^A-Za-z0-9]/g, "");
    const teamToJoin = teams?.filter((item) => item.code == inputCode);
    const isUserInTeam =
      teamToJoin[0]?.teamUsers?.some((item: any) => item?.userId == self?.id) ??
      false;
    if (!isUserInTeam) {
      track(TEAM_EVENTS.JoinTeam, {
        HV_TeamCode: inputCode,
      });
      joinTeamToServer(inputCode as string).then(
        () => {
          showToast(
            true,
            "team-join-success",
            HVLocalizeStrings.TEAM_JOIN_SUCCESS_DESCRIPTION
          );
          getPollInstancesFromServer();
          close();
        },
        (error: string | undefined) => {
          showToast(false, "team-join-error", error || "");
          close();
        }
      );
    } else {
      showToast(
        true,
        "join-already-on-team",
        HVLocalizeStrings.TEAM_JOIN_ALREADY_JOINED_DESCRIPTION
      );
      setCurrentTeam(teamToJoin[0]);
      close();
    }
  };

  const inputChanged = (e: { currentTarget: { value: string } }) => {
    setDisabled(
      e.currentTarget.value?.replace(/[^A-Za-z0-9]/g, "").trim() === ""
    );
  };

  switch (type) {
    case "add":
      return (
        <WithLargeModal open={open} close={close}>
          <Flex justifyContent={"center"} w={"100%"}>
            <BlockSectionTitle
              title={HVLocalizeStrings.TEAM_CREATE_A_TEAM}
            ></BlockSectionTitle>
          </Flex>
          <Text>{HVLocalizeStrings.TEAM_CREATE_NEW_TEAM_DESCRIPTION}</Text>
          <HvTextInput
            maxW={"70%"}
            placeholder={HVLocalizeStrings.TEAM_TEAM_NAME}
            ref={inputRef}
            onEnterKeyDown={handleAddTeam}
            onChange={inputChanged}
            dataTestId={HVTestId.TeamCommandModals.newTeamName}
          />
          {teamDataLoading ? (
            <Spinner />
          ) : (
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Secondary}
              w={250}
              text={HVLocalizeStrings.SAVE}
              disabled={disabled}
              onClick={handleAddTeam}
              dataTestId={HVTestId.TeamCommandModals.createNewTeam}
            />
          )}
        </WithLargeModal>
      );
    case "select":
      return (
        <WithLargeModal
          open={open}
          close={close}
          dataTestId={HVTestId.TeamCommandModals.selectTeamModal}
        >
          <Flex justifyContent={"center"} w={"100%"}>
            <BlockSectionTitle
              title={HVLocalizeStrings.TEAM_SWITCH_TEAM_VIEW}
            />
          </Flex>
          <Flex flexDirection={"column"} gap={2}>
            <Flex flexDir={"column"} gap={2}>
              <Text alignSelf={"center"}>
                {HVLocalizeStrings.TEAM_EDIT_CURRENT_TEAM_NAME}:
              </Text>
              <Text alignSelf={"center"} fontWeight={"semibold"}>
                {currentTeam?.name}
              </Text>
            </Flex>
            <Divider alignSelf={"center"} my={4} />
            <Text textAlign={"center"} maxW={"70%"} alignSelf={"center"}>
              {HVLocalizeStrings.TEAM_CHANGE_TEAM_BODY2}
            </Text>
          </Flex>
          <HvSelect
            maxW={"70%"}
            fontSize={"lg"}
            ref={inputRef}
            defaultValue={currentTeam?.id ? currentTeam?.id : 0}
            data-testid={HVTestId.TeamCommandModals.teamSelector}
          >
            {teams?.map(({ id, name }) => {
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
          </HvSelect>
          <AnimatedButton
            text={HVLocalizeStrings.SELECT}
            onClick={handleSelectTeamClick}
            colorSet={AnimatedButtonColorSet.Secondary}
            w={250}
            dataTestId={HVTestId.TeamCommandModals.submitButton}
          ></AnimatedButton>
        </WithLargeModal>
      );
    case "edit":
      return (
        <WithLargeModal open={open} close={close}>
          <Flex justifyContent={"center"} w={"100%"}>
            <BlockSectionTitle title={HVLocalizeStrings.TEAM_EDIT_TEAM_NAME} />
          </Flex>
          <Flex gap={2} flexDir={"column"}>
            <Text>{HVLocalizeStrings.TEAM_EDIT_CURRENT_TEAM_NAME}:</Text>
            <Text fontWeight={"semibold"} alignSelf={"center"}>
              {teamName}
            </Text>
          </Flex>
          <HvTextInput
            maxW={"70%"}
            placeholder={HVLocalizeStrings.TEAM_NEW_TEAM_NAME}
            ref={inputRef}
            onEnterKeyDown={handleEditTeamName}
            onChange={inputChanged}
            dataTestId={HVTestId.TeamCommandModals.updatedTeamName}
          />

          {teamDataLoading ? (
            <Spinner />
          ) : (
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Secondary}
              w={250}
              text={HVLocalizeStrings.UPDATE_BUTTON_TEXT}
              disabled={disabled}
              onClick={handleEditTeamName}
              dataTestId={HVTestId.TeamCommandModals.updateNameButton}
            />
          )}
        </WithLargeModal>
      );
    case "join":
      return (
        <>
          <WithLargeModal open={open} close={close}>
            <Flex justifyContent={"center"} w={"100%"}>
              <BlockSectionTitle title={HVLocalizeStrings.TEAM_JOIN_NEW_TEAM} />
            </Flex>

            <Text>
              {HVLocalizeStrings.TEAM_JOIN_NEW_TEAM_DESCRIPTION}{" "}
              <Link
                to={"/team/management"}
                style={{ textDecoration: "underline" }}
              >
                {HVLocalizeStrings.TEAM_CREATE_NEW_TEAM}
              </Link>
              {"."}
            </Text>
            <HvTextInput
              maxW={"70%"}
              placeholder={HVLocalizeStrings.TEAM_ENTER_NEW_CODE}
              ref={inputRef}
              onEnterKeyDown={handleJoinNewTeam}
              onChange={inputChanged}
              maxLength={6}
              dataTestId={HVTestId.TeamCommandModals.joinTeamID}
            />

            {teamDataLoading ? (
              <Spinner />
            ) : (
              <AnimatedButton
                colorSet={AnimatedButtonColorSet.Secondary}
                w={250}
                text={HVLocalizeStrings.TEAM_JOIN_NEW_TEAM}
                disabled={disabled}
                onClick={handleJoinNewTeam}
                dataTestId={HVTestId.TeamCommandModals.joinTeam}
              />
            )}
          </WithLargeModal>
        </>
      );
    case null:
      return <></>;
  }
};
