import { Textarea, TextareaProps } from "@chakra-ui/react";
import {
  INPUT_BACKGROUND_COLOR,
  INPUT_PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { forwardRef } from "react";

// Extend TextareaProps to include enterKeyHint for compatibility
interface HvTextareaProps extends TextareaProps {
  enterKeyHint?: "enter" | "done" | "go" | "next" | "send" | "search"; // Valid values for enterKeyHint
}

export const HvTextarea = forwardRef<HTMLTextAreaElement, HvTextareaProps>(
  ({ isDisabled, enterKeyHint, ...props }, ref) => {
    return (
      <Textarea
        ref={ref}
        bg={INPUT_BACKGROUND_COLOR}
        color={INPUT_PRIMARY_TEXT_COLOR}
        maxLength={props.maxLength ?? 1000}
        _hover={!isDisabled ? undefined : { bg: INPUT_BACKGROUND_COLOR }}
        _focus={!isDisabled ? undefined : { boxShadow: "none" }}
        isDisabled={isDisabled}
        enterKeyHint={enterKeyHint}
        {...props}
      />
    );
  }
);
