import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  Flex,
  Progress,
  Text,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  Black,
  Border_Radius,
  BUTTON_DISABLE_BACKGROUND_COLOR,
  Mid_Blue,
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  Success,
} from "../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { Loading } from "../../Common/Loading";
import { QuestionType } from "../../../Utils/PollsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  LocalizationText,
  PollQuestionDto,
  PollQuestionUserInstanceDto,
  QuestionAlternativeDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { PollResponseList, PollResponseListItem } from "./PollResponseList";
import { WordCloudChart } from "../../Sport/Charter/Shared/WordCloudChart";

interface PollResultProps {
  pollQuestion: PollQuestionDto | undefined;
  pollInstance: PollQuestionUserInstanceDto | undefined;
  barAlign?: string;
  hideVote?: boolean;
  showWaitingOnVotes: boolean;
  w?: string;
  gap?: string;
  boxShadow?: string;
  responseListPadding?: string;
  showWordCloud?: boolean;
}

export const PollResult = (props: PollResultProps) => {
  const {
    pollQuestion,
    pollInstance,
    barAlign,
    hideVote,
    showWaitingOnVotes,
    w,
    boxShadow,
    gap,
    responseListPadding,
    showWordCloud,
  } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  const { pollInstanceResults, getPollInstanceResultFromServer } =
    useContext<IGlobalProvider>(GlobalContext);

  const [pollInstanceResult, setPollInstanceResult] = useState<
    QuestionUserResponseDto[] | null
  >(null);

  const [pollResultCounts, setPollResultCounts] = useState<number[]>([]);
  const [questionAlternativesSorted, setQuestionAlternativesSorted] = useState<
    QuestionAlternativeDto[]
  >([]);

  const [highestCount, setHighestCount] = useState<number>(0);

  useEffect(() => {
    if (!pollInstance || !pollInstance.id) {
      return;
    }
    getPollInstanceResultFromServer(pollInstance.id);
  }, [pollInstance]);

  useEffect(() => {
    if (!pollInstance || !pollInstanceResults) {
      return;
    }
    const currentPollInstanceResult = pollInstanceResults[pollInstance.id];
    if (currentPollInstanceResult && currentPollInstanceResult?.length > 0) {
      setPollInstanceResult(currentPollInstanceResult);
      if (pollQuestion?.question.questionType === QuestionType.Single) {
        let highestCount = 0;
        let currentPollResultCounts: number[] = [];
        pollQuestion.question?.questionAlternatives.forEach((item) => {
          const currentResultCount = currentPollInstanceResult.filter(
            (result) => result.questionAlternativeId === item.id
          )?.length;
          currentPollResultCounts[item.id] = currentResultCount;
          if (currentResultCount > highestCount) {
            highestCount = currentResultCount;
          }
        });
        let newQuestionAlternativesSorted =
          pollQuestion.question?.questionAlternatives.sort(
            (a, b) =>
              currentPollResultCounts[b.id] - currentPollResultCounts[a.id]
          );
        setHighestCount(highestCount);
        setPollResultCounts(currentPollResultCounts);
        setQuestionAlternativesSorted(newQuestionAlternativesSorted);
      } else if (
        pollQuestion?.question.questionType === QuestionType.Headzone
      ) {
        /* FUTURE: re-implement headzone as generic "group nano-practice" */
      }
    } else {
      setPollInstanceResult(null);
    }
  }, [pollInstance, pollInstanceResults]);

  let content = (
    <>
      {showWaitingOnVotes && (
        <Flex
          minH={"150px"}
          flexDir={"column"}
          align={"center"}
          justify={"center"}
          gap={"5"}
          data-testid={HVTestId.PollResult.waitingVotes}
        >
          <Text color={MODAL_PRIMARY_TEXT_COLOR} textAlign={"center"}>
            {HVLocalizeStrings.TEAM_WAITING_FOR_VOTES}
          </Text>
          <Loading />
        </Flex>
      )}
    </>
  );
  if (pollInstanceResult && pollInstanceResult?.length > 0 && pollQuestion) {
    if (pollQuestion.question.questionType === QuestionType.Single) {
      content = (
        <>
          {questionAlternativesSorted?.map((item) => {
            return (
              <PollResultBar
                key={item.id}
                answer={item.alternativeText}
                count={pollResultCounts[item.id]}
                total={pollInstanceResult?.length}
                highestCount={highestCount}
              />
            );
          })}
        </>
      );
    } else if (pollQuestion.question.questionType === QuestionType.FreeText) {
      let width = isMobile ? "300px" : "70%";
      width = w ?? width;
      if (showWordCloud) {
        content = (
          <Box w={"100%"}>
            <WordCloudChart
              words={pollInstanceResult.map((item) => item.responseText)}
            ></WordCloudChart>
          </Box>
        );
      } else {
        content = (
          <PollResponseList
            w={width}
            boxShadow={boxShadow}
            p={responseListPadding}
          >
            {pollInstanceResult
              ?.sort(
                (a: QuestionUserResponseDto, b: QuestionUserResponseDto) =>
                  b.id - a.id
              )
              .map((item: any) => {
                return (
                  <>
                    <PollResponseListItem
                      data-testid={HVTestId.PollResult.freeTextAnswer}
                    >
                      <Text color={Black} fontSize={"16px"}>
                        {item.responseText}
                      </Text>
                    </PollResponseListItem>
                  </>
                );
              })}
          </PollResponseList>
        );
      }
    } else if (pollQuestion.question.questionType === QuestionType.Headzone) {
      content = (
        <>
          {pollInstanceResult?.length && (
            <Flex pos={"relative"}>
              {/* FUTURE: re-implement headzone as generic "group nano-practice" */}
            </Flex>
          )}
        </>
      );
    }
  }

  return (
    <Flex
      flexDir={"column"}
      gap={gap ?? "5"}
      align={barAlign ?? "center"}
      w={w ?? "70%"}
    >
      {content}
      {!hideVote && (
        <Flex
          flexDir={"column"}
          align={"center"}
          data-testid={HVTestId.PollResult.totalVotes}
        >
          <Text color={MODAL_PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
            {HVLocalizeStrings.TEAM_VOTES}
          </Text>
          <Text
            color={MODAL_PRIMARY_TEXT_COLOR}
            fontWeight={"semibold"}
            fontSize={"xl"}
          >
            {pollInstanceResult?.length ? pollInstanceResult?.length : 0}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

interface PollResultBarProps {
  answer: string | LocalizationText;
  count: number;
  total: number;
  highestCount: number;
}

const PollResultBar = (props: PollResultBarProps) => {
  const { answer, count, total, highestCount } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  return (
    <Flex
      flexDir={"column"}
      borderRadius={Border_Radius}
      boxShadow={"lg"}
      bg={"white"}
      w={"100%"}
      py={"5"}
      px={"5"}
      borderWidth={count === highestCount ? "1px" : "0"}
      borderColor={Success}
      ml={count === highestCount ? "5" : "0"}
      data-testid={HVTestId.PollResult.alternativeAnswer}
    >
      <Flex flexDir={"row"} w={"100%"} fontSize={isMobile ? "xs" : "lg"}>
        <Text
          fontWeight={"semibold"}
          color={highestCount === count ? Success : PRIMARY_TEXT_COLOR}
          flexBasis={isMobile ? "80%" : "90%"}
        >
          {answer as string}
        </Text>
        <Text
          fontWeight={"semibold"}
          color={highestCount === count ? Success : PRIMARY_TEXT_COLOR}
        >
          {Math.trunc((count / total) * 100)} %
        </Text>
      </Flex>
      <Progress
        max={100}
        mt={"2"}
        mb={"2"}
        h={"10px"}
        w={"100%"}
        borderRadius={50}
        bgColor={BUTTON_DISABLE_BACKGROUND_COLOR}
        sx={{
          "& > div": {
            background: highestCount === count ? Success : Mid_Blue,
            transition: "all 0.5s ease-in",
          },
        }}
        value={(count / total) * 100}
      />
    </Flex>
  );
};
