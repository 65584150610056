import {
  Box,
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Stack,
  Text,
  useEditableControls,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import {
  Black,
  Evening_Blue,
  Light_Blue,
  Mid_Blue,
} from "../../../Styles/HeadversityStyle";
import {
  PollResponseList,
  PollResponseListItem,
} from "../../Team/Polls/PollResponseList";
import { Loading } from "../../Common/Loading";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HvSpinner } from "../../Common/HvSpinner";
import { QuestionUserResponseEditorIconState } from "../../Sport/Charter/Shared/QuestionUserResponseEditor";
import { FiEdit, FiSave, FiTrash2, FiPlusCircle } from "react-icons/fi";
import { FaUndo } from "react-icons/fa";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { HvTextarea } from "../../Common/HvTextarea";
import { useEffect, useRef } from "react";

interface PollMultiFreeTextInputEditableControlProps {
  iconState?: QuestionUserResponseEditorIconState;
  deleteAndPutBackClick: () => void;
}
const PollMultiFreeTextInputEditableControl = (
  props: PollMultiFreeTextInputEditableControlProps
) => {
  const { isEditing, getSubmitButtonProps, getEditButtonProps } =
    useEditableControls();

  const size = "16px";
  const { deleteAndPutBackClick, iconState } = props;
  return (
    <>
      {isEditing ? (
        <IconButton
          aria-label={"save"}
          size={size}
          icon={<FiSave />}
          key={"SAVE"}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          {...getSubmitButtonProps()}
        />
      ) : (
        <IconButton
          aria-label={"edit"}
          size={size}
          icon={<FiEdit />}
          key={"EDIT"}
          bg={"transparent"}
          _hover={{
            bg: "transparent",
          }}
          {...getEditButtonProps()}
        />
      )}
      {iconState !== QuestionUserResponseEditorIconState.editOnly && (
        <IconButton
          pl={2}
          aria-label={"icon button"}
          size={size}
          icon={true ? <FiTrash2 /> : <FaUndo />}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          onClick={deleteAndPutBackClick}
        />
      )}
    </>
  );
};

interface PollMultiFreeTextInputProps {
  currentTextAnswer: string;
  setCurrentTextAnswer: (value: string) => void;
  textAnswersToSubmit: string[];
  setTextAnswersToSubmit: (value: string[]) => void;
  loading: boolean;
  iamDoneCallBack: () => void;
  disableIamDone: boolean;
  disableAddAnother: boolean;
  instruction?: string;
  questionText?: string;
  doneButtonMessage?: string;
  maxLength?: number;
  questionTextAlignment?: string;
  questionTextFontWeight?: string;
  questionTextColor?: string;
  submitButtonColorSet?: AnimatedButtonColorSet;
  addInputTracking?: () => void;
  width?: string;
  useBottomActionBar?: boolean;
}
export const PollMultiFreeTextInput = (props: PollMultiFreeTextInputProps) => {
  const {
    currentTextAnswer,
    setCurrentTextAnswer,
    textAnswersToSubmit,
    questionText,
    setTextAnswersToSubmit,
    loading,
    disableAddAnother,
    disableIamDone,
    instruction,
    iamDoneCallBack,
    doneButtonMessage,
    maxLength,
    questionTextAlignment,
    questionTextFontWeight,
    questionTextColor,
    submitButtonColorSet,
    addInputTracking,
    width,
    useBottomActionBar,
  } = props;

  const disableAddButton =
    !currentTextAnswer ||
    currentTextAnswer.trim().length === 0 ||
    disableAddAnother ||
    loading;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height dynamically
    }
  }, [currentTextAnswer]); // Re-run when the value changes

  return (
    <>
      <Text
        alignSelf={questionTextAlignment ?? "flex-start"}
        color={questionTextColor ?? Black}
        dangerouslySetInnerHTML={{
          __html: questionText as string,
        }}
        fontWeight={questionTextFontWeight}
      />
      {instruction && (
        <Text alignSelf={"flex-start"} color={Black} fontSize={"sm"}>
          {instruction}
        </Text>
      )}
      <InputGroup alignItems="flex-start">
        <HvTextarea
          isDisabled={disableAddAnother || loading}
          w={width ?? "100%"}
          ref={textareaRef}
          value={currentTextAnswer}
          placeholder={HVLocalizeStrings.ENTER_YOUR_RESPONSE_ONE_AT_A_TIME}
          onKeyDown={(e) => {
            if (e.key === "Enter" && currentTextAnswer && !disableAddButton) {
              textAnswersToSubmit.push(currentTextAnswer);
              setTextAnswersToSubmit([...textAnswersToSubmit]);
              setCurrentTextAnswer("");
              addInputTracking?.();
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            setCurrentTextAnswer(e.currentTarget.value);
            const target = e.currentTarget;
            target.style.height = "auto"; // Reset height to measure properly
            target.style.height = `${target.scrollHeight}px`; // Set height dynamically
          }}
          resize="none" // Remove resize handle
          rows={1} // Ensure the textarea starts with one visible row
          overflow="hidden" // Prevent scrollbars
          p="0.5rem" // Adjust padding for a tighter fit
          pr="4rem" // Ensure space for the button
          lineHeight="2" // Adjust for single-line height consistency
          borderRadius="md"
          bg="white"
          height="auto" // Ensure height is dynamic
          minHeight="2.5rem" // Ensure it's no taller than one line initially
          maxLength={maxLength}
          enterKeyHint={"done"}
        />
        <InputRightElement
          width="4rem"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            icon={<FiPlusCircle />}
            color={!disableAddButton ? Black : undefined}
            aria-label="Submit"
            position="absolute"
            width="3rem"
            height="3rem"
            fontSize="2rem"
            onClick={() => {
              if (currentTextAnswer) {
                textAnswersToSubmit.push(currentTextAnswer);
                setTextAnswersToSubmit([...textAnswersToSubmit]);
                setCurrentTextAnswer("");
              }
            }}
            isDisabled={disableAddButton}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          />
        </InputRightElement>
      </InputGroup>
      <Box h={"auto"} w={"100%"}>
        {textAnswersToSubmit.length > 0 ? (
          <PollResponseList
            noDefaultHeight={useBottomActionBar}
            mb={useBottomActionBar ? "100px" : undefined}
          >
            {textAnswersToSubmit
              .slice()
              .reverse()
              .map((answer, index) => (
                <PollResponseListItem key={`${answer}-${index}`}>
                  <Editable
                    wordBreak={"break-all"}
                    defaultValue={answer}
                    onSubmit={(value) => {
                      const updatedAnswers = [...textAnswersToSubmit];
                      updatedAnswers[(updatedAnswers.length -1) - index] = value;
                      setTextAnswersToSubmit(updatedAnswers);
                    }}
                    w={"100%"}
                  >
                    <Flex
                      flexDir={"row"}
                      h={"100%"}
                      w={"100%"}
                      align={"center"}
                    >
                      <EditablePreview as={Text} w={"100%"} mr={2} />
                      <EditableInput
                        mr={2}
                        bg="white"
                        w={"100%"}
                        outline={"none"}
                        boxShadow={"none"}
                        border={0}
                        _focusVisible={{ boxShadow: "none" }}
                        maxLength={props.maxLength}
                      />
                      <PollMultiFreeTextInputEditableControl
                        iconState={QuestionUserResponseEditorIconState.normal}
                        deleteAndPutBackClick={() => {
                          const updatedAnswers = [...textAnswersToSubmit];
                          updatedAnswers.splice(
                            (updatedAnswers.length-1) - index,
                            1
                          );
                          setTextAnswersToSubmit(updatedAnswers);
                        }}
                      />
                    </Flex>
                  </Editable>
                </PollResponseListItem>
              ))}
          </PollResponseList>
        ) : (
          <Flex
            minH={"150px"}
            flexDir={"column"}
            align={"center"}
            justify={"center"}
            gap={"5"}
          >
            <Loading
              colorOne={Evening_Blue}
              colorTwo={Mid_Blue}
              colorThree={Light_Blue}
            />
          </Flex>
        )}
      </Box>
      {!useBottomActionBar && (
        <Stack spacing={2}>
          {loading ? (
            <Center>
              <HvSpinner />
            </Center>
          ) : (
            <AnimatedButton
              disabled={disableIamDone || loading}
              w={300}
              text={doneButtonMessage ?? HVLocalizeStrings.DONE}
              onClick={() => {
                iamDoneCallBack();
              }}
              colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Third}
            />
          )}
        </Stack>
      )}
      {useBottomActionBar && (
        <BottomActionBar
          showOptions={false}
          nextButtonText={HVLocalizeStrings.DONE}
          nextButtonWidth={"300"}
          isNextButtonDisabled={() => {
            return disableIamDone || loading;
          }}
          colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Third}
          onNextButtonClick={() => {
            iamDoneCallBack();
          }}
          loading={loading}
          isFixed={true}
        />
      )}
    </>
  );
};
