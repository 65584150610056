import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HvSpinner } from "../../../Common/HvSpinner";
import { CharterDataDto, QuestionUserResponseDto } from "@headversity/contract";
import { White } from "../../../../Styles/HeadversityStyle";

export interface CharterPanelProps {
  pdfExport?: boolean;
  statements: QuestionUserResponseDto[];
  charterData: CharterDataDto;
}

export const CharterPanel = (props: CharterPanelProps) => {
  const { charterData, statements } = props;

  const aspectRatio: number = 3508 / 2480;
  let charterWidthByBreakpoint = useBreakpointValue({
    base: 320,
    sm: 460,
    md: 720,
    lg: 800,
    xl: 800,
    "2xl": 800,
  }) as number;
  let width = `${charterWidthByBreakpoint}px`;
  let height = `${charterWidthByBreakpoint / aspectRatio}px`;
  let scale = charterWidthByBreakpoint / 800;
  if (props.pdfExport) {
    // pdf specific settings
    width = "3508px";
    height = "2480px";
    scale = 3508 / charterWidthByBreakpoint;
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor={charterData?.background ? charterData.background : White}
      width={width}
      height={height}
      border={!props.pdfExport ? "1px solid black" : undefined}
      m={"0px"}
    >
      {charterData ? (
        <Flex flexDir="column" p={"10%"}>
          <Image
            src={charterData.logoUrl}
            objectFit="contain"
            width={`${200 * scale}px`}
            alt=""
          />
          <Text
            pb={2}
            fontFamily={charterData.font}
            fontSize={`${36 * scale}px`}
            color={charterData.fontColor}
          >
            {charterData.name}
          </Text>

          {statements?.map((statement) => (
            <Text
              noOfLines={1}
              key={statement.id}
              fontSize={`${18 * scale}px`}
              fontFamily={charterData.font}
              color={charterData.fontColor}
            >
              {statement.responseText}
            </Text>
          ))}
        </Flex>
      ) : (
        <Center>
          <HvSpinner />
        </Center>
      )}
    </Box>
  );
};
