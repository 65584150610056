import { AxiosClient } from "../AxiosClient";
import { AxiosResponse } from "axios";
import { InviteCodeDomainDto } from "@headversity/contract";
import { AuthTokensDto } from "@headversity/contract/Dto/AuthTokensDto";
import { getConfig } from "../Utils";

export const requestAccessToken = (
  accessToken: string,
  language: string,
  jwtToken?: string,
  inviteCodeId?: number
) => {
  return AxiosClient.post(
    `login/accesstoken`,
    {
      accessToken,
      language,
      ...(jwtToken && { jwtToken }),
      ...(inviteCodeId && { inviteCodeId }),
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const loginWithMsTeamsToken = (
  token: string,
  jwtToken?: string,
  inviteCodeId?: number
) => {
  return AxiosClient.post(
    `login/msTeams`,
    {
      token,
      ...(jwtToken && { jwtToken }),
      ...(inviteCodeId && { inviteCodeId }),
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getNewAccessToken = (
  token: string
): Promise<AxiosResponse<AuthTokensDto>> => {
  const config = getConfig(token);
  config.withCredentials = true;
  return AxiosClient.post(`api/self/accesstoken`, {}, config);
};

export const requestAccessTokenByCode = (
  code: string,
  language: string,
  jwtToken?: string,
  inviteCodeId?: number
) => {
  return AxiosClient.post(
    `login/accesscode`,
    {
      code,
      language,
      ...(jwtToken && { jwtToken }),
      ...(inviteCodeId && { inviteCodeId }),
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const loginAndGetAccessToken = (
  email: string,
  password: string,
  jwtToken?: string,
  inviteCodeId?: number
) => {
  return AxiosClient.post(
    `login`,
    {
      email,
      password,
      ...(jwtToken && { jwtToken }),
      ...(inviteCodeId && { inviteCodeId }),
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const logoutFromApi = () => {
  return AxiosClient.post(
    `logout`,
    {},
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getSSODomains = (): Promise<
  AxiosResponse<InviteCodeDomainDto[]>
> => {
  return AxiosClient.get(`login/ssoDomains`);
};

export const getRefreshToken = (
  authTokensDto: AuthTokensDto,
  jwtToken?: string,
  inviteCodeId?: number
): Promise<AxiosResponse<AuthTokensDto> | string> => {
  return AxiosClient.post(
    `login/refresh`,
    {
      authTokensDto,
      ...(jwtToken && { jwtToken }),
      ...(inviteCodeId && { inviteCodeId }),
    },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const lmsAutoEmail = (
  certKey: string,
  userId: string
): Promise<AxiosResponse<AuthTokensDto>> => {
  return AxiosClient.post(
    `api/registration/lms-auto-email`,
    { certKey, userId },
    {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
