import {
  Box,
  Center,
  ChakraProvider,
  Divider,
  Fade,
  Flex,
  Image,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
  Link,
} from "@chakra-ui/react";
import "@progress/kendo-theme-default/dist/all.css";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { App as CapApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import {
  PasswordContainerChildComponent,
  PromotionalMaterialDto,
  UserRole,
} from "@headversity/contract";
import { NewRelicCapacitorPlugin } from "@newrelic/newrelic-capacitor-plugin";
import { BottomNavigation } from "@progress/kendo-react-layout";
import dayjs, { Dayjs } from "dayjs";
import Highcharts from "highcharts";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AxiosClient } from "./Api/AxiosClient";
import { getNewAccessToken } from "./Api/People/AccessTokenApi";
import { currentAppVersion } from "./Api/Utils";
import { AppUrlListener } from "./AppUrlListener";
import { CertPage } from "./Components/Cert/CertPage";
import { CertQualificationsPage } from "./Components/Cert/CertQualificationsPage";
import { UserCertificatePage } from "./Components/Cert/UserCertificatePage";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "./Components/Common/AnimatedButton";
import {
  ConfirmationButtons,
  ConfirmationDialog,
} from "./Components/Common/ConfirmationDialog";
import { GetHelpModal } from "./Components/Common/GetHelpModal";
import { Header } from "./Components/Common/Header";
import { PostLoginGuard } from "./Components/People/Guard/PostLoginGuard";
import { AuthenticatedPage } from "./Components/People/Login/AuthenticatedPage";
import { LoginPage } from "./Components/People/Login/LoginPage";
import { MobileLoginPage } from "./Components/People/Login/MobileLoginPage";
import { MobilePostLoginPage } from "./Components/People/Login/MobilePostLoginPage";
import { SetAccessTokenPage } from "./Components/People/Login/SetAccessTokenPage";
import PasswordRequirementsContainer from "./Components/People/Shared/PasswordRequirementsContainer";
import { ReachHelpResourcesPageContainer } from "./Components/Reach/HelpResources/ReachHelpResourcesPageContainer";
import { ReachMyOrganizationPageContainer } from "./Components/Reach/Organizations/ReachMyOrganizationPageContainer";
import ReachCommunicationSettingsPage from "./Components/Reach/ReachCommunicationSettingsPage";
import { ReachHomePage } from "./Components/Reach/ReachHomePage";
import { ReachPromoMaterialModal } from "./Components/Reach/ReachPromoMaterialModal";
import ReachPromotePage from "./Components/Reach/ReachPromotePage";
import { ReachUsersPageContainer } from "./Components/Reach/SelfService/ReachUsersPageContainer";
import { ReachGoalsPageContainer } from "./Components/Reach/Goals/ReachGoalsPageContainer";
import { ReachScormsPage } from "./Components/Reach/Scorms/ReachScormsPage";
import { AppVersionError } from "./Components/Shared/AppVersionError/AppVersionError";
import { ErrorPage } from "./Components/Shared/Error/ErrorPage";
import { Navbar } from "./Components/Shared/Navigation/Navbar";
import { SidebarDesktop } from "./Components/Shared/Navigation/SidebarDesktop";
import { NoInternetConnectionError } from "./Components/Shared/NoInternetConnectionError/NoInternetConnectionError";
import { ExplorePage } from "./Components/Solo/Explore/ExplorePage";
import { FirstTimeInterstitialModal } from "./Components/Solo/FirstTimeInterstitial/FirstTimeInterstitialModal";
import { HeadScanQuestionModal } from "./Components/Solo/HeadScan/HeadScanQuestionModal";
import { HomePage } from "./Components/Solo/Home/HomePage";
import LessonPreviewModal from "./Components/Solo/Lessons/LessonPreviewModal";
import { LessonsPage } from "./Components/Solo/Lessons/LessonsPage";
import { NanoPracticeModal } from "./Components/Solo/NanoPractices/NanoPracticeModal";
import { NanoPracticesPage } from "./Components/Solo/NanoPractices/NanoPracticesPage";
import { ProfilePage } from "./Components/Solo/Profile/ProfilePage";
import { ResilienceFeedPage } from "./Components/Solo/ResilienceFeed/ResilienceFeedPage";
import { ResiliencePostModal } from "./Components/Solo/ResilienceFeed/ResiliencePostModal";
import { SkillContentPage } from "./Components/Solo/Skill/SkillContentPage";
import { SkillsPage } from "./Components/Solo/Skill/SkillsPage";
import { CalmingToolPage } from "./Components/Solo/Tools/Calming/CalmingToolPage";
import { FocusToolPage } from "./Components/Solo/Tools/Focus/FocusToolPage";
import { MindfulnessMediaPlayer } from "./Components/Solo/Tools/Mindfulness/MindfulnessMediaPlayer";
import { MindfulnessToolPage } from "./Components/Solo/Tools/Mindfulness/MindfulnessToolPage";
import { OptimismToolPage } from "./Components/Solo/Tools/Optimism/OptimismToolPage";
import { ThinkingToolPage } from "./Components/Solo/Tools/Thinking/ThinkingToolPage";
import { ToolsPage } from "./Components/Solo/Tools/ToolsPage";
import FirstTimeTeamInterstitialModal from "./Components/Team/FirstTimeTeamInterstitial/FirstTimeTeamInterstitialModal";
import { TeamHomePage } from "./Components/Team/Home/TeamHomePage";
import { InsightsPage } from "./Components/Team/Insights/InsightsPage";
import { JoinTeamPage } from "./Components/Team/JoinTeamPage";
import { ParticipantModal } from "./Components/Team/Participant/ParticipantModal";
import { PathPage } from "./Components/Team/Path/PathPage";
import { PresentationModal } from "./Components/Team/Presentation/PresentationModal";
import { PresentationPreviewModal } from "./Components/Team/Presentation/PresentationPreviewModal";
import { TeamActivityNotification } from "./Components/Team/TeamActivityNotification";
import ManagementPage from "./Components/Team/TeamManagement/ManagementPage";
import { CURRENT_URL_BEFORE_UPDATE } from "./Hooks/Shared/useLiveUpdateGlobal";
import { useTeamPusherSubs } from "./Hooks/Team/useTeamPusherSubs";
import {
  HVLocalizeStrings,
  HVLocalizeStringsObj,
} from "./Localization/HVLocalizeStrings";
import { UnLocalizeOutputPage } from "./Localization/UnLocalizeOutputPage";
import { AppType, GlobalContext, IGlobalProvider } from "./State/GlobalContext";
import { ReachInsightsContextProvider } from "./State/Reach/ReachInsightsContext";
import {
  IShellProvider,
  NOSTALGIC_THEME_NAME,
  ShellContext,
  DEFAULT_THEME_NAME,
} from "./State/ShellContext";
import { CalmingToolProvider } from "./State/Solo/CalmingToolContext";
import {
  FocusToolContext,
  FocusToolProvider,
} from "./State/Solo/FocusToolContext";
import { MindfulnessToolProvider } from "./State/Solo/MindfulnessToolContext";
import {
  OptimismToolContext,
  OptimismToolProvider,
} from "./State/Solo/OptimismToolContext";
import { ThinkingToolProvider } from "./State/Solo/ThinkingToolContext";
import { PAGE_BACKGROUND_COLOR } from "./Styles/HeadversityStyle";
import { TestPage } from "./Testing/TestPage";
import { HVTestId } from "./Testing/dataTestIds";
import { EVENTS, init as initAnalytics, track } from "./Utils/Analytics";
import { FeatureFlags } from "./Utils/FeatureFlags";
import {
  getKey,
  incrementLocalStorageCounter,
  getLocalStorageCounter,
  parseJSON,
  navigateWithSearchParams,
} from "./Utils/Helpers";
import {
  isRefreshTokenInCookie,
  setLocalStorageAuthTokens,
} from "./Utils/LoginUtil";
import {
  getNewRelicToken,
  isAndroid,
  isAndroidBrowser,
  isWebPlatform,
  writeToClipboard,
} from "./Utils/mobileUtils";
import CertInsightsPage from "./Components/Reach/Insights/CertInsightsPage";
import ReachInsightsPage from "./Components/Reach/ReachInsightsPage";
import HvSvgUtil, { HvSvgEnum } from "./Utils/HvSvgUtil";
import { LocalStorageKeys, LocalStorageWrapper } from "./Utils/StorageUtil";
import {
  getGoalMinSkillLevel,
  getTotalPoints,
  needsSkillScore,
  SOLO_GOAL_SKILL_LEVEL_TARGET,
  SOLO_GOAL_WELCOME_BACK_KEY,
} from "./Utils/SkillsUtil";
import { Tour } from "./Components/Common/Tour";
import WelcomeModal from "./Components/Shared/Welcome/WelcomeModal";
import { GoalCheckInModal } from "./Components/Solo/Goal/GoalCheckInModal";
import { ReachMyOrganizationContextProvider } from "./State/Reach/ReachMyOrganizationContext";
import { RegistrationValidationPage } from "./Components/People/Login/RegistrationValidationPage";
import { loadMessages, load } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import esCurrencies from "cldr-numbers-full/main/es/currencies.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";
import esTimeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";

import frNumbers from "cldr-numbers-full/main/fr/numbers.json";
import frCurrencies from "cldr-numbers-full/main/fr/currencies.json";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import frTimeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import GooglePlayBanner from "./Components/Common/GooglePlayBanner";
import { WaitingToLoginPage } from "./Components/People/Login/WaitingToLoginPage";
import { UnsubscribeEmailPage } from "./Components/People/Login/UnsubscribeEmailPage";
import { PracticeReminderModal } from "./Components/Shared/PracticeReminder/PracticeReminderModal";
import {
  getPathTeamLessonInProgress,
  getTeamForTeamLessonUserInstanceId,
} from "./Utils/TeamUtil";
import { SportPage } from "./Components/Sport/Home/SportPage";
import { CharterTestPage } from "./Components/Sport/Charter/TestPage/CharterTestPage";
import { CharterAutoTestPage } from "./Components/Sport/Charter/TestPage/CharterAutoTestPage";
import { SportResources } from "./Components/Sport/Resources/SportResources";
import { SportTeamManagement } from "./Components/Sport/Management/SportTeamManagement";
import { useSportTeam } from "./Hooks/Team/useSportTeam";
import {
  getMappedError,
  isErrorResponse,
} from "./Components/People/Shared/ErrorCodes";
import { HierarchyPageContainer } from "./Components/Reach/SelfService/ReachHierarchyPage";
import { HierarchySelectContainer } from "./Components/People/Shared/HierarchySelectContainer";
import { LandingContainer } from "./Components/People/Shared/LandingContainer";
import useCompanyReachAdmin from "./Hooks/Reach/useCompanyReachAdmin";
import { useCertAssign } from "./Hooks/Cert/useCertAssign";

require("highcharts/modules/accessibility")(Highcharts);

// init analytics
initAnalytics();

let oldPathName: any = null;

export const languageObj = { selectedLanguage: "en" };

export const SOLO_PRODUCT_ANNOUNCEMENT =
  "interstitial-solo-pursuit-challenge-x";

export const App = () => {
  const { theme, confirmationDialogRef } = useContext(ShellContext);
  const {
    self,
    soloEnabled,
    teamEnabled,
    reachEnabled,
    isReachAdminEnabled,
    certEnabled,
    sportEnabled,
    isReachHierarchyAdminEnabled,
    selectedUserLanguage,
  } = useContext<IGlobalProvider>(GlobalContext);

  useCertAssign();

  const companyReachAdmin = useCompanyReachAdmin();

  const toolPaths = [
    "mindfulness",
    "calming",
    "focus",
    "optimism",
    "thinking",
    "move",
  ];

  const soloElement = <HomePage />;

  return (
    theme && (
      <>
        <ChakraProvider theme={theme.themeInstance}>
          <MindfulnessToolProvider>
            <OptimismToolProvider>
              <FocusToolProvider>
                <Routes>
                  <Route
                    path={"/charterTest"}
                    element={<CharterTestPage />}
                  ></Route>
                  <Route
                    path={"/charterAutoTest"}
                    element={<CharterAutoTestPage />}
                  ></Route>
                  <Route
                    path={"/cert-instance"}
                    element={<UserCertificatePage />}
                  />
                  <Route path={"/login"} element={<LoginPage />} />
                  <Route
                    path={"/waitingTologin"}
                    element={<WaitingToLoginPage />}
                  />
                  <Route
                    path={"/mobilePostLogin"}
                    element={<MobilePostLoginPage />}
                  />
                  <Route
                    path={"/reset-password"}
                    element={
                      <PasswordRequirementsContainer
                        childComponent={
                          PasswordContainerChildComponent.ResetPasswordPage
                        }
                      />
                    }
                  />
                  {["/validate-registration", "/validate-login"].map((p) => (
                    <Route
                      key={p}
                      path={p}
                      element={<RegistrationValidationPage />}
                    />
                  ))}

                  <Route
                    path={"/unsubscribe"}
                    element={<UnsubscribeEmailPage />}
                  />
                  <Route path={"/mobileLogin"} element={<MobileLoginPage />} />
                  <Route
                    path={"/authenticated"}
                    element={<AuthenticatedPage />}
                  />
                  <Route
                    path={"/accessToken"}
                    element={<SetAccessTokenPage />}
                  />
                  <Route path={"/waiting"} element={<></>} />
                  <Route
                    path={"/"}
                    element={
                      isWebPlatform() ? (
                        <PostLoginGuard>
                          <AppContainer />
                        </PostLoginGuard>
                      ) : (
                        <AppContainer />
                      )
                    }
                  >
                    {soloEnabled && (
                      <>
                        <Route path={"/"} element={soloElement} />
                        <Route path={"/solo"} element={soloElement} />

                        <Route path={"/tools"} element={<ToolsPage />} />

                        {selectedUserLanguage !== "es" && (
                          <Route
                            path={"/tools/mindfulness"}
                            element={<MindfulnessToolPage />}
                          />
                        )}

                        <Route
                          path={"/tools/calming"}
                          element={
                            <CalmingToolProvider>
                              <CalmingToolPage />
                            </CalmingToolProvider>
                          }
                        />
                        <Route
                          path={"/tools/focus"}
                          element={<FocusToolPage />}
                        />

                        <Route
                          path={"/tools/optimism"}
                          element={<OptimismToolPage />}
                        />
                        <Route
                          path={"/tools/thinking"}
                          element={
                            <ThinkingToolProvider>
                              <ThinkingToolPage />
                            </ThinkingToolProvider>
                          }
                        />

                        <Route path={"/lessons"} element={<LessonsPage />} />
                        <Route path={"/skills"} element={<SkillsPage />} />
                        <Route
                          path={"/skills/:slug"}
                          element={<SkillContentPage />}
                        />
                        <Route
                          path={"/resilience-feed"}
                          element={<ResilienceFeedPage />}
                        />
                        <Route
                          path={"/live-sessions"}
                          element={<ResilienceFeedPage isLiveSessions={true} />}
                        />
                        <Route path={"/explore"} element={<ExplorePage />} />
                        <Route
                          path={"/nano-practices"}
                          element={<NanoPracticesPage />}
                        />
                      </>
                    )}
                    <Route path={"/profile"} element={<ProfilePage />} />

                    {teamEnabled && (
                      <>
                        <Route path={"/team"} element={<TeamHomePage />} />
                        <Route path={"/team/paths"} element={<PathPage />} />
                        <Route
                          path={"/team/management"}
                          element={<ManagementPage />}
                        />
                        <Route
                          path={"/team/insights"}
                          element={<InsightsPage />}
                        />
                        <Route
                          path={"/join/:teamCode"}
                          element={<JoinTeamPage />}
                        />
                      </>
                    )}

                    {sportEnabled && (
                      <>
                        <Route path={"/sport"} element={<SportPage />}></Route>
                        <Route
                          path={"/sport/resources"}
                          element={<SportResources />}
                        ></Route>
                        <Route
                          path={"/sport/management"}
                          element={<SportTeamManagement />}
                        ></Route>
                      </>
                    )}

                    {reachEnabled && (
                      <>
                        <>
                          <Route
                            path={"/reach/"}
                            element={
                              <ReachInsightsContextProvider>
                                <ReachMyOrganizationContextProvider>
                                  <ReachHomePage />
                                </ReachMyOrganizationContextProvider>
                              </ReachInsightsContextProvider>
                            }
                          />
                          <Route
                            path={"/reach/promote"}
                            element={<ReachPromotePage />}
                          />
                        </>
                        {isReachAdminEnabled && (
                          <>
                            <Route
                              path={"/reach/organizations"}
                              element={<ReachMyOrganizationPageContainer />}
                            />
                            {isReachHierarchyAdminEnabled &&
                              companyReachAdmin.isHvAdmin && (
                                <Route
                                  path={"/reach/organizations/hierarchies"}
                                  element={<HierarchyPageContainer />}
                                />
                              )}
                            <Route
                              path={"/reach/organizations/users"}
                              element={<ReachUsersPageContainer />}
                            />
                            {soloEnabled && (
                              <Route
                                path={"/reach/organizations/goals"}
                                element={<ReachGoalsPageContainer />}
                              />
                            )}
                            <Route
                              path={"/reach/organizations/scorms"}
                              element={<ReachScormsPage />}
                            />
                            <Route
                              path={
                                "/reach/organizations/communication-settings"
                              }
                              element={<ReachCommunicationSettingsPage />}
                            />
                            <Route
                              path={"/reach/organizations/help-resources"}
                              element={<ReachHelpResourcesPageContainer />}
                            />
                            <Route
                              path={"/reach/insights"}
                              element={
                                <ReachInsightsContextProvider>
                                  <ReachInsightsPage />
                                </ReachInsightsContextProvider>
                              }
                            />
                            <Route
                              path={"/reach/insights/certs"}
                              element={
                                <ReachInsightsContextProvider>
                                  <CertInsightsPage />
                                </ReachInsightsContextProvider>
                              }
                            />
                          </>
                        )}
                      </>
                    )}

                    {self && self.role === UserRole.HVAdmin && (
                      <>
                        <Route
                          path={"/localization"}
                          element={<UnLocalizeOutputPage />}
                        ></Route>
                        <Route path={"/test"} element={<TestPage />}></Route>
                      </>
                    )}

                    <Route
                      path="/practice"
                      element={<Navigate replace to="/tools" />}
                    />

                    {toolPaths.map((tool) => (
                      <Route
                        key={tool}
                        path={`/practice/${tool}`}
                        element={<Navigate replace to={`/tools/${tool}`} />}
                      />
                    ))}

                    {["tools", "practice"].map((path) => (
                      <Route
                        key={"headzone"}
                        path={`/${path}/headzone`}
                        element={<Navigate replace to={`/nano-practices`} />}
                      />
                    ))}

                    <Route
                      path="/learn"
                      element={<Navigate replace to="/lessons" />}
                    />

                    <Route path="*" element={<ErrorPage />} />

                    {certEnabled && (
                      <Route path={"/cert"} element={<CertPage />} />
                    )}
                    <Route
                      path={"/cert/:slug"}
                      element={<CertQualificationsPage />}
                    />
                  </Route>
                </Routes>
              </FocusToolProvider>
            </OptimismToolProvider>
          </MindfulnessToolProvider>
          <ConfirmationDialog ref={confirmationDialogRef}></ConfirmationDialog>
          <AppVersionError />
          <NoInternetConnectionError></NoInternetConnectionError>
          <AppUrlListener />
        </ChakraProvider>
      </>
    )
  );
};

const BOTTOM_MENU_PADDINGY = 5;
export const BOTTOM_MENU_HEIGHT = 48 + BOTTOM_MENU_PADDINGY * 2;

const AppContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    getSelfFromServer,
    selectedUserLanguage,
    isUninterruptibleModalOpen,
    setIsUninterruptibleModalOpen,
    loadAllData,
    getAppVersionFromServer,
    saveSiteLoadedEventToServer,
    participantModalOpen,
    setParticipantModalOpen,
    helpInfo,
    presenterModalOpen,
    presenterPathTeamLesson,
    setPresenterModalOpen,
    previewModalOpen,
    setPreviewModalOpen,
    currentPathTeamLessonPreview,
    currentTeamLessonUserInstanceId,
    teamEnabled,
    reachEnabled,
    isLessonPreviewOpen,
    lesson,
    setIsLessonPreviewOpen,
    isTourOpen,
    setIsTourOpen,
    currentApp,
    updateApp,
    isFirstTimeTeamInterstitialModalOpen,
    setIsFirstTimeTeamInterstitialModalOpen,
    promoMaterialModalOpen,
    setPromoMaterialModalOpen,
    setIsPromoMaterialNotFound,
    selectedPromoMaterial,
    selectedSkill,
    globalHeadScanQuestionModalOpen,
    setGlobalHeadScanQuestionModalOpen,
    setSelectedSkill,
    globalPractice,
    setGlobalPractice,
    nanoPracticeModalOpen,
    setNanoPracticeModalOpen,
    nanoPractices,
    promoMaterialAssets,
    skills,
    userSkillStats,
    pushDisabled,
    promptForPushPermission,
    getNotificationCountForTools,
    getFeatureVersion,
    self,
    baseDataLoaded,
    setBaseDataLoaded,
    globalBlogPost,
    clearGlobalActivities,
    isReachAdminEnabled,
    isReachHierarchyAdminEnabled,
    company,
    selectedGoal,
    schedulerReminders,
    commitUserInformation,
    oneSignalInitialized,
    logout,
    resourceLock,
    currentTeam,
    paths,
    setCurrentTeamLessonUserInstanceId,
    setParticipateForTeamLessonUserInstanceToServer,
    teams,
    setCurrentTeam,
    setIsHierarchyAssociationStepComplete,
    isHierarchyAssociationStepComplete,
    userHierarchyAssociations,
    getHierarchyAssocationsFromServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const {
    setMainContainerWidth,
    setIsMainContainerXL,
    audio,
    confirmationDialogRef,
    safeAreaInsets,
    showToast,
    theme,
    isSideBarOpen,
    setIsSideBarOpen,
    isFirstTimeUserWelcomeModalOpen,
    setIsFirstTimeUserWelcomeModalOpen,
    isGoalOverviewModalOpen,
    setIsGoalOverviewModalOpen,
    isGoalCheckInModalOpen,
    setIsGoalCheckInModalOpen,
    isGoalCheckInNewPursuitModalOpen,
    setIsGoalCheckInNewPursuitModalOpen,
    isPracticeReminderModalOpen,
    setIsPracticeReminderModalOpen,
    setThemeHelper,
  } = useContext<IShellProvider>(ShellContext);

  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const [minTimePassed, setMinTimePassed] = useState<boolean>(false);
  const [medTimePassed, setMedTimePassed] = useState<boolean>(false);
  const [maxTimePassed, setMaxTimePassed] = useState<boolean>(false);
  const [maxTimePassedTimeout, setMaxTimePassedTimeout] = useState<any>();
  const [showSplash, setShowSplash] = useState<boolean>(true);
  const [useFunnyBg, setUseFunnyBg] = useState<boolean>(false);
  const [lastSiteLoadedEventTime, setLastSiteLoadedEventTime] = useState<Date>(
    new Date()
  );

  const [needReloadOnVersionChanged, setNeedReloadOnVersionChanged] =
    useState<boolean>(false);
  const [tourSteps, setTourSteps] = useState<any[]>([]);
  const [appBarMenuItems, setAppBarMenuItems] = useState<any[]>([]);
  const [
    isSOLOProductAnnouncementModalOpen,
    setIsSOLOProductAnnouncementModalOpen,
  ] = useState(false);
  const [showPushBanner, setShowPushBanner] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [footerAndHeaderHeight, setFooterAndHeaderHeight] = useState<number>(0);

  const [isGoalCheckInWelcomeBack, setIsGoalCheckInWelcomeBack] =
    useState(false);
  const [didSOLOWelcomeIntersitital, setDidSOLOWelcomeIntersitital] =
    useState(false);
  const [hierarchyIsReady, setHierarchyIsReady] = useState(false);

  const { isLeader } = useSportTeam();
  const loadCountIncreased = useRef(false);

  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem(
      "msTeamsLastUrl",
      `${location.pathname}${location.search}`
    );
  }, [location]);

  const onBaseDataLoaded = () => {
    // we loaded!
    setBaseDataLoaded(true);

    // increment the success counter
    if (!loadCountIncreased.current) {
      incrementLocalStorageCounter("loads");
      loadCountIncreased.current = true;
    }
    const loadCount = getLocalStorageCounter("loads");

    // push notification reminder:
    // on desktop, show after every 10 loads; on app, after every 5
    const showAfter = isWebPlatform() ? 10 : 5;

    // stop asking after 3 times
    if (loadCount < showAfter * 3) {
      // never show on mobile web
      if (isWebPlatform() && isMobile) {
        setShowPushBanner(false);
      } else {
        // always show after the second load;
        if (loadCount === 2 || loadCount % showAfter === 0) {
          setShowPushBanner(true);
        }
      }
    }

    // clear the failure counter
    localStorage.removeItem("load-fails");
  };

  /* this is the initial entry point for loading data */
  useEffect(() => {
    if (self || !getKey()) return;
    getSelfFromServer().then((user) => {
      updateApp().then(() => {
        const url: any = localStorage.getItem(CURRENT_URL_BEFORE_UPDATE);
        if (url && !url.toLowerCase().includes("mobilelogin")) {
          localStorage.removeItem(CURRENT_URL_BEFORE_UPDATE);
          // only navigate if the prev url is not on the mobile login page
          navigate(url, { replace: true });
        }

        loadAllData(user, onBaseDataLoaded);

        LocalStorageWrapper.getItem(LocalStorageKeys.REFRESH_TOKEN).then(
          (refreshToken: string | null) => {
            if (!refreshToken && !isRefreshTokenInCookie()) {
              getNewAccessToken(getKey()).then((response) => {
                setLocalStorageAuthTokens(response.data);
              });
            }
          }
        );

        if (!isWebPlatform()) {
          if (getNewRelicToken()) {
            NewRelicCapacitorPlugin.setUserId({ userId: user.id.toString() });
          }
        } else {
          if ((window as any).newrelic) {
            (window as any).newrelic.setUserId(
              `${Capacitor.getPlatform()}: ${user.id.toString()}`
            );
          }
        }
      });
    });
  }, [getKey()]);

  useEffect(() => {
    // check base path (ignoring query), and kill any trailing slash
    const curPath = location.pathname.replace(/\/+$/, "");

    if (curPath !== oldPathName) {
      track(EVENTS.Navigated, { HV_Path: location.pathname });

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);

      // Save new site loaded event if last one was more than 1 hour ago
      const now = new Date();
      const deltaInMinutes =
        (now.valueOf() - lastSiteLoadedEventTime.valueOf()) / (1000 * 60);
      if (deltaInMinutes > 60) {
        setLastSiteLoadedEventTime(now);
        saveSiteLoadedEventToServer();
      }

      oldPathName = curPath;
    }
  }, [location.pathname]);

  useEffect(() => {
    // set medTimePassed and maxTimePassed from timeout
    // NOTE: minTimePassed is set after logo loads
    setTimeout(() => {
      if (!baseDataLoaded) setMedTimePassed(true);
    }, 5000);

    const maxTimePassedTimeout = setTimeout(() => {
      if (!baseDataLoaded) {
        setMaxTimePassed(true);

        // clear "dt" from local storage in case the problem is with the token
        //  try a few times before killing the token
        const loadFailCount = incrementLocalStorageCounter("load-fails");

        // after the 3rd consecutive failure, kill the token so user has to sign in again
        if (loadFailCount >= 3) {
          localStorage.removeItem("load-fails");
          setLocalStorageAuthTokens();
        }
      }
    }, 15000);

    setMaxTimePassedTimeout(maxTimePassedTimeout);
  }, []);

  // TODO: add hook to properly verify font has loaded, e.g. document.fonts.check...
  useEffect(() => {
    const loaded = minTimePassed && baseDataLoaded;

    if (loaded && maxTimePassedTimeout) {
      clearTimeout(maxTimePassedTimeout);
    }

    setShowSplash(!loaded);
    if (loaded && !isWebPlatform()) {
      setTimeout(() => {
        SplashScreen.hide();
      }, 800);
    }
  }, [minTimePassed, baseDataLoaded]);

  useTeamPusherSubs();

  useEffect(() => {
    if (selectedUserLanguage) {
      languageObj.selectedLanguage = selectedUserLanguage;
      HVLocalizeStrings.setLanguage(selectedUserLanguage);
      dayjs.locale(selectedUserLanguage);

      // TODO....highchart is not really friendly when it comes for localization
      switch (selectedUserLanguage) {
        case "en":
          Highcharts.setOptions({
            lang: {
              shortMonths: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              weekdays: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
            },
          });
          break;
        case "fr":
          Highcharts.setOptions({
            lang: {
              shortMonths: [
                "jan.",
                "fév.",
                "mars",
                "avr.",
                "mai",
                "juin",
                "juil.",
                "août",
                "sept.",
                "oct.",
                "nov.",
                "déc.",
              ],
              weekdays: [
                "dimanche",
                "lundi",
                "mardi",
                "mercredi",
                "jeudi",
                "vendredi",
                "samedi",
              ],
            },
          });
          break;
        case "es":
          Highcharts.setOptions({
            lang: {
              shortMonths: [
                "ene",
                "feb",
                "mar",
                "abr",
                "may",
                "jun",
                "jul",
                "ago",
                "sep",
                "oct",
                "nov",
                "dic",
              ],
              weekdays: [
                "domingo",
                "lunes",
                "martes",
                "miércoles",
                "jueves",
                "viernes",
                "sábado",
              ],
            },
          });
          break;
      }
    }
  }, [selectedUserLanguage]);

  // useRef so that we can read state value inside CanApp listener below
  const activeIsUninterruptibleModalOpen = React.useRef(
    isUninterruptibleModalOpen
  );

  useEffect(() => {
    activeIsUninterruptibleModalOpen.current = isUninterruptibleModalOpen;
  }, [isUninterruptibleModalOpen]);

  // Refresh the app when a new version is deployed
  useEffect(() => {
    if (isWebPlatform()) {
      const checkUpdatePeriodInSeconds = 2 * 60 * 60;
      const refreshIntervalId = setInterval(async () => {
        getAppVersionFromServer().then((appVersion: string | void) => {
          if (
            currentAppVersion.value !== "" &&
            currentAppVersion.value !== appVersion
          ) {
            setNeedReloadOnVersionChanged(true);
          }
        });
      }, checkUpdatePeriodInSeconds * 1000);

      return () => {
        clearInterval(refreshIntervalId);
      };
    } else {
      if (isAndroid()) {
        CapApp.addListener("backButton", (data) => {
          // prevent back swipe while modal is open (Android)
          if (!activeIsUninterruptibleModalOpen.current && data.canGoBack) {
            window.history.back();
          }
        });
      }

      // use for getting the timestamp when app goes to the background
      let inActiveTime: any = null;

      // updateApp will get call everytime the state change
      CapApp.addListener("appStateChange", ({ isActive }) => {
        if (isActive) {
          // Check inActiveTime, if it has been greater than 5 minute write back to server
          if (inActiveTime && dayjs().diff(inActiveTime, "minutes", true) > 5) {
            saveSiteLoadedEventToServer();
          }
          updateApp(1500);
        } else {
          // set timestamp when app goes to the background
          inActiveTime = dayjs();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (needReloadOnVersionChanged && !isUninterruptibleModalOpen) {
      confirmationDialogRef.current.confirmation(
        HVLocalizeStrings.NEW_APP_VERSION,
        HVLocalizeStrings.NEW_APP_VERSION_DESCRIPTION,
        ConfirmationButtons.Ok,
        () => {
          window.location.reload();
        }
      );
    }
  }, [needReloadOnVersionChanged, isUninterruptibleModalOpen]);

  useEffect(() => {
    if (isUninterruptibleModalOpen) {
      window.onbeforeunload = (e) => {
        e.preventDefault();
        return (e.returnValue = HVLocalizeStrings.LEAVE_MODAL_ARE_YOU_SURE);
      };
    } else {
      window.onbeforeunload = () => {};
      window.onunload = () => {};
    }
  }, [isUninterruptibleModalOpen]);

  useEffect(() => {
    if (!baseDataLoaded) return;

    if (currentApp === AppType.SOLO) {
      // initial interstitial: only check once per app load
      if (!didSOLOWelcomeIntersitital) {
        setDidSOLOWelcomeIntersitital(true);

        // first-time user?
        if (userSkillStats.length === 0) {
          track(EVENTS.WelcomeModalShown);

          setIsFirstTimeUserWelcomeModalOpen(true);
          return;
        }

        // never seen the returning user product announcement message?
        else if (localStorage.getItem(SOLO_PRODUCT_ANNOUNCEMENT) === null) {
          setIsSOLOProductAnnouncementModalOpen(!isUninterruptibleModalOpen);
          return;
        }

        // time for a goal check-in?
        //  we won't do this when the user is sent to a specific resource
        else if (selectedGoal && window.location.pathname === "/") {
          const lastShown = localStorage.getItem(SOLO_GOAL_WELCOME_BACK_KEY);
          const daysSince = !lastShown
            ? 1
            : dayjs().startOf("day").diff(dayjs(lastShown), "days");

          // have we never seen the modal or is it time to show it again?
          if (daysSince >= 1) {
            localStorage.setItem(
              SOLO_GOAL_WELCOME_BACK_KEY,
              dayjs().add(30, "day").format("YYYY-MM-DD")
            );

            // reached level in each goal skill?
            const minLevel = getGoalMinSkillLevel(userSkillStats, selectedGoal);

            if (minLevel >= SOLO_GOAL_SKILL_LEVEL_TARGET) {
              setIsGoalCheckInModalOpen(true);
              setIsGoalCheckInWelcomeBack(true);

              return;
            }

            // time for headscan redo?

            // find the most recent score in the 4 main pursuit skills
            //  3 Confidence
            //  5 Grit
            //  6 Recharging
            //  12 Connecting
            let mostRecent: Dayjs | undefined = undefined;

            for (const sk of userSkillStats.filter((us) =>
              [3, 5, 6, 12].includes(us.skillId)
            )) {
              if (sk.headscanHistory.length === 0) continue;

              // get the most recent item in headscanHistory array
              const dt = dayjs.utc(
                sk.headscanHistory[sk.headscanHistory.length - 1].date
              );

              if (!mostRecent || dt > mostRecent) {
                mostRecent = dt;
              }
            }

            // more than 90 days ago?
            if (mostRecent && mostRecent < dayjs().utc().add(-90, "day")) {
              setIsGoalCheckInNewPursuitModalOpen(true);
              setIsGoalCheckInWelcomeBack(true);
              return;
            }
          }
        }
      }

      // tour: check when isUninterruptibleModalOpen changes, so we start tour after user finishes activity loop for the first time
      if (!isUninterruptibleModalOpen) {
        const KEY = "tour-shown";
        const lastShown = localStorage.getItem(KEY);
        const daysSince = !lastShown
          ? -1
          : dayjs().startOf("day").diff(dayjs(lastShown), "days");

        if (
          daysSince === -1 ||
          (daysSince >= 1 && getTotalPoints(userSkillStats) === 0)
        ) {
          setIsTourOpen(true);
          localStorage.setItem(KEY, dayjs().format("YYYY-MM-DD"));
        }
      }
    }
  }, [baseDataLoaded, currentApp, isUninterruptibleModalOpen]);

  const resizeObserver = new ResizeObserver((e) => {
    const w = (e[0].target as HTMLElement).offsetWidth;

    setMainContainerWidth(w);
    setIsMainContainerXL(w > 1300);

    if (document.getElementById("custom-footer")) {
      // footer height + 20 for footer margin + 275 for header height
      setFooterAndHeaderHeight(
        (document.getElementById("custom-footer")?.offsetHeight || 0) + 20 + 275
      );
    }
  });

  useEffect(() => {
    if (showSplash) return;
    window.parent.postMessage({ type: "page-loaded" }, "*");

    const e = document.querySelector(".hv-main-container");

    if (e) {
      resizeObserver.observe(e as HTMLElement);
    }

    window.addEventListener("keydown", (event) => {
      if (
        event.key === "Escape" &&
        document.getElementsByClassName("app-tour").length > 0
      ) {
        setIsTourOpen(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  }, [showSplash]);

  const { getOptimismPlanFromServer, currentPlannedItem } =
    useContext(OptimismToolContext);
  const { getFocusPlanItemsFromServer, focusPlanItems } =
    useContext(FocusToolContext);

  useEffect(() => {
    getOptimismPlanFromServer();
    getFocusPlanItemsFromServer();
  }, []);

  let newRelicScript = <></>;
  const script = process.env.REACT_APP_NEW_RELIC;
  if (script) {
    newRelicScript = (
      <Helmet>
        <script type="text/javascript" src={`/NewRelic/${script}.js`}></script>
      </Helmet>
    );
  }

  const axiosErrorHandler = useCallback(async (error: any) => {
    if (process.env.NODE_ENV !== "production") {
      let errorLog = sessionStorage.getItem("error_log");
      if (!errorLog) {
        errorLog = "";
      }
      errorLog = errorLog + JSON.stringify(error, null, 2);
      sessionStorage.setItem("error_log", errorLog);
      await writeToClipboard(errorLog);
    }

    const errorResponse = parseJSON(error?.response?.data?.message);
    if (error?.response?.status === 400 && isErrorResponse(errorResponse)) {
      showToast(false, "error", getMappedError(errorResponse.code as string));
    } else if (
      error.request.responseURL.endsWith("/assets") &&
      error.response.status === 404
    ) {
      setIsPromoMaterialNotFound(undefined);
      showToast(
        false,
        error.request.responseURL + new Date().getSeconds(),
        HVLocalizeStrings.REACH_PROMO_NOT_FOUND
      );
    } else if (
      /\/blogs\/\d+$/.test(error.request.responseURL) &&
      error.response.status === 404
    ) {
      showToast(
        false,
        "error",
        HVLocalizeStrings.RESILIENCE_FEED_POST_NOT_FOUND
      );
    } else if (process.env.REACT_APP_DEBUG) {
      showToast(false, "error", JSON.stringify(error, null, 2));
    } else {
      showToast(false, "error", HVLocalizeStrings.SOLO_UNEXPECTED_ERROR);
    }
  }, []);

  useEffect(() => {
    const interceptorId = AxiosClient.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (error?.message && /timeout of \d+ms exceeded/.test(error.message)) {
          return Promise.reject(error);
        }
        if (
          error?.response?.data?.message !== "Invalid refresh token" &&
          error?.response?.status !== 401
        ) {
          await axiosErrorHandler(error);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      AxiosClient.interceptors.response.eject(interceptorId);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsUninterruptibleModalOpen(
        !!document.querySelector(".uninterruptible-modal")
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // https://docs.react-joyride.com/step
    const steps = [
      {
        target: ".get-help-button",
        title:
          currentApp === AppType.REACH && isReachAdminEnabled
            ? HVLocalizeStrings.GET_REACH_HELP_LABEL
            : helpInfo?.buttonTitle
            ? helpInfo.buttonTitle
            : HVLocalizeStrings.GET_HELP_LABEL,
        content:
          currentApp === AppType.REACH && isReachAdminEnabled
            ? HVLocalizeStrings.TOUR_GET_REACH_HELP_DESCRIPTION
            : HVLocalizeStrings.TOUR_GET_HELP_DESCRIPTION,
        disableBeacon: true,
      },
      {
        target: ".points-button",
        title: HVLocalizeStrings.SOLO_HOME_ACHIEVEMENTS_DESCRIPTION,
        content: HVLocalizeStrings.EARN_MODAL_INITIAL_MESSAGE_BODY,
        disableBeacon: true,
      },
      {
        target: ".goal-button",
        title: HVLocalizeStrings.YOUR_PURSUIT,
        content: HVLocalizeStrings.TOUR_PURSUIT,
        disableBeacon: true,
      },
      {
        target: ".streak-button",
        title: HVLocalizeStrings.YOUR_STREAK,
        content: HVLocalizeStrings.START_STREAK_DESCRIPTION,
        disableBeacon: true,
      },
      {
        target: ".open-menu-button-app",
        title: HVLocalizeStrings.TOUR_MENU_TITLE,
        content: HVLocalizeStrings.TOUR_MENU_APP_DESCRIPTION,
        disableBeacon: true,
      },
      {
        target: ".open-menu-button",
        title: HVLocalizeStrings.TOUR_MENU_TITLE,
        content: reachEnabled
          ? HVLocalizeStrings.TOUR_MENU_DESCRIPTION_REACH
          : teamEnabled
          ? HVLocalizeStrings.TOUR_MENU_DESCRIPTION_TEAM
          : HVLocalizeStrings.TOUR_MENU_DESCRIPTION,
        disableBeacon: true,
      },
      {
        target: ".goal-box",
        title: HVLocalizeStrings.YOUR_PURSUIT,
        content: HVLocalizeStrings.TOUR_PURSUIT_BOX,
        disableBeacon: true,
      },
      {
        target: ".progress-steps-container",
        title: HVLocalizeStrings.DAILY_THREE_TITLE,
        content: HVLocalizeStrings.TOUR_DAILY_THREE_DESCRIPTION,
        disableBeacon: true,
        placement: "top" as "top",
      },
      {
        target: ".skills-container-20",
        title: HVLocalizeStrings.MORE_ACTIVITIES,
        content: HVLocalizeStrings.TOUR_RECOMMENDED_DESCRIPTION,
        disableBeacon: true,
        placement: "top" as "top",
      },
      {
        target: ".live-sessions",
        title: HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_TITLE,
        content: HVLocalizeStrings.SOLO_HOME_LIVE_SESSIONS_DESCRIPTION,
        disableBeacon: true,
        placement: "top" as "top",
      },
      {
        target: ".resilience-feed",
        title: HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_TITLE,
        content: HVLocalizeStrings.SOLO_HOME_RESILIENCE_FEED_DESCRIPTION,
        disableBeacon: true,
        placement: "top" as "top",
      },
      {
        target: ".recommended-assets",
        title: HVLocalizeStrings.REACH_RECOMMENDED_ASSETS,
        content: HVLocalizeStrings.REACH_RECOMMENDED_ASSETS_TOUR_DESCRIPTION,
        disableBeacon: true,
        placement: "top" as "top",
      },
    ];

    setTourSteps(steps);
  }, [helpInfo, selectedUserLanguage, currentApp]);

  useEffect(() => {
    setNotificationCount(
      getNotificationCountForTools(currentPlannedItem, focusPlanItems)
    );
  }, [currentPlannedItem, focusPlanItems]);

  const companyReachAdmin = useCompanyReachAdmin();

  /**
   * NOTE: there are 3 nav bars:
   *  SidebarDesktop.tsx: desktop
   *  SidebarMobileWeb.tsx: mobile web
   *  appBarMenuItems in App.tsx
   *
   */
  useEffect(() => {
    const items =
      currentApp === AppType.SOLO
        ? [
            {
              text: HVLocalizeStrings.SOLO_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/",
              selected:
                location.pathname === "/" || location.pathname === "/solo",
            },
            {
              text: HVLocalizeStrings.SOLO_MENU_SKILLS,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiGrid),
              id: "2",
              route: "/skills",
              selected: location.pathname?.indexOf("/skills") > -1,
            },
            {
              text: HVLocalizeStrings.EXPLORE,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiMap),
              id: "3",
              route: "/explore",
              selected:
                location.pathname === "/explore" ||
                location.pathname === "/nano-practices" ||
                location.pathname === "/lessons" ||
                location.pathname === "/tools" ||
                location.pathname === "/live-sessions" ||
                location.pathname === "/resilience-feed",
            },
          ]
        : currentApp === AppType.TEAM
        ? [
            {
              text: HVLocalizeStrings.SOLO_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/team",
              selected: location.pathname === "/team",
            },
            {
              text: HVLocalizeStrings.TEAM_MENU_PATHS,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.SiFuturelearn),
              id: "2",
              route: "/team/paths",
              selected: location.pathname === "/team/paths",
            },
            {
              text: HVLocalizeStrings.TEAM_MENU_TEAMS,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.MdGroups),
              id: "3",
              route: "/team/management",
              selected: location.pathname === "/team/management",
            },
            {
              text: HVLocalizeStrings.TEAM_MENU_INSIGHTS,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.HiOutlineLightBulb),
              id: "4",
              route: "/team/insights",
              selected: location.pathname === "/team/insights",
            },
          ]
        : currentApp === AppType.REACH
        ? [
            {
              text: HVLocalizeStrings.SOLO_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/reach",
              selected: location.pathname === "/reach",
            },
            {
              text: HVLocalizeStrings.REACH_PROMOTE,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.IoRocketOutline),
              id: "4",
              route: "/reach/promote",
              selected: location.pathname === "/reach/promote",
            },
            ...(isReachAdminEnabled
              ? [
                  {
                    text: HVLocalizeStrings.REACH_MY_ORG,
                    svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.MdDomain),
                    id: "2",
                    route: "/reach/organizations",
                    selected: location.pathname.includes(
                      "/reach/organizations"
                    ),
                  },
                  {
                    text: HVLocalizeStrings.REACH_INSIGHTS,
                    svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.MdEmojiObjects),
                    id: "3",
                    route: "/reach/insights",
                    selected: location.pathname.includes("/reach/insights"),
                  },
                ]
              : []),
            ...(isReachAdminEnabled &&
            isReachHierarchyAdminEnabled &&
            companyReachAdmin.isHvAdmin
              ? [
                  {
                    text: HVLocalizeStrings.REACH_HIERARCHIES,
                    svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.TbHierarchy),
                    id: "4",
                    route: "/reach/organizations/hierarchies",
                    selected: location.pathname.includes(
                      "/reach/organizations/hierarchies"
                    ),
                  },
                ]
              : []),
          ]
        : currentApp === AppType.CERT
        ? [
            {
              text: HVLocalizeStrings.SOLO_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/cert",
              selected: location.pathname.startsWith("/cert"),
            },
          ]
        : currentApp === AppType.SPORT && isLeader
        ? [
            {
              text: HVLocalizeStrings.SPORT_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/sport",
              selected: location.pathname === "/sport",
            },
            {
              text: HVLocalizeStrings.TEAM_MENU_TEAMS,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.MdGroups),
              id: "2",
              route: "/sport/management",
              selected: location.pathname.startsWith("/sport/management"),
            },
            {
              text: HVLocalizeStrings.SPORT_MENU_RESOURCES,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiGrid),
              id: "3",
              route: "/sport/resources",
              selected: location.pathname.startsWith("/sport/resources"),
            },
          ]
        : currentApp === AppType.SPORT
        ? [
            {
              text: HVLocalizeStrings.SPORT_MENU_HOME,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiHome),
              id: "1",
              route: "/sport",
              selected: location.pathname === "/sport",
            },
            {
              text: HVLocalizeStrings.SPORT_MENU_RESOURCES,
              svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiGrid),
              id: "3",
              route: "/sport/resources",
              selected: location.pathname.startsWith("/sport/resources"),
            },
          ]
        : [];

    const extra =
      currentApp === AppType.TEAM
        ? "?app=team"
        : currentApp === AppType.REACH
        ? "?app=reach"
        : currentApp === AppType.CERT
        ? "?app=cert"
        : currentApp === AppType.SPORT
        ? "?app=sport"
        : "";

    if (currentApp === AppType.SPORT) {
      setThemeHelper(DEFAULT_THEME_NAME);
    }

    items.push({
      text:
        notificationCount > 0
          ? `${HVLocalizeStrings.SOLO_PROFILE_TITLE} (${notificationCount})`
          : HVLocalizeStrings.SOLO_PROFILE_TITLE,
      svgIcon: HvSvgUtil.getSvgIcon(HvSvgEnum.FiUser),
      id: "5",
      route: `/profile${extra}`,
      selected:
        location.pathname === "/profile" ||
        location.pathname === "/achievements",
    });

    setAppBarMenuItems(items);
  }, [currentApp, location, notificationCount, selectedUserLanguage]);

  useEffect(() => {
    if (
      nanoPractices.length === 0 ||
      nanoPracticeModalOpen ||
      window.location.search === "" || // TODO: why searchParams has value but window.location.search is empty?
      !searchParams.get("practice") ||
      !currentApp ||
      !userSkillStats ||
      (skills.length === 0 && currentApp !== AppType.CERT) ||
      (userSkillStats.length === 0 && currentApp !== AppType.CERT)
    ) {
      return;
    }

    const nanoPracticeFromParameter = nanoPractices.find(
      (item) => item.id.toString() === searchParams.get("practice")
    );

    if (nanoPracticeFromParameter) {
      const skill = skills.find(
        (x) => x.id === nanoPracticeFromParameter.skillCompetencies[0]?.skillId
      );

      clearGlobalActivities();
      setGlobalPractice(nanoPracticeFromParameter);

      // if we're in SOLO and we haven't scored for this skill, open headscan and then load item
      if (
        currentApp !== AppType.CERT &&
        currentApp !== AppType.SPORT &&
        needsSkillScore(userSkillStats, skill?.id)
      ) {
        setSelectedSkill(skill);
        setGlobalHeadScanQuestionModalOpen(true);
      } else {
        setNanoPracticeModalOpen(true);
      }
    }
  }, [
    searchParams,
    globalPractice,
    nanoPractices,
    skills,
    nanoPracticeModalOpen,
    userSkillStats,
    currentApp,
  ]);

  useEffect(() => {
    // If the user is not being impersonated, OneSignal is configured, the base data is loaded,
    // OneSignal is initialized, and we have the needed data, then commit the user information to OneSignal
    const impersonatingUserId = localStorage.getItem("impersonatingUserId");
    if (impersonatingUserId) {
      return;
    }
    if (
      process.env.REACT_APP_ONE_SIGNAL_APP_ID &&
      baseDataLoaded &&
      oneSignalInitialized &&
      self &&
      company
    ) {
      commitUserInformation(
        self,
        company,
        selectedGoal,
        selectedUserLanguage,
        schedulerReminders
      );
    }
  }, [
    baseDataLoaded,
    oneSignalInitialized,
    self,
    company,
    selectedGoal,
    selectedUserLanguage,
    schedulerReminders,
  ]);

  useEffect(() => {
    // get the team using the search param instance id
    const teamFromInstanceId = getTeamForTeamLessonUserInstanceId(
      Number(searchParams.get("teamLessonUserInstanceId")),
      paths,
      teams
    );

    if (!teamFromInstanceId) return;

    // if there is a path team lesson in progress, the user is part of the team, and has the instance id in their URL, auto-join the team presentation and update their current team
    if (teamFromInstanceId) {
      const pathTeamLessonInProgress = getPathTeamLessonInProgress(
        paths,
        teamFromInstanceId
      );

      if (
        pathTeamLessonInProgress &&
        searchParams.get("teamLessonUserInstanceId") ===
          pathTeamLessonInProgress.teamLessonUserInstanceId?.toString()
      ) {
        if (teamFromInstanceId.id !== currentTeam?.id) {
          setCurrentTeam(teamFromInstanceId);
        }

        setCurrentTeamLessonUserInstanceId(
          pathTeamLessonInProgress.teamLessonUserInstanceId
        );

        if (!participantModalOpen) {
          setTimeout(() => {
            setParticipateForTeamLessonUserInstanceToServer(
              pathTeamLessonInProgress.teamLessonUserInstanceId as number
            ).then(() => {
              setParticipantModalOpen(true);
            });
          }, 300);
        }
      }
    }
  }, [paths, searchParams, currentTeam, teams]);

  // This useEffect hook is useful when a user switches to a new phone or from web to mobile
  // and has already subscribed to a schedule that requires notifications to be enabled.
  useEffect(() => {
    if (
      getLocalStorageCounter("loads") === 1 &&
      schedulerReminders &&
      schedulerReminders.length > 0 &&
      !(isWebPlatform() && isMobile)
    ) {
      setShowPushBanner(true);
    }
  }, [schedulerReminders]);
  useEffect(() => {
    getHierarchyAssocationsFromServer().then((result) => {
      if (result) {
        setHierarchyIsReady(true);
      }
    });
  }, []);

  const customFooter = getFeatureVersion(FeatureFlags.CUSTOM_FOOTER) as any;

  const impersonatingUserId = localStorage.getItem("impersonatingUserId");

  const spanishMessages = {
    scheduler: {
      today: HVLocalizeStringsObj.es.TODAY,
      monthViewTitle: HVLocalizeStringsObj.es.MONTH,
    },
    timepicker: {
      set: HVLocalizeStringsObj.es.TIME_PICKER_SET,
      cancel: HVLocalizeStringsObj.es.TIME_PICKER_CANCEL,
      now: HVLocalizeStringsObj.es.TIME_PICKER_NOW,
      selectNow: HVLocalizeStringsObj.es.TIME_PICKER_SELECT_NOW,
      toggleTimeSelector:
        HVLocalizeStringsObj.es.TIME_PICKER_TOGGLE_TIME_SELECTOR,
      toggleClock: HVLocalizeStringsObj.es.TIME_PICKER_TOGGLE_CLOCK,
    },
    datetimepicker: {
      date: HVLocalizeStringsObj.es.NOTIFICATION_HEADER_DATE,
      time: HVLocalizeStringsObj.es.TOOLS_PAGE_USED_TIME,
      cancel: HVLocalizeStringsObj.es.TIME_PICKER_CANCEL,
      set: HVLocalizeStringsObj.es.TIME_PICKER_SET,
    },
  };

  const frenchMessages = {
    scheduler: {
      today: HVLocalizeStringsObj.fr.TODAY,
      monthViewTitle: HVLocalizeStringsObj.fr.MONTH,
    },
    timepicker: {
      set: HVLocalizeStringsObj.fr.TIME_PICKER_SET,
      cancel: HVLocalizeStringsObj.fr.TIME_PICKER_CANCEL,
      now: HVLocalizeStringsObj.fr.TIME_PICKER_NOW,
      selectNow: HVLocalizeStringsObj.fr.TIME_PICKER_SELECT_NOW,
      toggleTimeSelector:
        HVLocalizeStringsObj.fr.TIME_PICKER_TOGGLE_TIME_SELECTOR,
      toggleClock: HVLocalizeStringsObj.fr.TIME_PICKER_TOGGLE_CLOCK,
    },
    datetimepicker: {
      date: HVLocalizeStringsObj.fr.NOTIFICATION_HEADER_DATE,
      time: HVLocalizeStringsObj.fr.TOOLS_PAGE_USED_TIME,
      cancel: HVLocalizeStringsObj.fr.TIME_PICKER_CANCEL,
      set: HVLocalizeStringsObj.fr.TIME_PICKER_SET,
    },
  };
  load(
    likelySubtags,
    currencyData,
    weekData,
    frNumbers,
    frCurrencies,
    frCaGregorian,
    frDateFields,
    frTimeZoneNames,
    esNumbers,
    esCurrencies,
    esCaGregorian,
    esDateFields,
    esTimeZoneNames
  );
  loadMessages(frenchMessages, "fr-CA");
  loadMessages(spanishMessages, "es-ES");

  return (
    <>
      {impersonatingUserId && self ? (
        <Box
          position="fixed"
          zIndex={1000}
          backgroundColor="white"
          p={2}
          m={2}
          rounded="md"
          top={0}
          left={100}
        >
          <Text fontSize={"13px"}>
            Impersonating User: {self.email} |{" "}
            <Link
              textDecoration={"underline"}
              href="#"
              onClick={(e) => {
                logout();
                e.preventDefault();
              }}
            >
              Stop
            </Link>
          </Text>
        </Box>
      ) : null}
      {newRelicScript}
      {showSplash && (
        <Flex
          h={"100vh"}
          align={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          bgColor="white"
        >
          <Box maxW={300}>
            <Image
              src={
                "https://cdn.headversity.com/app/resources/other/blue-main-logo.png"
              }
              onLoad={() => {
                setTimeout(() => {
                  setMinTimePassed(true);
                }, 1000);
              }}
              alt={HVLocalizeStrings.HEADVERSITY}
            />
            {medTimePassed && !maxTimePassed && (
              <Box w={"100%"} textAlign={"center"}>
                <Spinner />
              </Box>
            )}
            {maxTimePassed && (
              <Text mt={4}>
                {HVLocalizeStrings.SOLO_HOME_INTERNET_CONNECTION_ERROR}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      {!showSplash && (
        <Flex
          as="section"
          direction={{ base: "column", lg: "row" }}
          bg="bg-canvas"
          minH={"100vh"}
        >
          {!resourceLock && isWebPlatform() && (
            <>
              {isDesktop ? (
                <SidebarDesktop
                  setUseFunnyBg={setUseFunnyBg}
                  isOpen={isSideBarOpen}
                  setIsOpen={setIsSideBarOpen}
                />
              ) : (
                <Navbar setUseFunnyBg={setUseFunnyBg} />
              )}
            </>
          )}

          <Box
            pb={`${
              (showPushBanner ? 150 : 0) +
              (isWebPlatform() && audio
                ? 50
                : !isWebPlatform()
                ? BOTTOM_MENU_HEIGHT + safeAreaInsets.bottom + (audio ? 75 : 0)
                : 0)
            }px`}
            px={0}
            pos={"relative"}
            bg={PAGE_BACKGROUND_COLOR}
            flex="1"
            maxW={
              // always full-width on app (since no sidebar) and web mobile width
              !isWebPlatform() || !isDesktop || resourceLock
                ? "100%"
                : `calc(100% - ${isSideBarOpen ? 240 : 60}px)`
            }
            bgImage={
              currentApp === AppType.SPORT
                ? "https://cdn.headversity.com/app/sport/green-main-bg.jpg"
                : !isDesktop
                ? undefined
                : useFunnyBg
                ? "url(https://cdn.headversity.com/app/resources/modal/modalBG_lg_team.png)"
                : "url(https://cdn.headversity.com/app/resources/modal/modalBG_lg.png)"
            }
            bgPos={"0 0"}
            bgRepeat={"repeat-y"}
            bgSize="100% auto"
            className={"hv-main-container"}
          >
            <Header />

            {isTourOpen && (
              <Tour
                tourSteps={tourSteps}
                onTourComplete={() => {
                  setIsTourOpen(false);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
            )}

            <Box
              minH={
                footerAndHeaderHeight > 0
                  ? `calc(100vh - ${footerAndHeaderHeight}px)`
                  : undefined
              }
            >
              <Outlet />
            </Box>

            {customFooter && (
              <Box id="custom-footer" mt={"20px"}>
                <Divider />
                <Center p={8} bg="white">
                  <VStack data-testid={HVTestId.App.customFooter}>
                    {customFooter.tagline && (
                      <Text fontSize={"sm"}>
                        {customFooter.tagline[selectedUserLanguage]}
                      </Text>
                    )}
                    {customFooter.logo && (
                      <Image
                        width="200px"
                        src={customFooter.logo[selectedUserLanguage]}
                        alt=""
                      />
                    )}
                  </VStack>
                </Center>
              </Box>
            )}

            {!isWebPlatform() && (
              <BottomNavigation
                onSelect={(e) => {
                  navigate(e.itemTarget.route);
                }}
                items={appBarMenuItems}
                style={{
                  fontSize: "12px",
                  zIndex: "100",
                  paddingTop: `${BOTTOM_MENU_PADDINGY}px`,
                  paddingBottom: `${
                    BOTTOM_MENU_PADDINGY + safeAreaInsets.bottom
                  }px`,
                }}
              />
            )}
          </Box>
        </Flex>
      )}

      {pushDisabled &&
        showPushBanner &&
        !isUninterruptibleModalOpen &&
        !isTourOpen &&
        !resourceLock &&
        !sessionStorage.getItem("channel") && (
          <Box
            pos="fixed"
            bottom="0px"
            w="100%"
            pt="15px"
            pb={`${15 + safeAreaInsets.bottom}px`}
            background="white"
            borderTop="solid 1px #ccc"
            textAlign="center"
            zIndex="999"
          >
            <Text fontSize="lg" fontWeight="600">
              {HVLocalizeStrings.TURN_ON_NOTIFICATIONS}
            </Text>
            <Text mt={2} mb={4}>
              {HVLocalizeStrings.WELCOME_HEADZONE_HELP_SUCCEED}
            </Text>
            <AnimatedButton
              text={HVLocalizeStrings.ENABLE}
              onClick={() => {
                setShowPushBanner(false);
                promptForPushPermission();
              }}
            />
            &nbsp;&nbsp;
            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Third}
              text={HVLocalizeStrings.NOT_NOW}
              onClick={() => {
                setShowPushBanner(false);
              }}
            />
          </Box>
        )}

      <svg height="0">
        <filter id="shadow">
          <feDropShadow dx="0" dy="1" stdDeviation="1" floodColor="#ddd" />
        </filter>
        <defs>
          {skills.map((skill) => {
            return (
              <linearGradient
                key={skill.id}
                id={`skillGradient${skill.id}`}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  stopColor={`${skill.color}${
                    theme.name === NOSTALGIC_THEME_NAME ? "66" : "33"
                  }`}
                />
                <stop
                  offset="15%"
                  stopColor={`${skill.color}${
                    theme.name === NOSTALGIC_THEME_NAME ? "99" : "66"
                  }`}
                />
                <stop
                  offset="40%"
                  stopColor={`${skill.color}${
                    theme.name === NOSTALGIC_THEME_NAME ? "CC" : "BB"
                  }`}
                />
                <stop offset="90%" stopColor={`${skill.color}FF`} />
              </linearGradient>
            );
          })}
        </defs>
      </svg>

      <ResiliencePostModal />

      {audio && (
        <Fade in={true}>
          <MindfulnessMediaPlayer
            url={`${process.env.REACT_APP_CLOUD_FRONT_URL}${audio.url}`}
            id={audio.id}
            name={audio.name}
            image={audio.image}
            maxTime={audio.time}
            title={audio.title}
          />
        </Fade>
      )}

      {participantModalOpen && (
        <ParticipantModal
          open={participantModalOpen}
          setOpen={setParticipantModalOpen as any}
          forceCurrentStep={null}
          teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
        />
      )}

      {presenterModalOpen &&
        !isFirstTimeTeamInterstitialModalOpen &&
        presenterPathTeamLesson !== null &&
        !participantModalOpen && (
          <PresentationModal
            open={presenterModalOpen}
            setOpen={(val: boolean) => {
              if (window.location.pathname.indexOf("team/paths") !== -1) {
                // if the modal is opening, then ensure the path is navigated to and if it is closing then ensure extra params other than the path are removed
                if (val) {
                  navigateWithSearchParams(
                    navigate,
                    `/team/paths`,
                    {
                      pathId: (
                        presenterPathTeamLesson?.pathId ?? ""
                      ).toString(),
                    },
                    true,
                    true
                  );
                } else {
                  navigate(
                    `/team/paths?pathId=${presenterPathTeamLesson?.pathId}`,
                    {
                      replace: true,
                    }
                  );
                }
              }
              setPresenterModalOpen(val);
            }}
            pathTeamLesson={presenterPathTeamLesson}
          />
        )}

      {previewModalOpen && currentPathTeamLessonPreview !== null && (
        <PresentationPreviewModal
          open={previewModalOpen}
          setOpen={setPreviewModalOpen}
          pathTeamLesson={currentPathTeamLessonPreview}
        />
      )}

      {!globalHeadScanQuestionModalOpen && (
        <LessonPreviewModal
          open={isLessonPreviewOpen}
          setOpen={setIsLessonPreviewOpen}
        />
      )}

      <TeamActivityNotification />

      <FirstTimeInterstitialModal
        open={isSOLOProductAnnouncementModalOpen}
        setOpen={(val: any) => {
          setIsSOLOProductAnnouncementModalOpen(val);
          if (!val) {
            track(EVENTS.FirstTimeInterstitialShown);
          }
        }}
      />
      <FirstTimeTeamInterstitialModal
        open={isFirstTimeTeamInterstitialModalOpen}
        setOpen={(val: boolean) => {
          setIsFirstTimeTeamInterstitialModalOpen(val);
        }}
      />
      <ReachPromoMaterialModal
        promo={selectedPromoMaterial as PromotionalMaterialDto}
        open={promoMaterialModalOpen}
        setOpen={(val: boolean) => {
          setPromoMaterialModalOpen(val);
          if (!val) {
            setIsPromoMaterialNotFound(undefined);
          }
        }}
        assets={promoMaterialAssets}
      />
      {globalPractice && (
        <NanoPracticeModal
          practice={globalPractice}
          open={!globalHeadScanQuestionModalOpen && nanoPracticeModalOpen}
        />
      )}
      {globalHeadScanQuestionModalOpen &&
        selectedSkill &&
        currentApp !== AppType.CERT &&
        currentApp !== AppType.SPORT && (
          <HeadScanQuestionModal
            open={globalHeadScanQuestionModalOpen}
            setOpen={setGlobalHeadScanQuestionModalOpen}
            onStartTraining={() => {
              if (lesson) {
                setIsLessonPreviewOpen(true);
              } else if (globalPractice) {
                setNanoPracticeModalOpen(true);
              } else if (globalBlogPost) {
                navigate(`?post=${globalBlogPost.id}`, { replace: true });
              }
            }}
            skillIds={[selectedSkill.id]}
            showIntro={true}
          />
        )}
      <GetHelpModal />

      {isHierarchyAssociationStepComplete && isGoalOverviewModalOpen && (
        <WelcomeModal
          open={isGoalOverviewModalOpen}
          goalsOnly={true}
          setOpen={(val: any) => {
            setIsGoalOverviewModalOpen(val);
          }}
        />
      )}

      {isHierarchyAssociationStepComplete &&
        isFirstTimeUserWelcomeModalOpen && (
          <WelcomeModal
            open={isFirstTimeUserWelcomeModalOpen}
            preventClose={true}
            setOpen={setIsFirstTimeUserWelcomeModalOpen}
          />
        )}

      {isGoalCheckInModalOpen && (
        <GoalCheckInModal
          open={isGoalCheckInModalOpen}
          setOpen={() => {
            setIsGoalCheckInWelcomeBack(false);
            setIsGoalCheckInModalOpen(false);
          }}
          isWelcomeBack={isGoalCheckInWelcomeBack}
        />
      )}

      {isGoalCheckInNewPursuitModalOpen && (
        <GoalCheckInModal
          open={isGoalCheckInNewPursuitModalOpen}
          setOpen={() => {
            setIsGoalCheckInNewPursuitModalOpen(false);
          }}
          isWelcomeBack={isGoalCheckInWelcomeBack}
          skipGoalReview={true}
        />
      )}
      {self &&
        getKey() &&
        hierarchyIsReady &&
        userHierarchyAssociations &&
        !isHierarchyAssociationStepComplete && (
          <LandingContainer other={<></>} showHierarchySelection={true}>
            <HierarchySelectContainer
              onHierarchySelectComplete={() => {
                setIsHierarchyAssociationStepComplete(true);
              }}
            ></HierarchySelectContainer>
          </LandingContainer>
        )}

      {/* Google Play Banner */}
      {isAndroidBrowser() && <GooglePlayBanner />}

      <PracticeReminderModal
        open={isPracticeReminderModalOpen}
        setOpen={setIsPracticeReminderModalOpen}
        startEnabled={true}
        onClose={() => {
          setIsPracticeReminderModalOpen(false);
        }}
      />
    </>
  );
};
