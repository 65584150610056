import { useContext, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
  Error_Red,
  sportAllSetGreen,
  White,
} from "../../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { BestWorstResult } from "../Shared/BestWorstResult";
import { useGroupInformation } from "../../../../Hooks/Sport/useGroupInformation";
import { useBestWorstInitQuestion } from "../../../../Hooks/Sport/useBestWorstInitQuestion";
import { usePollQuestionInformation } from "../../../../Hooks/Sport/usePollQuestionInformation";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { PollMultiSelectInput } from "../../../Shared/SelectInput/PollMultiSelectInput";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { performHapticFeedback } from "../../../../Api/Utils";
import {
  PollResponseList,
  PollResponseListItem,
} from "../../../Team/Polls/PollResponseList";
import { HvSpinner } from "../../../Common/HvSpinner";
import { useSportTeam } from "../../../../Hooks/Team/useSportTeam";
import { SPORT_EVENTS } from "../../../../Utils/Analytics";

export const ParticipantBestWorstStepTwo = () => {
  const {
    setPollInstancesResultToServer,
    participantPathTeamLesson,
    currentTeamLessonUserInstanceId,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { trackEvent, lessonName } = useSportTeam();

  const [bestSelectedAnswers, setBestSelectedAnswers] = useState<number[]>([]);
  const [worstSelectedAnswers, setWorstSelectedAnswers] = useState<number[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);
  const [bestIsDone, setBestIsDone] = useState<boolean>(false);

  const { isGroupLead } = useGroupInformation();

  const { bestPollQuestion, worstPollQuestion } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_4_PQ",
    worstPollQuestionInternalTitle: "CH_5_PQ",
  });

  const {
    bestPollQuestion: parentBestPollQuestion,
    worstPollQuestion: parentWorstPollQuestion,
  } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_2_PQ",
    worstPollQuestionInternalTitle: "CH_3_PQ",
  });

  const {
    pollQuestionResults: bestQuestionResults,
    currentPollInstanceId: bestPollInstanceId,
  } = usePollQuestionInformation({
    pollQuestion: bestPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    pollQuestionResults: worstQuestionResults,
    currentPollInstanceId: worstPollInstanceId,
    currentUserPollQuestionResults: currentUserWorstQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: worstPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    groupPollQuestionResults: parentCurrentUserBestQuestionResults,
    pollQuestionResults: parentAllUsersBestQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: parentBestPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    groupPollQuestionResults: parentCurrentUserWorstQuestionResults,
    pollQuestionResults: parentAllUsersWorstQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: parentWorstPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const [hasPerformedHapticImpact, setHasPerformedHapticImpact] =
    useState(false);

  const submitPollResponse = () => {
    if (!bestIsDone) {
      setBestIsDone(true);
      return;
    }
    trackEvent(SPORT_EVENTS.IndividualInputSubmitted, {
      HV_Presentation: lessonName,
      HV_Question_Id: bestPollQuestion?.id,
    });
    trackEvent(SPORT_EVENTS.IndividualInputSubmitted, {
      HV_Presentation: lessonName,
      HV_Question_Id: worstPollQuestion?.id,
    });

    setLoading(true);
    setPollInstancesResultToServer([
      {
        pollQuestionUserInstanceId: bestPollInstanceId,
        questionAlternativeIds: [],
        freeTexts: [],
        feedback: "",
        questionUserResponseIds: bestSelectedAnswers,
      },
      {
        pollQuestionUserInstanceId: worstPollInstanceId,
        questionAlternativeIds: [],
        freeTexts: [],
        feedback: "",
        questionUserResponseIds: worstSelectedAnswers,
      },
    ]).then(() => {
      setFinish(true);
    });
  };

  if (!worstPollInstanceId && !bestPollInstanceId) {
    return <HvSpinner centerVertically />;
  }

  let content;
  if (
    participantPathTeamLesson?.inProgressStep ===
    CharterActivity.BestWorstStepTwoResults
  ) {
    content = (
      <BestWorstResult
        bestQuestionResults={bestQuestionResults}
        worstQuestionResults={worstQuestionResults}
        parentBestQuestionResults={parentAllUsersBestQuestionResults}
        parentWorstQuestionResults={parentAllUsersWorstQuestionResults}
      />
    );
  } else if (isGroupLead) {
    if (
      !bestIsDone &&
      bestPollInstanceId &&
      bestPollQuestion &&
      parentCurrentUserBestQuestionResults?.length > 0 &&
      !finish
    ) {
      if (!hasPerformedHapticImpact) {
        performHapticFeedback();
        setHasPerformedHapticImpact(true);
      }
      content = (
        <PollMultiSelectInput
          questionText={HVSportLocalizeStrings.CHARTER_BEST_TWO_QUESTION}
          doneButtonMessage={HVLocalizeStrings.CONTINUE}
          instruction={
            HVSportLocalizeStrings.CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION
          }
          alternatives={parentCurrentUserBestQuestionResults}
          currentSelectedAnswers={bestSelectedAnswers}
          submitPollResponse={submitPollResponse}
          setCurrentSelectedAnswers={setBestSelectedAnswers}
          disableIamDone={bestSelectedAnswers.length < 1}
          disableSelection={bestSelectedAnswers.length > 2}
          loading={loading}
          useBottomActionBar={true}
        />
      );
    } else if (
      currentUserWorstQuestionResults.length < 1 &&
      worstPollInstanceId &&
      worstPollQuestion &&
      parentCurrentUserWorstQuestionResults?.length > 0 &&
      !finish
    ) {
      content = (
        <PollMultiSelectInput
          questionText={HVSportLocalizeStrings.CHARTER_WORST_TWO_QUESTION}
          doneButtonMessage={HVLocalizeStrings.WE_ARE_DONE}
          instruction={
            HVSportLocalizeStrings.CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION
          }
          alternatives={parentCurrentUserWorstQuestionResults}
          currentSelectedAnswers={worstSelectedAnswers}
          submitPollResponse={submitPollResponse}
          setCurrentSelectedAnswers={setWorstSelectedAnswers}
          disableIamDone={worstSelectedAnswers.length < 1}
          disableSelection={worstSelectedAnswers.length > 2}
          loading={loading}
          useBottomActionBar={true}
        />
      );
    } else {
      content =
        parentCurrentUserBestQuestionResults.length > 0 &&
        parentCurrentUserWorstQuestionResults.length > 0 ? (
          <ParticipantWaitingScreen
            message={HVLocalizeStrings.TEAM_POLL_WAITING_FOR_GROUP_RESULTS}
          />
        ) : (
          <ParticipantWaitingScreen
            message={HVLocalizeStrings.TEAM_POLL_REPORTER_DID_NOT_RESPOND}
            messageBold={""}
          />
        );
    }
  } else {
    content =
      parentCurrentUserBestQuestionResults.length > 0 &&
      parentCurrentUserWorstQuestionResults.length > 0 ? (
        <BestWorstVoteQuestion
          bestQuestionResults={parentCurrentUserBestQuestionResults}
          worstQuestionResults={parentCurrentUserWorstQuestionResults}
        />
      ) : (
        <ParticipantWaitingScreen
          message={HVLocalizeStrings.TEAM_POLL_REPORTER_DID_NOT_RESPOND}
          messageBold={""}
        />
      );
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {isGroupLead &&
      currentUserWorstQuestionResults.length < 1 &&
      parentCurrentUserBestQuestionResults.length > 0 &&
      parentCurrentUserWorstQuestionResults.length > 0 ? (
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={`${HVLocalizeStrings.STEP} ${!bestIsDone ? 1 : 2} / 2`}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
      ) : (
        <></>
      )}
      {content}
    </ParticipantContainer>
  );
};

interface BestWorstVoteQuestionProps {
  bestQuestionResults: QuestionUserResponseDto[];
  worstQuestionResults: QuestionUserResponseDto[];
}

const BestWorstVoteQuestion = (props: BestWorstVoteQuestionProps) => {
  const { bestQuestionResults, worstQuestionResults } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const bestContent = (
    <PollResponseList noBorder p={"2"} w={"100%"} noDefaultHeight={isMobile}>
      {bestQuestionResults
        ?.sort(
          (a: QuestionUserResponseDto, b: QuestionUserResponseDto) =>
            b.id - a.id
        )
        .map((item: QuestionUserResponseDto) => {
          return (
            <PollResponseListItem key={item.id}>
              <Text color={Black} fontSize={"16px"}>
                {item.responseText}
              </Text>
            </PollResponseListItem>
          );
        })}
    </PollResponseList>
  );

  const worstContent = (
    <PollResponseList noBorder p={"2"} w={"100%"} noDefaultHeight={isMobile}>
      {worstQuestionResults
        ?.sort(
          (a: QuestionUserResponseDto, b: QuestionUserResponseDto) =>
            b.id - a.id
        )
        .map((item: QuestionUserResponseDto) => {
          return (
            <PollResponseListItem key={item.id}>
              <Text color={Black} fontSize={"16px"}>
                {item.responseText}
              </Text>
            </PollResponseListItem>
          );
        })}
    </PollResponseList>
  );

  return (
    <Flex
      flexDir={isMobile ? "column" : "row"}
      color={Black}
      maxW={isMobile ? "400px" : "900px"}
      p={4}
      gap={isMobile ? 12 : 6}
      m="auto" // Automatically centers horizontally
      w="100%" // Takes up the full width of the screen
      mt={"10px"}
      justify="center"
    >
      <Flex
        gap={3}
        flexDir={"column"}
        backgroundColor={White}
        borderRadius={Border_Radius}
        boxShadow={"lg"}
        p={isMobile ? 6 : 12}
        flex={1}
        maxW={isMobile ? "100%" : "400px"}
      >
        <Text fontSize={"md"} mb={8}>
          {HVLocalizeStrings.WHAT_ARE_SOME_OF_THE}{" "}
          <span style={{ color: sportAllSetGreen, fontWeight: 600 }}>
            {HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_BEST}
          </span>{" "}
          {HVLocalizeStrings.CHARACTERISTICS_THAT_MAKE_A_TEAM_SUCCESSFUL}
        </Text>
        <Text>{HVLocalizeStrings.YOUR_GROUP_SAID}</Text>
        {bestContent}
      </Flex>
      <Flex
        gap={3}
        flexDir={"column"}
        backgroundColor={White}
        borderRadius={Border_Radius}
        boxShadow={"lg"}
        p={isMobile ? 6 : 12}
        flex={1}
        maxW={isMobile ? "100%" : "400px"}
      >
        <Text fontSize={"md"} mb={8}>
          {HVLocalizeStrings.WHAT_ARE_SOME_OF_THE}{" "}
          <span style={{ color: Error_Red, fontWeight: 600 }}>
            {HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_WORST}
          </span>{" "}
          {HVLocalizeStrings.CHARACTERISTICS_THAT_CAN_HARM_A_TEAM}
        </Text>
        <Text>{HVLocalizeStrings.YOUR_GROUP_SAID}</Text>
        {worstContent}
      </Flex>
    </Flex>
  );
};
