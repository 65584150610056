import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { InviteCodeSelectionDto } from "@headversity/contract";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HvSelect } from "../../Common/HvSelect";
import { White, Equity_Black, MODAL_CLOSE_BUTTON } from "../../../Styles/HeadversityStyle";
import { useState } from "react";
import { LandingContainer } from "../Shared/LandingContainer";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface InviteCodeSelectorProps {
  domains: InviteCodeSelectionDto[];
  onSubmit: (domain: InviteCodeSelectionDto) => void;
}

const InviteCodeContent = ({
  domains,
  onSubmit,
}: {
  domains: InviteCodeSelectionDto[];
  onSubmit: (domain: InviteCodeSelectionDto) => void;
}) => {
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] =
    useState<InviteCodeSelectionDto | null>(null);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = parseInt(e.target.value);
    if (!isNaN(selectedIndex)) {
      setSelectedDomain(domains[selectedIndex]);
    } else {
      setSelectedDomain(null);
    }
  };

  const handleContinue = () => {
    if (selectedDomain) {
      onSubmit(selectedDomain);
    }
  };

  return (
    <LandingContainer other={
      <Button
        position={"absolute"}
        left={"20px !important"}
        top={"20px !important"}
        onClick={() => navigate("/login")}
        w={"30px !important"}
        h={"30px !important"}
        className={"button-link"}
        textAlign="left"
        zIndex={2}
        _hover={{
          bgColor: "var(--chakra-colors-blackAlpha-100) !important",
        }}
      >
        <Icon
          as={FiArrowLeftCircle}
          w={"30px"}
          h={"30px"}
          color={MODAL_CLOSE_BUTTON}
        />
      </Button>
    }>
      <Flex direction="column" alignItems="center">
        <Text mb={4} textAlign="center">{HVLocalizeStrings.MULTIPLE_INVITE_CODES_MESSAGE}</Text>
        <Box mb={4} w={300}>
          <HvSelect
            placeholder={HVLocalizeStrings.SELECT_INVITE_CODE}
            onChange={handleSelect}
            bg={White}
            color={Equity_Black}
            height="40px"
          >
            {domains.map((domain, index) => (
              <option key={domain.code} value={index}>
                {domain.name}
              </option>
            ))}
          </HvSelect>
        </Box>
        <Box mb={4}>
          <AnimatedButton
            colorSet={AnimatedButtonColorSet.Primary}
            onClick={handleContinue}
            text={HVLocalizeStrings.CONTINUE}
            w={300}
            disabled={!selectedDomain}
          />
        </Box>
      </Flex>
    </LandingContainer>
  );
};

export const InviteCodeSelector = ({
  domains,
  onSubmit,
}: InviteCodeSelectorProps) => {
  return <InviteCodeContent domains={domains} onSubmit={onSubmit} />;
};
